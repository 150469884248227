import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  manageCustomerAddresses,
  getEcommerceOrders,
  getSpecificProduct,
  printEcommerceBill,
  updateOrderStatus,
  getStoreSpecificProduct,
  getScannedProduct,
  addEcommerOrder,
  printEcommerceShippingLabel,
} from "apis/restApis";
import { FieldArrayRenderProps, FormikHelpers } from "formik";
import {
  ACCESS_CONSTANTS,
  calculateTax,
  EcommerceAddress,
  filterNullElements,
  formatProductForOrder,
  ICart,
  ICartItem,
  IDiscount,
  IEcommerceOrderDetail,
  IEcommerceOrderListing,
  printBill,
  STATUSES,
  TGetParams,
  to2Decimal,
  Toast,
  transformProduct,
  updateValuesForItem,
} from "utils";
import { AddProduct } from "store/POS";
import { setCommonStatus, updateValues } from "store/commonSlice";
import { setProductStatus } from "store/ManageProducts/productSlice";
import { NavigateFunction } from "react-router-dom";

const initialState = {
  orders: [] as IEcommerceOrderListing[],
  orderToEdit: {} as IEcommerceOrderDetail,
  customer_addresses: [] as EcommerceAddress[],
  selectedProduct: null as any,
  cart: {
    cartItems: [] as ICartItem[],
    discount: {
      discount_type: "flat",
      discount_value: 0,
    } as IDiscount,
    net_amount: 0,
    round_off: 0,
    additional_charges: 0,
    credit_note: {
      creditNote: [{ refund_amount: 0, value: 0, label: "" }] as any[],
      applyCredit: 0,
    },
    remarks: "",
  } as ICart,
  status: STATUSES.IDLE as string,
  error: null as any,
};

export const fetchEcommerceOrders = createAsyncThunk(
  "ecommerce_order/fetchEcommerceOrders",
  async (
    { id, active = false, pageNo = 1, query = "", edit }: TGetParams,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await getEcommerceOrders({
        id,
        active,
        pageNo,
        query,
        edit,
      });
      dispatch(updateValues(response));
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const manageEcommerceCustomerAddresses = createAsyncThunk(
  "ecommerce_order/manageEcommerceCustomerAddresses",
  async (
    { customer_id, data }: { customer_id: number; data?: EcommerceAddress },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await manageCustomerAddresses({
        customer_id: customer_id,
        data: data,
      });

      dispatch(updateValues(response));

      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateEcommerceOrder = createAsyncThunk(
  "ecommerce_order/updateEcommerceOrder",
  async (
    {
      bill_id,
      data,
      setModal,
      actions,
    }: {
      bill_id: number;
      data: any;
      setModal: React.Dispatch<React.SetStateAction<boolean>>;
      actions: FormikHelpers<any>;
    },
    { dispatch, rejectWithValue }
  ) => {
    dispatch(
      setCommonStatus({ state: STATUSES.LOADING, type: "update-order" })
    );

    try {
      const bill = await updateOrderStatus({
        bill_id: bill_id,
        data: data,
      });

      dispatch(setCommonStatus({ state: STATUSES.IDLE, type: "update-order" }));

      return bill;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const addNewEcommerceOrder = createAsyncThunk(
  "ecommerce_order/addNewEcommerceOrder",
  async (
    { data, navigate }: { data: any; navigate: NavigateFunction },
    { dispatch, rejectWithValue }
  ) => {
    dispatch(setCommonStatus({ state: STATUSES.LOADING, type: "add-bill" }));

    try {
      const bill: any = await addEcommerOrder({
        data: data,
      });

      // dispatch(printEcommerceOrder({ bill_id: bill?.result?.id }));

      dispatch(setCommonStatus({ state: STATUSES.IDLE, type: "add-bill" }));

      return bill;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const printEcommerceOrder = createAsyncThunk(
  "ecommerce_order/printEcommerceOrder",
  async ({ bill_id }: { bill_id: number }, { dispatch, rejectWithValue }) => {
    dispatch(setCommonStatus({ state: STATUSES.LOADING, type: "print-bill" }));

    try {
      const bill = await printEcommerceBill({
        bill_id: bill_id,
      });

      dispatch(setCommonStatus({ state: STATUSES.IDLE, type: "" }));

      return bill;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const printShippingLabel = createAsyncThunk(
  "ecommerce_order/printShippingLabel",
  async ({ bill_id }: { bill_id: number }, { dispatch, rejectWithValue }) => {
    dispatch(setCommonStatus({ state: STATUSES.LOADING, type: "print-label" }));

    try {
      const shipping_label: any = await printEcommerceShippingLabel({
        bill_id: bill_id,
      });

      dispatch(setCommonStatus({ state: STATUSES.IDLE, type: "" }));

      return {
        message: shipping_label.message,
        result: {
          bill: shipping_label.result,
        },
      };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// export const fetchProductForOrder = createAsyncThunk(
//   "ecommerce_order/fetchProductForOrder",
//   async (
//     {
//       data,
//       setBatchModal,
//       pos = false,
//       arrayHelper,
//     }: {
//       data: {
//         cms_product_id: number;
//         product_id: number;
//         product_code: string;
//         weights: any[];
//         medias: any[];
//         product_name: string;
//       };
//       setBatchModal: React.Dispatch<React.SetStateAction<boolean>>;
//       pos: boolean;
//       arrayHelper?: FieldArrayRenderProps;
//     },
//     { dispatch, rejectWithValue }
//   ) => {
//     try {
//       //   const pos_response: any = await getScannedProduct(
//       //     `%25${data?.product_code}`,
//       //     ACCESS_CONSTANTS.PRICE_DATA_STORE,
//       //     true,
//       //     false
//       //   );
//       //   dispatch(setProductStatus(STATUSES.IDLE));

//       //   if (pos_response.result.length === 0) {
//       //     return Toast("No such Product exists", "warning");
//       //   } else if (Object.keys(pos_response.result).length > 0) {
//       //     const updatedPrices = filterNullElements(
//       //       pos_response.result.price.map((item) => {
//       //         return item.store_prices.length > 0 ? item : null;
//       //       })
//       //     );

//       //     const formatted_data = {
//       //       id: data?.cms_product_id,
//       //       weight_variationss: data?.weights || [],
//       //       media: data?.medias || [],
//       //       quantity: 1,
//       //       print_name: data?.product_name,
//       //       product_code: pos_response.result?.product_code,
//       //       product_id: data?.product_id,
//       //       cms_product_id: data?.cms_product_id,
//       //       price: updatedPrices,
//       //       net_weight: pos_response.result?.net_weight,
//       //       uom: pos_response.result?.uom,
//       //       selling_uom: pos_response.result?.selling_uom,
//       //       tax: pos_response.result?.tax,
//       //       hsn_code: pos_response.result?.hsn_code,
//       //       barcode_type: pos_response.result?.barcode_type,
//       //     };
//       //     if (updatedPrices.length === 1) {
//       //       dispatch(
//       //         AddProduct(
//       //           transformProduct(
//       //             formatted_data,
//       //             ACCESS_CONSTANTS.ECOMMERCE_STORE
//       //           ),
//       //           {
//       //             batch_id: updatedPrices[0].id,
//       //             store_id: updatedPrices[0].store_prices[0].store,
//       //             batch_name: updatedPrices[0].batch_name,
//       //             batch_mrp: updatedPrices[0].store_prices[0].mrp,
//       //             batch_selling_price:
//       //               updatedPrices[0].store_prices[0].selling_price,
//       //           }
//       //         )
//       //       );
//       //     } else {
//       //       return {
//       //         ...transformProduct(
//       //           formatted_data,
//       //           ACCESS_CONSTANTS.PRICE_DATA_STORE
//       //         ),
//       //         net_weight: pos_response.result?.net_weight,
//       //         cms_product_id: data?.cms_product_id,
//       //         product_id: pos_response.result?.id,
//       //         net_weight_uom: pos_response.result?.uom,
//       //         weight_variations: data?.weights,
//       //         media: data?.medias,
//       //       };
//       //     }
//       //   }

//       const product_data: any = await getSpecificProduct(data.product_id);

//       const response = {
//         id: data?.cms_product_id,
//         weight_variationss: data?.weights || [],
//         media: data?.medias || [],
//         quantity: 1,
//         print_name: data?.product_name,
//         product_code: product_data?.result?.product_code,
//         product_id: data?.product_id,
//         cms_product_id: data?.cms_product_id,
//         price: product_data?.result?.price.map((price, index) => {
//           return {
//             ...price,
//             store_prices: product_data?.result?.price[
//               index
//             ].store_prices.filter(
//               (storePrice) =>
//                 storePrice.store === ACCESS_CONSTANTS.PRICE_DATA_STORE
//             ),
//           };
//         }),
//         net_weight: product_data?.result?.net_weight,
//         uom: product_data?.result?.uom,
//         selling_uom: product_data?.result?.selling_uom,
//         tax: product_data?.result?.tax,
//         hsn_code: product_data?.result?.hsn_code,
//         barcode_type: product_data?.result?.barcode_type,
//       };

//       if (response.price.length > 1) {
//         return {
//           ...transformProduct(response, ACCESS_CONSTANTS.PRICE_DATA_STORE),
//           net_weight: product_data?.result?.net_weight,
//           cms_product_id: data?.cms_product_id,
//           product_id: product_data?.result?.id,
//           net_weight_uom: product_data?.result?.uom,
//           weight_variations: data?.weights,
//           media: [],
//         };
//       } else {
//         dispatch(
//           AddProduct(
//             {
//               ...transformProduct(response, ACCESS_CONSTANTS.PRICE_DATA_STORE),
//               net_weight: product_data?.result?.net_weight,
//               cms_product_id: data?.cms_product_id,
//               product_id: product_data?.result?.id,
//               net_weight_uom: product_data?.result?.uom,
//               weight_variations: data?.weights,
//               media: [],
//             },
//             {
//               batch_id: response?.price?.[0]?.id,
//               store_id: response?.price?.[0]?.store_prices[0].store,
//               batch_name: response?.price?.[0]?.batch_name,
//               batch_mrp: response?.price?.[0]?.store_prices[0].mrp,
//               batch_selling_price:
//                 response?.price?.[0]?.store_prices[0].selling_price,
//             }
//           )
//         );
//         return;
//       }
//     } catch (error) {
//       console.log("error", error);
//       return rejectWithValue(error);
//     }
//   }
// );

// export const addProductToOrder = createAsyncThunk(
//   "ecommerce_order/addProductToOrder",
//   async (
//     {
//       selectedProduct,
//       batch,
//       arrayHelper,
//     }: {
//       selectedProduct: any;
//       batch: any;
//       arrayHelper: FieldArrayRenderProps;
//     },
//     { dispatch, getState, rejectWithValue }
//   ) => {
//     const {
//       ecommerce: {
//         order: { cart },
//       },
//     }: any = getState();
//     try {
//       let index: number;

//       if (
//         !selectedProduct.print_name.toLocaleLowerCase().includes("new product")
//       ) {
//         index = cart.cartItems.findIndex(
//           (cartItem: any) =>
//             cartItem?.cms_product_id === selectedProduct?.cms_product_id
//         );
//       }

//       if (
//         index === -1 ||
//         selectedProduct.print_name.toLocaleLowerCase().includes("new product")
//       ) {
//         const {
//           discount_amount,
//           total_amount,
//           payable_amount,
//         } = updateValuesForItem(
//           batch.batch_selling_price,
//           selectedProduct.quantity || 1,
//           {
//             discount_type: "flat",
//             discount_value: 0,
//           } as IDiscount
//         );

//         const { gst, cess } = calculateTax(
//           payable_amount,
//           selectedProduct.tax.tax_rate ? selectedProduct.tax.tax_rate : 0,
//           selectedProduct.tax.cess ? selectedProduct.tax.cess : 0
//         );

//         const newItem: ICartItem = {
//           id: selectedProduct.product_id,
//           cms_product_id: selectedProduct.cms_product_id,
//           print_name: selectedProduct.print_name,
//           product_code: selectedProduct.product_code,
//           batch_id: batch.batch_id,
//           store_id: selectedProduct.store_id,
//           batch: batch.batch_name,
//           quantity: selectedProduct.advanceOrder
//             ? selectedProduct.advanceOrder.quantity
//             : selectedProduct.quantity
//             ? selectedProduct.quantity
//             : 1,
//           mrp: batch.batch_selling_price,
//           selling_price: batch.batch_selling_price,
//           total_amount: total_amount,
//           cess: cess,
//           cgst: to2Decimal(gst / 2),
//           sgst: to2Decimal(gst / 2),
//           igst: 0,
//           tax_applied: to2Decimal(gst + cess),
//           discount_applied: discount_amount,
//           payable_amount: payable_amount,
//           discount: {
//             discount_type: "flat",
//             discount_value: 0,
//           } as IDiscount,
//           tax: {
//             id: selectedProduct.tax.id,
//             tax_rate: selectedProduct.tax.tax_rate
//               ? selectedProduct.tax.tax_rate
//               : 0,
//             cess: selectedProduct.tax.cess ? selectedProduct.tax.cess : 0,
//           },
//           uom: {
//             id: selectedProduct.uom.id,
//             uom_code: selectedProduct.uom.uom_code,
//           },
//           net_weight_uom: {
//             id: selectedProduct.net_weight_uom.id,
//             uom_code: selectedProduct.net_weight_uom.uom_code,
//           },
//           hsn: {
//             id: selectedProduct.hsn.id,
//             hsn_code: selectedProduct.hsn.hsn_code,
//           },
//           count: selectedProduct.count,
//           meddler:
//             selectedProduct.print_name
//               .toLocaleLowerCase()
//               .includes("new product") && selectedProduct.productGroup.id === 0
//               ? true
//               : selectedProduct.meddler,
//           weight_variations: selectedProduct?.weight_variations,
//           media: selectedProduct?.media,
//           productGroup: selectedProduct.productGroup,
//         };
//         console.log(newItem);
//         // arrayHelper.unshift(newItem);
//         return newItem;
//       }
//     } catch (error) {
//       console.log("error", error);
//       return rejectWithValue(error);
//     }
//   }
// );

// export const deleteProductFromOrder = createAsyncThunk(
//   "ecommerce_order/deleteProductFromOrder",
//   async (
//     {
//       index,
//       arrayHelper,
//     }: {
//       index: number;
//       arrayHelper: FieldArrayRenderProps;
//     },
//     { rejectWithValue }
//   ) => {
//     try {
//       // arrayHelper.remove(index);
//     } catch (error) {
//       console.log("error", error);
//       return rejectWithValue(error);
//     }
//   }
// );

// export const updateProductQuantity = createAsyncThunk(
//   "ecommerce_order/updateProductQuantity",
//   async (
//     {
//       index,
//       quantity,
//       arrayHelper,
//     }: {
//       index: number;
//       quantity: number;
//       arrayHelper: FieldArrayRenderProps;
//     },
//     { dispatch, getState, rejectWithValue }
//   ) => {
//     const {
//       ecommerce: {
//         order: { cart },
//       },
//     }: any = getState();
//     try {
//       const {
//         discount_amount,
//         total_amount,
//         payable_amount,
//       } = updateValuesForItem(
//         cart.cartItems[index].selling_price,
//         quantity,
//         cart.cartItems[index].discount,
//         cart.cartItems[index].weight
//       );

//       const { gst, cess } = calculateTax(
//         payable_amount,
//         cart.cartItems[index].tax.tax_rate,
//         cart.cartItems[index].tax.cess
//       );

//       const updatedItem = {
//         ...cart.cartItems[index],
//         quantity: quantity,
//         total_amount: total_amount,
//         cess: cess,
//         cgst: to2Decimal(gst / 2),
//         sgst: to2Decimal(gst / 2),
//         igst: 0,
//         tax_applied: to2Decimal(gst + cess),
//         discount_applied: discount_amount,
//         payable_amount: payable_amount,
//       };

//       // arrayHelper.replace(index, updatedItem);

//       return updatedItem;
//     } catch (error) {
//       console.log("error", error);
//       return rejectWithValue(error);
//     }
//   }
// );

// export const updateProductWeight = createAsyncThunk(
//   "ecommerce_order/updateProductWeight",
//   async (
//     {
//       index,
//       weight,
//       arrayHelper,
//     }: {
//       index: number;
//       weight: number;
//       arrayHelper: FieldArrayRenderProps;
//     },
//     { dispatch, getState, rejectWithValue }
//   ) => {
//     const {
//       ecommerce: {
//         order: { cart },
//       },
//     }: any = getState();
//     try {
//       const {
//         discount_amount,
//         total_amount,
//         payable_amount,
//       } = updateValuesForItem(
//         cart.cartItems[index].selling_price,
//         cart.cartItems[index].quantity,
//         cart.cartItems[index].discount,
//         weight
//       );

//       const { gst, cess } = calculateTax(
//         payable_amount,
//         cart.cartItems[index].tax.tax_rate,
//         cart.cartItems[index].tax.cess
//       );

//       const updatedItem = {
//         ...cart.cartItems[index],
//         weight: weight,
//         total_amount: total_amount,
//         cess: cess,
//         cgst: to2Decimal(gst / 2),
//         sgst: to2Decimal(gst / 2),
//         igst: 0,
//         tax_applied: to2Decimal(gst + cess),
//         discount_applied: discount_amount,
//         payable_amount: payable_amount,
//       };

//       // arrayHelper.replace(index, updatedItem);

//       return updatedItem;
//     } catch (error) {
//       console.log("error", error);
//       return rejectWithValue(error);
//     }
//   }
// );

// export const updateProductDiscount = createAsyncThunk(
//   "ecommerce_order/updateProductDiscount",
//   async (
//     {
//       index,
//       discount,
//       arrayHelper,
//     }: {
//       index: number;
//       discount: IDiscount;
//       arrayHelper: FieldArrayRenderProps;
//     },
//     { dispatch, getState, rejectWithValue }
//   ) => {
//     const {
//       ecommerce: {
//         order: { cart },
//       },
//     }: any = getState();
//     try {
//       const {
//         discount_amount,
//         total_amount,
//         payable_amount,
//       } = updateValuesForItem(
//         cart.cartItems[index].selling_price,
//         cart.cartItems[index].quantity,
//         discount,
//         cart.cartItems[index].weight
//       );

//       const { gst, cess } = calculateTax(
//         to2Decimal(
//           payable_amount - cart.cartItems[index].bill_discount_per_item
//         ),
//         cart.cartItems[index].tax.tax_rate
//           ? cart.cartItems[index].tax.tax_rate
//           : 0,
//         cart.cartItems[index].tax.cess ? cart.cartItems[index].tax.cess : 0
//       );

//       const updatedItem = {
//         ...cart.cartItems[index],
//         discount: discount,
//         total_amount: total_amount,
//         cess: cess,
//         cgst: to2Decimal(gst / 2),
//         sgst: to2Decimal(gst / 2),
//         igst: 0,
//         tax_applied: to2Decimal(gst + cess),
//         discount_applied: to2Decimal(
//           discount_amount + cart.cartItems[index].bill_discount_per_item
//         ),
//         payable_amount: to2Decimal(
//           payable_amount - cart.cartItems[index].bill_discount_per_item
//         ),
//       };

//       // arrayHelper.replace(index, updatedItem);

//       return updatedItem;
//     } catch (error) {
//       console.log("error", error);
//       return rejectWithValue(error);
//     }
//   }
// );

// export const updateProductTaxApplied = createAsyncThunk(
//   "ecommerce_order/updateProductTaxApplied",
//   async (
//     {
//       currentCart,
//       bill_discount,
//     }: {
//       currentCart: ICart;
//       bill_discount: number;
//     },
//     { getState, dispatch, rejectWithValue }
//   ) => {
//     const {
//       ecommerce: {
//         order: { cart },
//       },
//     }: any = getState();

//     const cart_to_use = currentCart ? currentCart : cart;

//     const current_cart_discount = currentCart
//       ? currentCart.discount.discount_value
//       : bill_discount;

//     const initial_net_payable = cart_to_use.cartItems.reduce((acc, item) => {
//       const item_amount = item?.weight
//         ? to2Decimal((item.selling_price * item.weight) / 1000)
//         : to2Decimal(item.selling_price);

//       const discount_amount =
//         item.discount.discount_type === "percentage"
//           ? to2Decimal(item_amount * (item.discount.discount_value / 100))
//           : item.discount.discount_value;

//       const amount_with_discount = to2Decimal(
//         (item_amount - discount_amount) * item.quantity
//       );

//       return acc + to2Decimal(amount_with_discount);
//     }, 0);

//     const updatedCartItems = cart_to_use.cartItems.map((item) => {
//       const {
//         discount_amount,
//         total_amount,
//         payable_amount,
//       } = updateValuesForItem(
//         item.selling_price,
//         item.quantity,
//         item.discount,
//         item.weight
//       );

//       const {
//         gst,
//         cess,
//         bill_discount_per_item,
//         new_payable_amount,
//       } = calculateTax(
//         payable_amount,
//         item.tax.tax_rate,
//         item.tax.cess,
//         initial_net_payable,
//         current_cart_discount
//       );

//       return {
//         ...item,
//         discount_applied:
//           discount_amount + bill_discount_per_item / item.quantity,
//         cess: cess,
//         cgst: to2Decimal(gst / 2),
//         sgst: to2Decimal(gst / 2),
//         igst: 0,
//         tax_applied: to2Decimal(gst + cess),
//         total_amount: total_amount,
//         payable_amount: to2Decimal(new_payable_amount),
//         bill_discount_per_item: to2Decimal(bill_discount_per_item),
//       };
//     });

//     return updatedCartItems;
//   }
// );

const ecommerceOrderSlice = createSlice({
  name: "ecommerce_order",
  initialState,
  reducers: {
    resetOrderState: (state) => {
      state = initialState;
    },
    setOrderToEdit: (state, action) => {
      state.orderToEdit = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchEcommerceOrders.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(fetchEcommerceOrders.fulfilled, (state, action: any) => {
        if (action.meta.arg.id) {
          state.orderToEdit = {
            ...action.payload.result,
            ordered_products: action.payload.result.ordered_products.map(
              (item: any) => ({
                ...item,
                product: {
                  ...formatProductForOrder(item.product),
                  selected_weight: item.product.product_weights?.find(
                    (weight: any) => weight.id === item.product_weight
                  ).weight,
                },
              })
            ),
          };
        } else if (action.meta.arg.active) {
          state.orders = action.payload.result;
        } else {
          state.orders = action.payload.result.results;
        }

        state.status = STATUSES.IDLE;
      })
      .addCase(
        manageEcommerceCustomerAddresses.fulfilled,
        (state, action: any) => {
          if (action.meta.arg.data) {
            state.customer_addresses.unshift(action.payload.result);
          } else {
            state.customer_addresses = action.payload.result;
          }
        }
      )

      .addCase(updateEcommerceOrder.fulfilled, (state, action: any) => {
        action.meta.arg.actions.resetForm();
        action.meta.arg.setModal(false);
        state.orderToEdit["order_status"] = action.payload.result?.order_status;
        state.orderToEdit["order_history"] =
          action.payload.result?.order_history;
      })
      .addCase(printEcommerceOrder.fulfilled, (state, action: any) => {
        printBill(action.payload);

        Toast(`${action.payload.message}`, "success");
      })
      .addCase(printShippingLabel.fulfilled, (state, action: any) => {
        printBill(action.payload);

        Toast(`${action.payload.message}`, "success");
      })
      .addCase(addNewEcommerceOrder.fulfilled, (state, action: any) => {
        action.meta.arg.navigate && action.meta.arg.navigate(-1);

        Toast(`${action.payload.message}`, "success");
      });
    // .addCase(fetchProductForOrder.fulfilled, (state, action: any) => {
    //   if (action.payload && Object.keys(action.payload).length > 0) {
    //     state.selectedProduct = action.payload;
    //     action.meta.arg.setBatchModal(true);
    //   }
    // })
    // .addCase(addProductToOrder.fulfilled, (state, action: any) => {
    //   if (action.payload && Object.keys(action.payload).length > 0) {
    //     state.cart.cartItems.unshift(action.payload);
    //   }
    // })
    // .addCase(deleteProductFromOrder.fulfilled, (state, action: any) => {
    //   if (action.payload && Object.keys(action.payload).length > 0) {
    //     state.cart.cartItems.filter(
    //       (_, index) => index !== action.meta.arg.index
    //     );
    //   }
    // })
    // .addCase(updateProductTaxApplied.fulfilled, (state, action: any) => {
    //   if (action.payload && Object.keys(action.payload).length > 0) {
    //     state.cart.cartItems = action.payload;
    //   }

    //   state.cart.total_items = state.cart.cartItems.reduce(
    //     (acc: number, item: ICartItem) => acc + item.count,
    //     0
    //   );

    //   state.cart.total_amount = state.cart.cartItems.reduce(
    //     (acc: number, item: ICartItem) => acc + item.total_amount,
    //     0
    //   );

    //   state.cart.total_discount = state.cart.cartItems.reduce(
    //     (acc: number, item: ICartItem) => acc + item.discount_applied,
    //     0
    //   );

    //   state.cart.total_tax = state.cart.cartItems.reduce(
    //     (acc: number, item: ICartItem) => acc + item.tax_applied,
    //     0
    //   );

    //   state.cart.payable_amount = state.cart.cartItems.reduce(
    //     (acc: number, item: ICartItem) => acc + item.payable_amount,
    //     0
    //   );

    //   state.cart.round_off =
    //     state.cart.payable_amount - Math.round(state.cart.payable_amount);
    // })
    // .addMatcher(
    //   isAnyOf(
    //     updateProductQuantity.fulfilled,
    //     updateProductWeight.fulfilled,
    //     updateProductDiscount.fulfilled
    //   ),
    //   (state, action: any) => {
    //     if (action.payload && Object.keys(action.payload).length > 0) {
    //       state.cart.cartItems[action.meta.arg.index] = action.payload;
    //     }

    //     const cart_to_use = JSON.parse(JSON.stringify(state.cart)) as ICart;

    //     const current_cart_discount = state.cart.discount.discount_value;

    //     const initial_net_payable = cart_to_use.cartItems.reduce(
    //       (acc, item) => {
    //         const item_amount = item?.weight
    //           ? to2Decimal((item.selling_price * item.weight) / 1000)
    //           : to2Decimal(item.selling_price);

    //         const discount_amount =
    //           item.discount.discount_type === "percentage"
    //             ? to2Decimal(
    //                 item_amount * (item.discount.discount_value / 100)
    //               )
    //             : item.discount.discount_value;

    //         const amount_with_discount = to2Decimal(
    //           (item_amount - discount_amount) * item.quantity
    //         );

    //         return acc + to2Decimal(amount_with_discount);
    //       },
    //       0
    //     );

    //     const updatedCartItems = cart_to_use.cartItems.map((item) => {
    //       const {
    //         discount_amount,
    //         total_amount,
    //         payable_amount,
    //       } = updateValuesForItem(
    //         item.selling_price,
    //         item.quantity,
    //         item.discount,
    //         item.weight
    //       );

    //       const {
    //         gst,
    //         cess,
    //         bill_discount_per_item,
    //         new_payable_amount,
    //       } = calculateTax(
    //         payable_amount,
    //         item.tax.tax_rate,
    //         item.tax.cess,
    //         initial_net_payable,
    //         current_cart_discount
    //       );

    //       return {
    //         ...item,
    //         discount_applied:
    //           discount_amount + bill_discount_per_item / item.quantity,
    //         cess: cess,
    //         cgst: to2Decimal(gst / 2),
    //         sgst: to2Decimal(gst / 2),
    //         igst: 0,
    //         tax_applied: to2Decimal(gst + cess),
    //         total_amount: total_amount,
    //         payable_amount: to2Decimal(new_payable_amount),
    //         bill_discount_per_item: to2Decimal(bill_discount_per_item),
    //       };
    //     });

    //     state.cart.cartItems = updatedCartItems;

    //     state.cart.total_items = state.cart.cartItems.reduce(
    //       (acc: number, item: ICartItem) => acc + item.count,
    //       0
    //     );

    //     state.cart.total_amount = state.cart.cartItems.reduce(
    //       (acc: number, item: ICartItem) => acc + item.total_amount,
    //       0
    //     );

    //     state.cart.total_discount = state.cart.cartItems.reduce(
    //       (acc: number, item: ICartItem) => acc + item.discount_applied,
    //       0
    //     );

    //     state.cart.total_tax = state.cart.cartItems.reduce(
    //       (acc: number, item: ICartItem) => acc + item.tax_applied,
    //       0
    //     );

    //     state.cart.payable_amount = state.cart.cartItems.reduce(
    //       (acc: number, item: ICartItem) => acc + item.payable_amount,
    //       0
    //     );

    //     state.cart.bill_discount =
    //       state.cart.discount.discount_value > 0
    //         ? state.cart.discount.discount_type === "percentage"
    //           ? to2Decimal(
    //               initial_net_payable *
    //                 (state.cart.discount.discount_value / 100)
    //             )
    //           : to2Decimal(state.cart.discount.discount_value)
    //         : 0;

    //     state.cart.round_off =
    //       state.cart.payable_amount - Math.round(state.cart.payable_amount);
    //   }
    // );
  },
});

export const { resetOrderState, setOrderToEdit } = ecommerceOrderSlice.actions;

export default ecommerceOrderSlice.reducer;
