import { createSlice } from "@reduxjs/toolkit";
import {
  ACCESS_CONSTANTS,
  CUSTOMERS_CONSTANTS,
  FORM_CONSTANTS,
  GENERAL_CONSTANTS,
  POS_HEADER_CONSTANTS,
  STATUSES,
  TOAST_CONSTANTS,
} from "utils/constants";
import {
  delCustomer,
  getActiveCustomer,
  getCustomer,
  getCustomerOrderDetail,
  getSpecificCustomer,
  setCustomer,
  updateCustomer,
} from "apis/restApis";
import { Customer } from "utils/types";
import { setCommonStatus, updateValues } from "store/commonSlice";
import { Toast, formatDate, to2Decimal } from "utils";
import { FormikHelpers } from "formik";
import { NavigateFunction } from "react-router-dom";
import { AddOrderPaymentMode } from "store/POS";
import { RootState } from "store/store";
import { onlyNumbers } from "../../utils/helper";

const initialState = {
  customers: [] as Customer[],
  customerToEdit: {} as Customer,
  cachedCustomer: {} as Customer,
  order_details: {} as any,
  status: STATUSES.IDLE as string,
  error: null,
};

const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    getAllCustomers(state, action) {
      state.customers = action.payload;
    },

    clearCustomers(state) {
      state.customers = [] as Customer[];
    },
    addCustomer(state, action) {
      state.customers.unshift(action.payload);
    },
    removeCustomer(state, action) {
      state.customers = state.customers.filter(
        (customer) => customer.id !== action.payload
      );
    },
    editCustomer(state, action) {
      const { id } = action.payload;
      const index = state.customers.findIndex((customer) => customer.id === id);
      if (index !== -1) {
        state.customers[index] = action.payload;
      }
    },

    setCachedCustomer(state, action) {
      state.cachedCustomer = action.payload;
    },

    setCustomerToEdit(state, action) {
      state.customerToEdit = action.payload;
    },

    setCustomerOrderDetails(state, action) {
      state.order_details = action.payload;
    },

    setCustomerStatus: (state, action) => {
      state.status = action.payload;
    },
    setCustomerError: (state, action) => {
      state.error = action.payload;
    },

    resetCustomerState: (state) => {
      state.customers = [] as Customer[];
      state.customerToEdit = {} as Customer;
      state.cachedCustomer = {} as Customer;
      state.order_details = {};
      state.status = STATUSES.IDLE;
      state.error = null;
    },
  },
});

export const {
  getAllCustomers,
  clearCustomers,
  addCustomer,
  removeCustomer,
  editCustomer,

  setCustomerToEdit,

  setCustomerOrderDetails,

  setCachedCustomer,

  setCustomerStatus,
  setCustomerError,
  resetCustomerState,
} = customerSlice.actions;

export default customerSlice.reducer;

export function getCustomers(
  active?: boolean,
  pageNo: number = 1,
  query: string = "",
  pos = false,
  setQuery?: any,
  setAddNewCustomer?: any,
  focusRef?: any
) {
  return async function getCustomersThunk(dispatch, getState) {
    dispatch(setCustomerStatus(STATUSES.LOADING));

    let response;

    try {
      if (active) {
        response = await getActiveCustomer();
        dispatch(getAllCustomers(response.result));
      } else {
        response = await getCustomer(pageNo, query);
        dispatch(updateValues(response));
        dispatch(getAllCustomers(response.result.results));
      }

      const data = response.result.results || response.result;

      if (pos) {
        if (data?.length === 0) {
          if (onlyNumbers(query)) {
            setAddNewCustomer({
              state: true,
              customer_data: query,
            });
            setQuery({
              query: GENERAL_CONSTANTS.EMPTY_STRING,
              type: POS_HEADER_CONSTANTS.CUSTOMER_QUERY,
            });
          } else {
            Toast("No such customer found", TOAST_CONSTANTS.WARNING);
          }

          return;
        }

        if (onlyNumbers(query) && data?.length === 1) {
          data[0] &&
            data?.[0]?.id &&
            dispatch(getIndividualCustomer(data?.[0]?.id));
          data[0] &&
            data?.[0]?.id &&
            dispatch(getCustomerOrderDetails(data?.[0]?.id));
          focusRef?.current?.focus();
          setQuery({
            query: GENERAL_CONSTANTS.EMPTY_STRING,
            type: POS_HEADER_CONSTANTS.PRODUCT_QUERY,
          });
        }
      }
    } catch (error) {
      dispatch(setCustomerStatus(STATUSES.ERROR));
      dispatch(setCustomerError(error.message));
    } finally {
      dispatch(setCustomerStatus(STATUSES.IDLE));
    }

    // active
    //   ? getActiveCustomer()
    //       .then((response: any) => {
    //         dispatch(getAllCustomers(response.result));
    //       })
    //       .catch((error) => {
    //         dispatch(setCustomerStatus(STATUSES.ERROR));
    //         dispatch(setCustomerError(error.message));
    //       })
    //       .finally(() => {
    //         dispatch(setCustomerStatus(STATUSES.IDLE));
    //       })
    //   : getCustomer(pageNo, query)
    //       .then((response: any) => {
    //         dispatch(updateValues(response));
    //         dispatch(getAllCustomers(response.result.results));
    //         if (pos) {
    //           if (
    //             response.result?.length === 0 ||
    //             response.result.results?.length === 0
    //           ) {
    //             setAddNewCustomer({
    //               state: true,
    //               customer_data: query,
    //             });
    //             setQuery({
    //               query: GENERAL_CONSTANTS.EMPTY_STRING,
    //               type: POS_HEADER_CONSTANTS.CUSTOMER_QUERY,
    //             });
    //             return;
    //           }
    //         }
    //       })
    //       .catch((error) => {
    //         dispatch(setCustomerStatus(STATUSES.ERROR));
    //         dispatch(setCustomerError(error.message));
    //       })
    //       .finally(() => {
    //         dispatch(setCustomerStatus(STATUSES.IDLE));
    //       });
  };
}

export function getIndividualCustomer(id: number, edit: boolean = false) {
  return async function getIndividualCustomerThunk(dispatch, getState) {
    const {
      customer: { cachedCustomer },
    }: RootState["root"] = getState().root;

    if (
      id === ACCESS_CONSTANTS.CASH_SALES_ID &&
      cachedCustomer?.id === ACCESS_CONSTANTS.CASH_SALES_ID
    ) {
      dispatch(setCustomerToEdit(cachedCustomer));
      return;
    }

    dispatch(setCustomerStatus(STATUSES.LOADING));
    dispatch(setCommonStatus({ state: STATUSES.LOADING, type: "customer" }));
    getSpecificCustomer(id)
      .then((response: any) => {
        if (edit) {
          const dataToEdit = {
            designation: CUSTOMERS_CONSTANTS.DESIGNATION_OPTIONS.filter(
              (designation) =>
                designation.value === response.result?.designation
            ).map((designation) => ({
              value: designation.value,
              label: designation.label,
            })),
            name: response.result?.name,
            company_name: response.result.company_name
              ? response.result.company_name
              : "",
            email: response.result.email ? response.result.email : "",
            contact_number: response.result?.contact_number,
            remarks: response.result.remarks ? response.result.remarks : "",
            whatsapp_number: response.result.whatsapp_number
              ? response.result.whatsapp_number
              : "",
            dob: response.result.dob ? response.result.dob : "",
            anniversary: response.result?.anniversary,
            address: response.result.address
              ? {
                  ...response.result?.address,
                  address_line_1: response.result?.address?.address_line_1,
                  address_line_2: response.result?.address?.address_line_2,
                  city: [
                    {
                      value: response.result?.address?.city.id,
                      label: response.result?.address?.city.city,
                    },
                  ],
                  state: [
                    {
                      value: response.result?.address?.state.id,
                      label: response.result?.address?.state.state,
                    },
                  ],
                  country: [
                    {
                      value: response.result?.address?.country.id,
                      label: response.result?.address?.country.country,
                    },
                  ],
                  pincode: response.result?.address?.pincode,
                }
              : {
                  address_line_1: "",
                  address_line_2: "",
                  city: FORM_CONSTANTS.DEFAULT_CITY,
                  state: FORM_CONSTANTS.DEFAULT_STATE,
                  country: FORM_CONSTANTS.DEFAULT_COUNTRY,
                  pincode: FORM_CONSTANTS.DEFAULT_PINCODE,
                },
            is_active: [
              {
                value: response.result?.is_active,
                label:
                  response.result?.is_active === 1
                    ? GENERAL_CONSTANTS.ACTIVE
                    : GENERAL_CONSTANTS.INACTIVE,
              },
            ],
          };

          dispatch(setCustomerToEdit(dataToEdit));
        } else {
          dispatch(setCustomerToEdit(response.result));
          if (id === ACCESS_CONSTANTS.CASH_SALES_ID) {
            dispatch(setCachedCustomer(response.result));
          }
        }
      })
      .catch((error) => {
        dispatch(setCustomerStatus(STATUSES.ERROR));
        dispatch(setCustomerError(error.message));
      })
      .finally(() => {
        dispatch(setCommonStatus({ state: STATUSES.IDLE, type: "customer" }));
        dispatch(setCustomerStatus(STATUSES.IDLE));
      });
  };
}

export function addNewCustomer(
  customerParams: Object,
  actions: FormikHelpers<any>,
  navigate: NavigateFunction
) {
  return async function addNewCustomerThunk(dispatch, getState) {
    dispatch(setCustomerStatus(STATUSES.LOADING));
    setCustomer(customerParams)
      .then((response: any) => {
        dispatch(addCustomer(customerParams));
        actions.resetForm();
        actions.setSubmitting(false);
        navigate(CUSTOMERS_CONSTANTS.CUSTOMERS_NAVIGATE);
        Toast(`${response.message}`, "success");
      })
      .catch((error) => {
        dispatch(setCustomerStatus(STATUSES.ERROR));
        dispatch(setCustomerError(error.message));
        // Toast(`${error.message}`, "error");
      })
      .finally(() => {
        dispatch(setCustomerStatus(STATUSES.IDLE));
      });
  };
}

export function updateExistingCustomer(
  id: number,
  customerParams: Object,
  actions: FormikHelpers<any>,
  navigate: NavigateFunction
) {
  return async function updateExistingCustomerThunk(dispatch, getState) {
    dispatch(setCustomerStatus(STATUSES.LOADING));
    updateCustomer(id, customerParams)
      .then((response: any) => {
        dispatch(editCustomer(response.result));
        actions.resetForm();
        actions.setSubmitting(false);
        navigate(CUSTOMERS_CONSTANTS.CUSTOMERS_NAVIGATE);
        Toast(`${response.message}`, "success");
      })
      .catch((error) => {
        dispatch(setCustomerStatus(STATUSES.ERROR));
        dispatch(setCustomerError(error.message));
        // Toast(`${error.message}`, "error");
      })
      .finally(() => {
        dispatch(setCustomerStatus(STATUSES.IDLE));
      });
  };
}

export function getCustomerOrderDetails(id: number) {
  return async function getCustomerOrderDetailsThunk(dispatch, getState) {
    dispatch(setCustomerStatus(STATUSES.LOADING));
    dispatch(
      setCommonStatus({ state: STATUSES.LOADING, type: "customerDetails" })
    );
    getCustomerOrderDetail(id)
      .then((response: any) => {
        dispatch(
          setCustomerOrderDetails({
            last_bill_amount: to2Decimal(response.result.last_bill_amount || 0),
            total_purchase: to2Decimal(response.result.total_purchase),
            last_visited: formatDate(response.result.last_visited || ""),
          })
        );
      })
      .catch((error) => {
        dispatch(setCustomerStatus(STATUSES.ERROR));
        dispatch(setCustomerError(error.message));
      })
      .finally(() => {
        dispatch(
          setCommonStatus({ state: STATUSES.IDLE, type: "customerDetails" })
        );
        dispatch(setCustomerStatus(STATUSES.IDLE));
      });
  };
}

export function deleteCustomer(id: number) {
  return async function deleteCustomerThunk(dispatch, getState) {
    const {
      common: { current_page },
    }: RootState["root"] = getState().root;
    dispatch(setCustomerStatus(STATUSES.LOADING));
    delCustomer(id)
      .then((response: any) => {
        dispatch(getCustomers(false, current_page, ""));
        // dispatch(editCustomer(response.result));
        Toast(`${response.message}`, "success");
      })
      .catch((error) => {
        dispatch(setCustomerStatus(STATUSES.ERROR));
        dispatch(setCustomerError(error.message));
        Toast(`${error.message}`, "error");
      })
      .finally(() => {
        dispatch(setCustomerStatus(STATUSES.IDLE));
      });
  };
}

export function quickAddNewCustomer({
  customerParams,
  cashierData,
  navigate,
  setModal,
}: {
  customerParams: Object;
  cashierData?: any;
  navigate?: NavigateFunction;
  setModal?: React.Dispatch<React.SetStateAction<any>>;
}) {
  return async function quickAddNewCustomerThunk(dispatch, getState) {
    dispatch(
      setCommonStatus({
        state: STATUSES.LOADING,
        type: "quickAddCustomer",
      })
    );
    setCustomer(customerParams)
      .then((response: any) => {
        if (cashierData) {
          const dataToSend = {
            ...cashierData,
            customer: response.result.id,
          };
          dispatch(
            AddOrderPaymentMode({
              order: dataToSend,
              navigate: navigate,
              // navigateTo: ROUTES.CASHIER,
            })
          );
        } else {
          dispatch(addCustomer(response.result));
          dispatch(getIndividualCustomer(response.result.id));
          dispatch(getCustomerOrderDetails(response.result.id));
        }

        setModal({ state: false, customer_data: null });

        Toast(`${response.message}`, "success");
      })
      .catch((error) => {
        dispatch(
          setCommonStatus({
            state: STATUSES.ERROR,
            type: "quickAddCustomer",
          })
        );
        dispatch(setCustomerError(error.message));
        // Toast(`${error.message}`, "error");
      })
      .finally(() => {
        dispatch(
          setCommonStatus({
            state: STATUSES.IDLE,
            type: "quickAddCustomer",
          })
        );
      });
  };
}
