// This will have all the common functions and helper functions
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import type { RootState, AppDispatch } from "store/store";
import {
  GENERAL_CONSTANTS,
  POS_CART_CONSTANTS,
  POS_CONSTANTS,
  REQUEST_CONTENT_TYPE,
  REQUEST_METHOD,
  STATUSES,
  TOAST_CONSTANTS,
} from "utils/constants";
import axios, { AxiosRequestConfig } from "axios";
import { useEffect, useState } from "react";
import { defaultState } from "store/commonSlice";
import { updatePages } from "../store/commonSlice";
import { useSearchParams } from "react-router-dom";
import {
  IBillProductItem,
  ICart,
  ICartItem,
  IDiscount,
  IOrderProduct,
  IProductCMSDetails,
} from "./types";
import { setupInterceptorsTo } from "./interceptors";
import moment from "moment";
import { Bounce, TypeOptions, toast } from "react-toastify";

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

//*-----------------------------------------------------------------------------------------*//

//*-------------------Helper function for making an API request using AXIOS-----------------*//

//*-----------------------------------------------------------------------------------------*//

export const makeApiPublicRequest = async (
  requestURL: string,
  requestMethod: string,
  requestBody: any
) =>
  new Promise((resolve, reject) => {
    const requestHeaders = {
      "Content-Type": REQUEST_CONTENT_TYPE.APP_JSON,
    };

    // Make a request
    return axios({
      method: requestMethod,
      url: requestURL,
      headers: requestHeaders,
      data: requestBody,
    })
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            return reject(error.response.data.message);
          } else {
            return reject(error.response.message);
          }
        } else {
          return reject(error.message);
        }
      });
  });

export const makeApiRequest = (
  requestURL: string,
  requestMethod: string,
  requestBody: any,
  controller?: AbortController,
  responseType: "json" | "blob" = "json",
  contentType: string = REQUEST_CONTENT_TYPE.APP_JSON
) =>
  new Promise(async (resolve, reject) => {
    // Get bearer token from local storage
    const bearerToken = localStorage.getItem("accessToken");
    // Create request headers
    const requestHeaders = {
      "Content-Type": contentType,
      Authorization: "Bearer " + bearerToken,
    };

    if (contentType === REQUEST_CONTENT_TYPE.FORM_DATA) {
      delete requestHeaders.Authorization;
    }

    let newURL = "";

    if (requestMethod === REQUEST_METHOD.GET) {
      if (requestURL.includes("?")) {
        newURL = requestURL + `&dt=${Date.now()}`;
      } else {
        newURL = requestURL + `?dt=${Date.now()}`;
      }
    } else {
      newURL = requestURL;
    }

    try {
      let config: AxiosRequestConfig = {
        method: requestMethod,
        url: newURL,
        headers: requestHeaders,
        data: requestBody,
        responseType: responseType,
      };
      if (controller) {
        config.signal = controller.signal;
      }
      const response = await setupInterceptorsTo(config);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });

// Function to generate random number
export function randomNumber(min, max) {
  return Math.floor(Math.random() * (max - min) + min);
}

export const debounce = (func, delay = 1000) => {
  let timer;
  return function (...args) {
    const context = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      func.apply(context, args);
    }, delay);
  };
};

export function flattenArray(arr) {
  return (
    arr &&
    arr.length > 0 &&
    arr.reduce(
      (acc, val) =>
        Array.isArray(val) ? acc.concat(flattenArray(val)) : acc.concat(val),
      []
    )
  );
}

window.mobileAndTabletCheck = function () {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};
//*-----------------------------------------------------------------------------------------*//

//*--------- Helper function for setting and getting items from localstorage ---------------*//

//*-----------------------------------------------------------------------------------------*//

export const secondsToMidnight = (n) => {
  return (
    (24 - n.getHours() - 1) * 60 * 60 +
    (60 - n.getMinutes() - 1) * 60 +
    (60 - n.getSeconds())
  );
};

export function setItemWithExpiry(key, value, ttl) {
  const now = new Date();

  const item = {
    value: value,
    expiry: now.getTime() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
}

export function getItemWithExpiry(key) {
  const itemStr = localStorage.getItem(key);

  if (!itemStr) {
    return null;
  }

  const item = JSON.parse(itemStr);

  const now = new Date();

  if (now.getTime() > parseInt(item.expiry)) {
    localStorage.removeItem(key);

    return null;
  }

  return item.value;
}

//*-----------------------------------------------------------------------------------------*//

//*----------------------------Helper function for pagination-------------------------------*//

//*-----------------------------------------------------------------------------------------*//

export const usePagination = ({
  update_page,
  current_page,
  total_items,
  total_pages,
  page_size,
  query,
}) => {
  const dispatch = useAppDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const searchPageNo = searchParams.get("page") ? searchParams.get("page") : 1;

  const onBackButtonEvent = (e: PopStateEvent) => {
    e.preventDefault();
    return resetState();
  };

  const resetState = () => {
    dispatch(defaultState());
  };

  const pageChange = (pageNo: number) => {
    setSearchParams({ page: pageNo.toString() });
    dispatch(updatePages(Number(pageNo)));
  };

  const nextPage = () => {
    pageChange(current_page + 1);
  };

  const prevPage = () => {
    pageChange(current_page - 1);
  };

  useEffect(() => {
    // window.removeEventListener("popstate", onBackButtonEvent);

    if (searchPageNo) {
      pageChange(Number(searchPageNo));
    }
    update_page(searchPageNo, query);
    return () => {
      // window.addEventListener("popstate", onBackButtonEvent);
    };
  }, [dispatch, current_page, query]);

  return {
    resetState,
    prevClickHandler: prevPage,
    nextClickHandler: nextPage,
    pageChangeHandler: pageChange,
  };
};

export const useScreenSize = () => {
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return screenSize;
};

export const updatePage = (currentPage, totalPages, setPages) => {
  const maxPage = 5;
  const minPage = 1;

  if (currentPage <= maxPage - 2) {
    const test = Array.from({ length: totalPages })
      .map((_, index) => index + 1)
      .filter((page) => page >= minPage && page <= maxPage);
    setPages(test);
  } else {
    const startPage = Math.max(currentPage - 2, minPage);
    const endPage = Math.min(currentPage + 2, totalPages);

    const test = Array.from({ length: endPage - startPage + 1 })
      .map((_, index) => startPage + index)
      .filter((page) => page >= minPage && page <= totalPages);
    setPages(test);
  }
};

//*-----------------------------------------------------------------------------------------*//

//*----------------------------Helper function for Scroll To Error--------------------------*//

//*-----------------------------------------------------------------------------------------*//

export const ScrollToTop = () => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
};

//*-----------------------------------------------------------------------------------------*//

//*----------------------------Helper function for Scroll To Error--------------------------*//

//*-----------------------------------------------------------------------------------------*//

export const getFieldErrorNames = (formikErrors) => {
  const transformObjectToDotNotation = (obj, prefix = "", result = []) => {
    Object.keys(obj).forEach((key) => {
      const value = obj[key];
      if (!value) return;

      const nextKey = prefix ? `${prefix}.${key}` : key;
      if (typeof value === "object") {
        transformObjectToDotNotation(value, nextKey, result);
      } else {
        result.push(nextKey);
      }
    });

    return result;
  };

  return transformObjectToDotNotation(formikErrors);
};

//*-----------------------------------------------------------------------------------------*//

//*----------------------------Helper function for debounce---------------------------------*//

//*-----------------------------------------------------------------------------------------*//

// export const debounce = (func, delay) => {
//   let timer;
//   return function (...args) {
//     clearTimeout(timer);
//     timer = setTimeout(() => func.apply(this, args), delay);
//   };
// };

export const singleDeleteCheck = (formikValues, actions) => {
  if (actions.removedValue.id !== undefined) {
    return formikValues?.findIndex(
      (item) => item.id && item.id === actions.removedValue.id
    );
  } else {
    return formikValues?.findIndex(
      (item) => item.id && item.id === actions.removedValue.value
    );
  }
};

export const multipleDeleteCheck = (formikValues, actions) => {
  const arr = [];

  actions?.removedValues.forEach((item) => {
    formikValues?.findIndex((res) => {
      if (item.id !== undefined) {
        if (res.id === item.id) {
          arr.push(res.id);
        }
      } else {
        if (res.id === item.value) {
          arr.push(res.id);
        }
      }
    });
  });

  return arr;
};

//*-----------------------------------------------------------------------------------------*//

//*--------------------------------Toast Helper Functions-----------------------------------*//

//*-----------------------------------------------------------------------------------------*//

export const Toast = (
  message: string,
  type: TypeOptions,
  closeDuration = 3000
) => {
  toast(message, {
    position: "top-right",
    autoClose: closeDuration,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    type: type,
    transition: Bounce,
  });
};

//*-----------------------------------------------------------------------------------------*//

//*----------------------Helper Function to delete MultiSelect Value------------------------*//

//*-----------------------------------------------------------------------------------------*//

export const handleOptionDelete = (
  deleteField,
  formikValues,
  actions,
  deleteFunction,
  setState,
  loadingState,
  multipleDelete = false
) => {
  loadingState(STATUSES.LOADING);
  if (actions?.action == "remove-value" || actions?.action == "pop-value") {
    if (singleDeleteCheck(formikValues?.values[deleteField], actions) !== -1) {
      if (actions.removedValue.id !== undefined) {
        deleteFunction(actions?.removedValue.id);
        setState(actions?.removedValue.value);
      } else {
        deleteFunction(actions?.removedValue.value);
        setState(actions?.removedValue.value);
      }

      Toast("Deleted option successfully", "success");
    }
  } else if (actions?.action == "clear") {
    const valuesToDelete = multipleDeleteCheck(
      formikValues?.values[deleteField],
      actions
    );
    if (valuesToDelete.length > 0) {
      multipleDelete
        ? deleteFunction(valuesToDelete)
        : valuesToDelete.forEach((item) => {
            deleteFunction(item);
          });
      Toast("Deleted all options successfully", "success");
      setState([]);
    }
  }
  if (actions?.action === "clear") {
    formikValues?.setFieldValue(deleteField, []);
  } else {
    formikValues?.setFieldValue(
      deleteField,
      formikValues?.values[deleteField].filter(
        (item) => item.value !== actions?.removedValue.value
      )
    );
  }
  loadingState(STATUSES.IDLE);
};

//*-----------------------------------------------------------------------------------------*//

//*--------------------Helper Function for calculating Pricing -----------------------------*//

//*-----------------------------------------------------------------------------------------*//

export const calculateProductsInProductGroup = (
  items: any[],
  productGroupId: number
) => {
  return items.filter(
    (item: any) =>
      item.product_group && item.product_group.id === productGroupId
  ).length;
};

export const calculateDiscount = (mrp, discount, discountType) => {
  return discountType === 4 ? mrp - (mrp * discount) / 100 : mrp - discount;
};

export const calculateSellingPrice = (
  mrp: number,
  sellingDiscount: number,
  discountType: number,
  ceil: boolean
) => {
  if (ceil) {
    return Math.ceil(calculateDiscount(mrp, sellingDiscount, discountType));
  } else {
    return Math.floor(calculateDiscount(mrp, sellingDiscount, discountType));
  }
};

//*-----------------------------------------------------------------------------------------*//

//*--------------------Helper Function for filtering null elements--------------------------*//

//*-----------------------------------------------------------------------------------------*//

export function filterNullElements(arr): any[] {
  if (
    arr &&
    arr.every((element) => element === null && element === undefined)
  ) {
    return [];
  } else {
    return (
      arr && arr.filter((element) => element !== null && element !== undefined)
    );
  }
}

export function getNewValues(valuesFromForm, valuesFromDB) {
  if (valuesFromForm.length === 0) {
    return [];
  }
  const existingValues = new Set(
    valuesFromDB.map((item) => item && item.value)
  );

  const newValues = valuesFromForm.filter(
    (item) => !existingValues.has(item.value)
  );

  return newValues.map((item) => item.value);
}

//*-----------------------------------------------------------------------------------------*//

//*--------------------------------POS Helper Functions-------------------------------------*//

//*-----------------------------------------------------------------------------------------*//

export const findStore = (id: number, stores: any[]) => {
  const store = stores?.find((store) => store?.id === id);
  return store;
};

export const groupByHamperCode = (input, key) => {
  return input.reduce((acc, currentValue) => {
    let groupKey = currentValue[key]?.hamper_code;
    if (!groupKey) {
      groupKey = "undefined";
    }
    if (!acc[groupKey]) {
      acc[groupKey] = [];
    }
    acc[groupKey].push(currentValue);
    return acc;
  }, {});
};

export const filterCartItemsByHamperCode = (cartItems: any[]) => {
  const groupedCartItems = groupByHamperCode(cartItems, "product_group");
  return Object.entries(groupedCartItems);
};

export const filterCartItems = (cartItems: any[]) => {
  // const sortedCartItems = [...cartItems].sort((a, b) =>
  //   a.productGroup.product_group_code.localeCompare(
  //     b.productGroup.product_group_code
  //   )
  // );

  const groupedCartItems = groupBy(cartItems, "productGroup");
  return Object.entries(groupedCartItems);
};

const arr = (x: any) => Array.from(x);
const num = (x: any) => Number(x) || 0;
const str = (x: any) => String(x);
const isEmpty = (xs: any) => xs.length === 0;
const take = (n: any) => (xs: any) => xs.slice(0, n);
const drop = (n: any) => (xs: any) => xs.slice(n);
const reverse = (xs: any) => xs.slice(0).reverse();
const comp = (f: any) => (g: any) => (x: any) => f(g(x));
const not = (x: any) => !x;
const chunk = (n: any) => (xs: any) =>
  isEmpty(xs) ? [] : [take(n)(xs), ...chunk(n)(drop(n)(xs))];

export const numToWords = (n: any) => {
  const a = [
    "",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
    "ten",
    "eleven",
    "twelve",
    "thirteen",
    "fourteen",
    "fifteen",
    "sixteen",
    "seventeen",
    "eighteen",
    "nineteen",
  ];

  const b = [
    "",
    "",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ];

  const g = [
    "",
    "thousand",
    "million",
    "billion",
    "trillion",
    "quadrillion",
    "quintillion",
    "sextillion",
    "septillion",
    "octillion",
    "nonillion",
  ];

  const makeGroup = ([ones, tens, huns]: any[]) => {
    return [
      num(huns) === 0 ? "" : a[huns] + " hundred ",
      num(ones) === 0 ? b[tens] : (b[tens] && b[tens] + "-") || "",
      a[tens + ones] || a[ones],
    ].join("");
  };

  const thousand = (group: any, i: number) =>
    group === "" ? group : `${group} ${g[i]}`;

  if (typeof n === "number") return numToWords(String(n));

  if (n === "0") return "zero";

  return comp(chunk(3))(reverse)(arr(n))
    .map(makeGroup)
    .map(thousand)
    .filter(comp(not)(isEmpty))
    .reverse()
    .join(" ");
};

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function capitalizeWithSeperator(string: string, seperator: string) {
  return string
    .split(seperator)
    .map((item) => capitalizeFirstLetter(item))
    .join(" ");
}

export const isPopUpDisabled = (): boolean => {
  var newWindow = window.open("", "_blank", "width=100,height=100");
  try {
    newWindow.close();
    return false;
  } catch (error) {
    return true;
  }
};

export const testPopUp = (path) => {
  let win = window.open(path, "_blank");
  let loading = setTimeout(function () {
    //Browser has blocked it
    alert("Please allow popups for this website");
  }, 1000);

  try {
    win.addEventListener("load", function () {
      clearTimeout(loading);
    });
  } catch (e) {
    clearTimeout(loading);
    alert("Please allow popups for this website");
  }
};

export const promisedPopupBlockerTest = async () => {
  return new Promise((resolve, reject) => {
    try {
      const result = { hasBlocker: true }; // result default.

      const handle = window.open(
        "",
        "",
        "popup=true,width=1,height=1,top=2000"
      );
      if (!!handle) {
        handle.close();
        result.hasBlocker = false; // result value change.
      }
      resolve(result); // resolve always with a valid result.
    } catch (reason) {
      reject(reason); // reject for/with whatever reason.
    }
  });
};

export function downloadFileObject(base64String) {
  const linkSource = base64String;
  const downloadLink = document.createElement("a");
  const fileName = "convertedPDFFile.pdf";
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
}

export const base64ToArrayBuffer = (data) => {
  const bString = window.atob(data);
  const bLength = bString.length;
  const bytes = new Uint8Array(bLength);
  for (let i = 0; i < bLength; i++) {
    const ascii = bString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
};

export const makePDFWindow = (base64String) => {
  const content = base64ToArrayBuffer(base64String);

  const blob = new Blob([content], {
    type: "application/pdf",
  });

  const url = window.URL.createObjectURL(blob);

  const iframe = document.createElement("iframe");

  iframe.style.display = "none";
  iframe.src = url;
  document.body.appendChild(iframe);
  iframe.contentWindow.print();
};

export const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize),
      byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const printBill = (
  response: any,
  setPrintOptionsModal?: React.Dispatch<React.SetStateAction<boolean>>
): any[] => {
  if (response.result.advance) {
    const printArray = [];

    printArray.push({
      type: "bill",
      content: response.result.bill,
    });
    printArray.push({
      type: "credit",
      content: response.result.advance,
    });
    setPrintOptionsModal && setPrintOptionsModal(true);

    return printArray;
  }
  const content = base64ToArrayBuffer(
    !setPrintOptionsModal
      ? response.result.credit_note
        ? response.result.credit_note
        : response.result.bill
      : response.result.bill
  );
  const blob = new Blob([content], { type: "application/pdf" });
  const url = window.URL.createObjectURL(blob);

  // function setPrint() {
  //   const closePrint = () => {
  //     document.body.removeChild(this);
  //   };
  //   this.contentWindow.onbeforeunload = closePrint;
  //   this.contentWindow.onafterprint = closePrint;
  //   this.contentWindow.print();
  // }

  // const hideFrame = document.createElement("iframe");
  // hideFrame.onload = setPrint;
  // hideFrame.style.display = "none"; // hide iframe
  // hideFrame.src = url;
  // document.body.appendChild(hideFrame);

  const iframe = document.createElement("iframe");
  iframe.style.display = "none";
  iframe.src = url;
  document.body.appendChild(iframe);
  iframe.contentWindow.print();
  return [];
};

//*-----------------------------------------------------------------------------------------*//

//*-----Helper functions to transform the server data to the format required by the app-----*//

//*-----------------------------------------------------------------------------------------*//

export const transformProduct = (
  inputData: any,
  selectedStore: any,
  quantity?: any,
  productGroup: {
    id: number;
    product_group_code: string;
    product_group_name: string;
    selling_price: number;
    products_quantity: number;
  } = {
    id: 0,
    product_group_code: "",
    product_group_name: "",
    selling_price: 0,
    products_quantity: 0,
  }
) => {
  const test = {
    id: inputData.id,
    store_id: selectedStore,
    print_name: inputData.print_name,
    product_code: inputData.product_code ? inputData.product_code : "",
    is_active: inputData.is_active,
    price: inputData.price,
    tax: {
      id: inputData?.tax?.id,
      tax_rate: Number(
        inputData?.tax?.find((item) => item?.tax?.tax_type !== 2)?.tax?.tax_rate
      ),
      cess:
        Number(
          inputData?.tax?.find((item) => item?.tax?.tax_type === 2)?.tax
            ?.tax_rate
        ) || 0,
    },
    hsn: {
      id: inputData.hsn_code.id,
      hsn_code: inputData.hsn_code.hsn_code,
    },
    uom: {
      id: inputData.selling_uom.id,
      uom_code: inputData.selling_uom.uom_code,
    },
    count: 1,
    meddler: inputData.barcode_type.id === 5 || productGroup.id > 0,
    productGroup: productGroup,
  };
  if (quantity) {
    test["quantity"] = Number(
      (productGroup.id > 0
        ? quantity
        : inputData.barcode_type.id === 5
        ? quantity / 1000
        : 1
      ).toFixed(3)
    );
  }
  return test;
};

export const transformCustomer = (inputData) => {
  return {
    id: inputData.id,
    name: inputData.name,
    // customer_code: inputData.code ? inputData.code : '',
    customer_address: {
      address_line_1: inputData.address.address_line_1,
      address_line_2: inputData.address.address_line_2,
      city: inputData.address.city.id,
      state: inputData.address.state.id,
      country: inputData.address.country.id,
      pincode: inputData.address.pincode,
    },
    contact_number: inputData.contact_number,
    customer_whatsapp_number: inputData.whatsapp_number,
    email: inputData.email,
    dob: inputData.dob,
    anniversary: inputData.anniversary,
    is_active: inputData.is_active,
    remarks: inputData.remarks,
  };
};

export const transformDiscount = (inputData) => {
  return {
    id: inputData.id,
    is_active: inputData.is_active,
    discount_code: inputData.discount_code,
    discount_value: inputData.discount_value,
    discount_type:
      inputData.discount_type === 4 * 1
        ? "percentage"
        : inputData.discount_type === 5 * 1
        ? "flat"
        : "",
    discount_applicable:
      inputData.discount_applicable === 6 * 1
        ? "bill"
        : inputData.discount_applicable === 7 * 1
        ? "product"
        : "",
  };
};

//*------------------------Helper function to groupBy the data-------------------------------*//

export const groupBy = (input, key) => {
  return input.reduce((acc, currentValue, currentIndex) => {
    let groupKey = currentValue[key]?.product_group_code;
    if (!groupKey) {
      groupKey = "undefined";
    }
    if (!acc[groupKey]) {
      acc[groupKey] = [];
    }
    acc[groupKey].push({ ...currentValue, originalIndex: currentIndex });
    return acc;
  }, {});
};

//*------------------------Helper function to format the date-------------------------------*//

export function dateIsValid(date) {
  return !Number.isNaN(new Date(date).getTime());
}

export const formatDateTime = (date: string) => {
  const parsedTimestamp = moment(date);
  // const modifiedTimestamp = parsedTimestamp.add(5, "hours").add(30, "minutes");
  return parsedTimestamp.format("DD MMM YYYY [at] hh:mm A");
};

export const formatDate = (date?: any) => {
  if (dateIsValid(date)) {
    return moment(date).format("YYYY-MM-DD");
  } else {
    return moment(new Date()).format("YYYY-MM-DD");
  }
};

export const formatTime = (time: Date) => {
  if (dateIsValid(time)) {
    const hours = time.getHours().toFixed(0).padStart(2, "0");
    const minutes = time.getMinutes().toFixed(0).padStart(2, "0");

    return `${hours}:${minutes}`;
  } else {
    return moment(new Date()).format("hh:mm");
  }
};

export const financialYear = (date: Date) => {
  const month = (date.getMonth() + 1).toString().padStart(2, "0");

  if (Number(month) < 4) {
    return `${(date.getFullYear() - 1)
      .toString()
      .substring(2, 4)}-${date.getFullYear().toString().substring(2, 4)}`;
  }

  return `${date.getFullYear().toString().substring(2, 4)}-${(
    date.getFullYear() + 1
  )
    .toString()
    .substring(2, 4)}`;
};

//*-----------------------------------------------------------------------------------------*//

//*-------------------------Helper functions for checking discount--------------------------*//

//*-----------------------------------------------------------------------------------------*//

interface IChangeDiscountTypeProps {
  wholeCart: ICart;
  applyTo: ICartItem | false;
  addDiscount: (value) => void;
  validDiscountCheck: (value) => boolean;
  discount: IDiscount;
  setDiscount: React.Dispatch<React.SetStateAction<IDiscount>>;
}

export const changeDiscountType = ({
  wholeCart,
  applyTo,
  addDiscount,
  validDiscountCheck,
  discount,
  setDiscount,
}: IChangeDiscountTypeProps) => {
  const item = applyTo ? applyTo : wholeCart;

  let switchDiscount = discount;

  switchDiscount = {
    ...switchDiscount,
    discount_type:
      item.discount.discount_type !== "flat" ? "flat" : "percentage",
  };
  if (validDiscountCheck(switchDiscount)) {
    addDiscount(switchDiscount);
    setDiscount(switchDiscount);
  } else {
    setDiscount({
      ...discount,
      discount_value: 0,
    });
    Toast("Discount cant be applied", "error");
  }
};

interface IChangeDiscountValueProps {
  discountValue: number;
  discount: IDiscount;
  setDiscount: React.Dispatch<React.SetStateAction<IDiscount>>;
}

export const changeDiscountValue = ({
  discountValue,
  discount,
  setDiscount,
}: IChangeDiscountValueProps) => {
  let updatedDiscount = discount;
  updatedDiscount = {
    ...updatedDiscount,
    discount_value: discountValue,
  };
  setDiscount(updatedDiscount);
};

interface IApplyDiscountProps {
  wholeCart: ICart;
  applyTo: ICartItem | false;
  addDiscount: () => void;
  removeDiscount: () => void;
  validDiscount: boolean;
  discount: IDiscount;
  setDiscount: React.Dispatch<React.SetStateAction<IDiscount>>;
}

export const applyDiscount = ({
  wholeCart,
  applyTo,
  addDiscount,
  removeDiscount,
  validDiscount,
  discount,
  setDiscount,
}: IApplyDiscountProps) => {
  const item = applyTo ? applyTo : wholeCart;
  if (
    (discount.discount_type === "flat" &&
      item.discount.discount_type &&
      item.discount.discount_type === "flat") ||
    (discount.discount_type === "percentage" &&
      item.discount.discount_type &&
      item.discount.discount_type !== "flat")
  ) {
    if (discount.discount_value === 0) {
      removeDiscount();
    } else {
      if (validDiscount) {
        addDiscount();
      } else {
        Toast("Discount cant be applied", "error");
        setDiscount({
          ...discount,
          discount_value: 0,
        });
      }
    }
  }
};

export function makeid(length) {
  let result = "";
  const characters = "0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

//*-----------------------------------------------------------------------------------------*//

//*----------Helper functions to calculate all the necessary values for the cart------------*//

//*-----------------------------------------------------------------------------------------*//

export const calculateAllValues = (cart: any) => {
  const initial_net_payable = cart.cartItems.reduce((acc, item) => {
    const item_amount = to2Decimal(item.selling_price);

    const discount_amount =
      item.discount.discount_type === "percentage"
        ? to2Decimal(item_amount * (item.discount.discount_value / 100))
        : item.discount.discount_value;

    const amount_with_discount = to2Decimal(
      (item_amount - discount_amount) * item.quantity
    );

    return acc + to2Decimal(amount_with_discount);
  }, 0);

  const total_items = cart.cartItems.reduce((acc, item) => {
    if (item.productGroup && item.productGroup.id > 0) {
      return (
        acc + to2Decimal(item.quantity / item.productGroup.products_quantity)
      );
    }
    return acc + item.quantity;
  }, 0);

  const total_quantity = to2Decimal(
    cart.cartItems.reduce((acc, item) => {
      if (item.productGroup.products_quantity > 0) {
        return acc + item.count / item.productGroup.products_quantity;
      } else {
        return acc + item.count;
      }
    }, 0)
  );

  const total_tax = to2Decimal(
    cart.cartItems.reduce((acc: number, item: ICartItem) => {
      return acc + item.tax_applied;
    }, 0)
  );

  const discount_to_send = to2Decimal(
    cart.cartItems.reduce((acc: number, item: ICartItem) => {
      return acc + to2Decimal(item.discount_applied * item.quantity);
    }, 0)
  );

  const total_discount = to2Decimal(
    cart.cartItems.reduce((acc: number, item: ICartItem) => {
      return item.discount_applied > 0
        ? acc + to2Decimal(item.discount_applied * item.quantity)
        : // acc + item.discount_applied
          acc + 0;
    }, 0)
  );

  const bill_total = to2Decimal(
    cart.cartItems.reduce((acc: number, item: ICartItem) => {
      return acc + item.total_amount;
    }, 0)
  );

  const total_payable = to2Decimal(
    cart.cartItems.reduce((acc: number, item: ICartItem) => {
      return acc + item.payable_amount;
    }, 0)
  );

  // const credit_note_discount = cart.credit_note && to2Decimal(cart.credit_note);

  const bill_discount =
    cart.discount.discount_value > 0
      ? cart.discount.discount_type === "percentage"
        ? to2Decimal(initial_net_payable * (cart.discount.discount_value / 100))
        : to2Decimal(cart.discount.discount_value)
      : 0;

  const final_discount = to2Decimal(total_discount);

  const final_amount_to_pay = total_payable;

  return {
    total_items,
    bill_discount,
    total_quantity: Math.round(total_quantity),
    total_tax,
    total_discount,
    bill_total,
    total_payable,
    final_discount,
    final_amount_to_pay,
    initial_net_payable,
    discount_to_send,
  };
};

export const calculateTax = (
  payable_amount: number,
  tax: number,
  cess: number,
  net_payable_amount?: number,
  bill_discount?: number
) => {
  const new_payable_amount = to2Decimal(
    bill_discount !== undefined
      ? payable_amount -
          to2Decimal(bill_discount * (payable_amount / net_payable_amount))
      : payable_amount
  );

  const cessAmount =
    new_payable_amount - (new_payable_amount * 100) / (100 + cess);

  const gst =
    new_payable_amount -
    cessAmount -
    ((new_payable_amount - cessAmount) * 100) / (100 + tax);

  return {
    gst: to2Decimal(gst),

    cess: to2Decimal(cessAmount),

    bill_discount_per_item: to2Decimal(
      bill_discount !== undefined
        ? to2Decimal(bill_discount * (payable_amount / net_payable_amount))
        : 0
    ),

    new_payable_amount: to2Decimal(new_payable_amount),
  };
};

export const updateValuesForItem = (
  selling_price: number,
  new_quantity: number,
  // tax: number,
  discount: IDiscount
) => {
  // const item_amount = to2Decimal(selling_price * new_quantity);
  const item_amount = to2Decimal(selling_price);

  const discount_amount =
    discount.discount_type === "percentage"
      ? to2Decimal(item_amount * (discount.discount_value / 100))
      : discount.discount_value;

  const amount_with_discount = to2Decimal(
    (item_amount - discount_amount) * new_quantity
  );

  // const tax_amount = item_amount - (item_amount * 100) / (100 + tax);

  return {
    // tax_amount: to2Decimal(tax_amount),
    discount_amount: to2Decimal(discount_amount),
    total_amount: to2Decimal(selling_price * new_quantity),
    payable_amount: to2Decimal(amount_with_discount),
  };
};

export const updateValuesForReturn = (
  currentReturn: IOrderProduct,
  quantity: number,
  remarks: string,
  index?: number
) => {
  const igst =
    (currentReturn.igst /
      (currentReturn.returned
        ? currentReturn.originalQuantity
        : currentReturn.quantity)) *
    quantity;

  const cgst =
    (currentReturn.cgst /
      (currentReturn.returned
        ? currentReturn.originalQuantity
        : currentReturn.quantity)) *
    quantity;

  const sgst =
    (currentReturn.sgst /
      (currentReturn.returned
        ? currentReturn.originalQuantity
        : currentReturn.quantity)) *
    quantity;

  const cess =
    (currentReturn.cess /
      (currentReturn.returned
        ? currentReturn.originalQuantity
        : currentReturn.quantity)) *
    quantity;

  const total_tax = igst + cgst + sgst + cess;

  const total_discount =
    (currentReturn.total_discount /
      (currentReturn.returned
        ? currentReturn.originalQuantity
        : currentReturn.quantity)) *
    quantity;

  const refundItem = {
    product_group: currentReturn.product_group
      ? currentReturn.product_group
      : null,
    batch: currentReturn.batch.id,
    price_with_discount: to2Decimal(
      currentReturn.payable_amount / currentReturn.quantity
    ),
    price: to2Decimal(
      currentReturn.total_amount /
        (currentReturn.returned
          ? currentReturn.originalQuantity
          : currentReturn.quantity)
    ),
    product: currentReturn.product.id,
    quantity: quantity,
    remarks: remarks,
    igst: to2Decimal(igst),
    cgst: to2Decimal(cgst),
    sgst: to2Decimal(sgst),
    cess: to2Decimal(cess),
    discount_type: currentReturn.discount_type,
    discount_value: currentReturn.discount_value,
    total_tax: to2Decimal(total_tax),
    total_discount: to2Decimal(total_discount),
    total_amount:
      (currentReturn.payable_amount /
        (currentReturn.returned
          ? currentReturn.originalQuantity
          : currentReturn.quantity)) *
      quantity,
  };

  index !== undefined && (refundItem["index"] = index);
  return refundItem;
};

export const checkValuesForDiscount = (
  cart: ICart,
  discount: IDiscount,
  cartItem: ICartItem
): boolean => {
  const { cartItems } = cart;

  if (cartItem) {
    const { bill_discount, initial_net_payable } = calculateAllValues(cart);

    const index = cartItem.originalIndex;
    // const index = cartItems.findIndex((item) => {
    //   if (item.batch_id === cartItem.batch_id) {
    //     if (cartItem.productGroup === undefined) {
    //       return true;
    //     } else if (
    //       item.productGroup &&
    //       item.productGroup.id === cartItem.productGroup.id
    //     ) {
    //       return true;
    //     }
    //   }
    //   return false;
    // });

    const {
      // tax_amount,
      discount_amount,
      total_amount,
      payable_amount,
    } = updateValuesForItem(
      cartItem?.selling_price,
      cartItem?.quantity,
      // 12,
      discount
    );

    const {
      gst,
      cess,
      new_payable_amount,
      bill_discount_per_item,
    } = calculateTax(
      to2Decimal(payable_amount),
      cartItem.tax.tax_rate ? cartItem.tax.tax_rate : 0,
      cartItem.tax.cess ? cartItem.tax.cess : 0,
      initial_net_payable,
      bill_discount
    );

    const newCartItem = {
      ...cartItems[index],
      discount: discount,
      cess: cess,
      cgst: gst / 2,
      sgst: gst / 2,
      igst: 0,
      tax_applied: gst + cess,
      discount_applied: to2Decimal(discount_amount + bill_discount_per_item),
      total_amount: total_amount,
      payable_amount: to2Decimal(new_payable_amount),
    };

    const test = [...cartItems];
    test[index] = newCartItem;
    const newCartItems = test;
    const newCart = { ...cart };
    newCart.cartItems = newCartItems;

    const { final_amount_to_pay } = calculateAllValues(newCart);

    const creditNote =
      cart.credit_note.applyCredit > 0
        ? Number(cart.credit_note.applyCredit)
        : 0;

    if (final_amount_to_pay - creditNote >= 0) {
      return cartItem.meddler
        ? cartItem.mrp -
            to2Decimal(cartItem.bill_discount_per_item / cartItem.quantity) >=
            to2Decimal(discount_amount)
        : cartItem.selling_price -
            to2Decimal(cartItem.bill_discount_per_item / cartItem.quantity) >=
            to2Decimal(discount_amount);
    } else {
      return false;
    }
  }
};

export const setDiscountType = (
  prevDiscount: IDiscount,
  validCondition: (value) => boolean,
  changeDiscountType: (value) => void,
  clearDiscount: () => void
) => {
  let switchDiscount = {
    ...prevDiscount,
    discount_type:
      prevDiscount.discount_type !== GENERAL_CONSTANTS.FLAT
        ? GENERAL_CONSTANTS.FLAT
        : GENERAL_CONSTANTS.PERCENTAGE,
  };
  if (validCondition(switchDiscount)) {
    changeDiscountType(switchDiscount);
  } else {
    clearDiscount();
    Toast(POS_CART_CONSTANTS.DISCOUNT_ERROR_MESSAGE, TOAST_CONSTANTS.ERROR);
  }
};

export const validateDiscount = (
  prevDiscount: IDiscount,
  newDiscount: IDiscount,
  validCondition: (value) => boolean,
  changeDiscountValue: (value) => void,
  clearDiscount: () => void
) => {
  if (
    (newDiscount.discount_type === GENERAL_CONSTANTS.FLAT &&
      prevDiscount.discount_type &&
      prevDiscount.discount_type === GENERAL_CONSTANTS.FLAT) ||
    (newDiscount.discount_type === GENERAL_CONSTANTS.PERCENTAGE &&
      prevDiscount.discount_type &&
      prevDiscount.discount_type !== GENERAL_CONSTANTS.FLAT)
  ) {
    if (newDiscount.discount_value === 0) {
      clearDiscount();
    } else {
      if (validCondition(newDiscount)) {
        changeDiscountValue(newDiscount);
      } else {
        clearDiscount();
        Toast(POS_CART_CONSTANTS.DISCOUNT_ERROR_MESSAGE, TOAST_CONSTANTS.ERROR);
      }
    }
  }
};

export const to2Decimal = (num) => {
  if (num?.toString()?.includes("-")) {
    return Number(num);
  } else {
    return Number(Number(num).toFixed(2));
  }
};

export const displayValue = (num: number) => {
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
  });
  return formatter.format(num);
};

export function containsNumbers(str: string): boolean {
  if (!str) return false;
  return Boolean(str.match(/\d/));
}

export function onlyNumbers(e): boolean {
  // const charCode = e.which ? e.which : e.keyCode;
  // if (charCode > 31 && (charCode < 48 || charCode > 57)) {
  //   return false;
  // }
  // return true;

  const re = /^[0-9\b]+$/;
  if (e === "" || re.test(e)) {
    return true;
  }
  return false;
}

export function isBarcode(str: string): boolean {
  if (!str) return false;
  return Boolean(str.match(/[A-Z](\d){2,}|(\d){4,}/));
}

//*-----------------------------------------------------------------------------------------*//

export const generateNutritionDetails = (
  producttoEdit,
  nutrientData,
  uomData
) => {
  if (producttoEdit?.nutrition_details?.length === 0) {
    return [
      {
        nutrient: [{ value: 6, label: "Energy" }],
        nutrition_numeric_value: 0,
        uom: [],
        new: true,
      },
      {
        nutrient: [{ value: 8, label: "Total Fat" }],
        nutrition_numeric_value: 0,
        uom: [],
        new: true,
      },
      {
        nutrient: [{ value: 7, label: "Carbohydrates" }],
        nutrition_numeric_value: 0,
        uom: [],
        new: true,
      },
      {
        nutrient: [{ value: 10, label: "Sugar" }],
        nutrition_numeric_value: 0,
        uom: [],
        new: true,
      },
      {
        nutrient: [{ value: 9, label: "Protein" }],
        nutrition_numeric_value: 0,
        uom: [],
        new: true,
      },
    ];
  }

  const nutritionDetails = producttoEdit?.nutrition_details?.map((item) => ({
    ...item,
    nutrient: item.nutrient
      ? [
          {
            value: item.nutrient.id,
            label: item.nutrient.nutrient_name,
          },
        ]
      : "",
    nutrition_numeric_value: item.nutrition_numeric_value,
    uom: item.uom
      ? [
          {
            value: item.uom.id,
            label: item.uom.uom_name,
          },
        ]
      : [],
  }));

  return nutritionDetails;
};

export const constructStorePrices = (
  productToEdit,
  branchData,
  discountTypeData
) => {
  if (!productToEdit) return null;

  const new_price = [];

  for (const BATCH of productToEdit.price) {
    const existing_store_price = branchData.map((store) => {
      const store_price = BATCH.store_prices.find(
        (price) => price.store === store.id
      );

      const selling_discount_type = discountTypeData.find(
        (dis) => dis.id === store_price?.selling_discount_type
      );

      return {
        ...store_price,
        batch: BATCH.id,
        store: store.store_name,
        mrp: store_price?.mrp || 0,
        selling_discount: store_price?.selling_discount || 0,
        selling_price: store_price?.selling_price || 0,
        minimum_selling_quantity: store_price?.minimum_selling_quantity || 0,
        selling_discount_type: selling_discount_type
          ? [
              {
                value: selling_discount_type.id,
                label: selling_discount_type.type,
              },
            ]
          : [],
        ceil: !!store_price?.ceil,
      };
    });

    new_price.push({
      ...BATCH,
      store_prices: existing_store_price,
    });
  }

  return new_price;
};

export const constructMSQ = (productToEdit, branchData) => {
  const new_msq = [];

  for (const store of branchData) {
    const store_msq = productToEdit.minimum_selling_quantity.find(
      (msq) => msq.store.id === store.id
    );
    new_msq.push({
      ...store_msq,
      store: store.store_name,
      days: {
        Mon: store_msq?.monday || 0,
        Tue: store_msq?.tuesday || 0,
        Wed: store_msq?.wednesday || 0,
        Thu: store_msq?.thursday || 0,
        Fri: store_msq?.friday || 0,
        Sat: store_msq?.saturday || 0,
        Sun: store_msq?.sunday || 0,
      },
    });
  }

  return new_msq;
};

export const constructProductOptions = (productData) => {
  return productData && productData.length > 0
    ? productData.map((item, index) => {
        const dataOptions: any = { ...item };
        const isProduct = item.print_name !== undefined;

        const price =
          (isProduct &&
            dataOptions.price &&
            dataOptions.price.store_prices &&
            dataOptions.price.store_prices.length > 0) ||
          (!isProduct &&
            dataOptions.store_price &&
            dataOptions.store_price.length > 0)
            ? isProduct
              ? dataOptions.price.store_prices[0].selling_price
              : dataOptions.store_price[0].selling_price
            : 0;
        return {
          type: dataOptions.print_name ? "product" : "productGroup",
          value: dataOptions.id,
          label: `${
            isProduct ? dataOptions.print_name : dataOptions.print_hamper_name
          }  ${isProduct ? `| ${dataOptions.net_weight}` : ""} ${
            isProduct ? (dataOptions.uom ? dataOptions.uom.uom_code : "") : ""
          } | ${POS_CONSTANTS.RUPEE_SIGN}${price}`,
          // ...productData[index],
        };
      })
    : [];
};

export function checkIfFilesAreTooBig(files?: any[]): boolean {
  let valid = true;
  if (files) {
    files.map((file) => {
      const size = file.size / 1024 / 1024;
      if (size > 10) {
        valid = false;
      }
    });
  }
  return valid;
}

export function checkIfFilesAreCorrectType(
  files: any[],
  fileTypes: string[]
): boolean {
  let valid = true;
  if (files) {
    files.map((file) => {
      if (!fileTypes.includes(file.type.split("/")[1])) {
        valid = false;
      }
    });
  }
  return valid;
}

export const createPreviewImg = (file: File) => {
  return URL.createObjectURL(file);
};

export const jsonDataToFormData = (data: any): FormData => {
  const formData = new FormData();

  function traverse(obj: Object, prefix: string) {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        let propName = prefix ? `${prefix}[${key}]` : key;
        if (typeof obj[key] === "object" && !(obj[key] instanceof File)) {
          traverse(obj[key], propName);
        } else {
          formData.append(propName, obj[key]);
        }
      }
    }
  }

  traverse(data, "");

  return formData;
};

export function truncateString(str, maxLength) {
  if (str?.length > maxLength) {
    return str.substring(0, maxLength - 3) + "...";
  }
  return str;
}
export function processWidgetData(widgets) {
  return widgets && widgets.length > 0
    ? widgets.map((item, widgetIndex) => {
        return {
          ...item,
          priority: widgetIndex + 1,
          properties: item.properties.map((property, propertyIndex) => {
            if (property.type === "image") {
              return {
                ...property,
                value: property.value?.preview,
              };
            } else if (property.draggable_list || property.type === "select") {
              return {
                ...property,
                // output_value: property.value.map((item) => item.value),
              };
            }
            // else if (property.type === "fieldarray") {
            //   const InnerFormvalue = property.value.map(
            //     (parentvalue, parentIndex) => {
            //       const finalValue = {};
            //       parentvalue.value.map((itemValue, itemIndex) => {
            //         Object.assign(finalValue, {
            //           [property.inner_form[parentIndex][itemIndex]
            //             .name]: itemValue,
            //         });
            //       });
            //       return finalValue;
            //     }
            //   );

            //   return {
            //     ...property,
            //     output_value: InnerFormvalue,
            //   };
            // }
            else {
              return property;
            }
          }),
          is_active: Number(item?.is_active?.[0]?.value),
        };
      })
    : [];
}
// export function processWidgetData(widgets) {
//   return widgets && widgets.length > 0
//     ? widgets.map((item, widgetIndex) => {
//         return {
//           ...item,
//           priority: widgetIndex + 1,
//           properties: item.properties.map((property) => {
//             if (property.type === "image") {
//               return {
//                 ...property,
//                 value: property.value?.preview,
//               };
//             } else if (property.draggable_list) {
//               return {
//                 ...property,
//                 // value: property.value.map((itemValue, index) => {
//                 //   return {
//                 //     value: Number(itemValue.value),
//                 //     priority: index + 1,
//                 //   };
//                 // }),
//               };
//             } else {
//               return property;
//             }
//           }),
//           is_active: Number(item?.is_active?.[0]?.value),
//         };
//       })
//     : [];
// }

export function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export function isValidImage(e: React.ChangeEvent<HTMLInputElement>) {
  if (e.target.files && e.target.files[0]) {
    const image = e.target.files[0];
    const imageType = image.type;
    const validImageTypes = ["image/jpeg", "image/png", "image/jpg"];
    if (validImageTypes.includes(imageType)) {
      return true;
    }
  }
  return false;
}

export function isValidImageURL(url) {
  const imageTypes = ["jpg", "jpeg", "png"];
  const extension = url.split(".").pop();
  return imageTypes.includes(extension);
}

export function isValidVideoURL(url) {
  const videoTypes = ["mp4", "webm", "ogg"];
  const extension = url.split(".").pop();
  return videoTypes.includes(extension);
}

export const constructSlug = (slugs: string[]) => {
  let finalSlug = "";
  slugs.forEach((slug, index) => {
    if (index === 0) {
      finalSlug = slug;
    } else {
      finalSlug = `${finalSlug}/${slug}`;
    }
  });
  return finalSlug;
};

export const formatProductForOrder = (item: IProductCMSDetails) => {
  return {
    id: item.id,
    category: item.category.display_name,
    sub_category: item.sub_category.display_name,
    slug: constructSlug([
      item.category.meta_slug,
      item.sub_category.meta_slug,
      item.meta_slug,
    ]),
    title: item.product_name,
    jain_friendly: item.product.jain_friendly,
    product_code: item.product.product_code,
    images: item.product_medias
      .filter((item) => item.media_type === "full")
      .map((item: any) => ({
        alt: item.alt,
        img: item.media_file,
      }))
      .sort((a: any, b: any) => a.priority - b.priority),
    thumbnails: item.product_medias
      .filter((item) => item.media_type === "thumbnail")
      .map((item: any) => ({
        alt: item.alt,
        img: item.media_file,
      }))
      .sort((a: any, b: any) => a.priority - b.priority),
    uom: item?.product?.uom?.uom_code,
    selling_uom: item?.product?.selling_uom?.uom_code,
    net_weight: item?.product?.net_weight,
    weights: item.product_weights
      .map((weight: any) => ({
        id: weight.id,
        value: weight.weight,
        label: weight.weight,
        uom: item?.product?.uom?.uom_code,
      }))
      .sort((a, b) => a.value - b.value),
    rating: 4,
  };
};

export function renderTheme(status: string) {
  switch (status) {
    case GENERAL_CONSTANTS.CONFIRMED:
    case GENERAL_CONSTANTS.SUCCESS:
    case GENERAL_CONSTANTS.COMPLETED:
    case GENERAL_CONSTANTS.DELIVERED:
      return GENERAL_CONSTANTS.SUCCESS;

    case GENERAL_CONSTANTS.PENDING:
      return GENERAL_CONSTANTS.WARNING;

    case GENERAL_CONSTANTS.PLACED:
      return GENERAL_CONSTANTS.LIME;

    case GENERAL_CONSTANTS.PROCESSED:
      return GENERAL_CONSTANTS.LIGHT_BLUE;

    case GENERAL_CONSTANTS.OUT_FOR_DELIVERY:
      return GENERAL_CONSTANTS.INFO;

    case GENERAL_CONSTANTS.REFUNDED:
      return GENERAL_CONSTANTS.SECONDARY;

    default:
      return GENERAL_CONSTANTS.DANGER;
  }
}
