import { Badge, OffCanvas, Table, TableBody } from "components";
import React, { useEffect, useMemo, useState } from "react";
import {
  capitalizeFirstLetter,
  formatDateTime,
  GENERAL_CONSTANTS,
  POS_CONSTANTS,
  renderTheme,
  to2Decimal,
  useAppSelector,
} from "utils";

const PaymentDetailsOffCanvas = ({
  show,
  setShow,
}: {
  show: {
    state: boolean;
    id: string;
  };
  setShow: React.Dispatch<
    React.SetStateAction<{
      state: boolean;
      id: string;
    }>
  >;
}) => {
  const {
    order: { status: orderStatus, orderToEdit },
  } = useAppSelector((state) => state.ecommerce);

  const details = useMemo(() => {
    const transaction_details = orderToEdit?.payment_data?.find(
      (payment) => payment?.payment_gateway_id?.toString() === show.id
    )?.transaction_details;

    return transaction_details ? JSON.parse(transaction_details) : null;
  }, [show.state]);

  return (
    <OffCanvas
      title={`Payment ID: ${details?.id}`}
      show={show.state}
      onClickHandler={() => {
        setShow({
          state: false,
          id: "",
        });
      }}
    >
      {Object.keys(details || {})?.length > 0 ? (
        <Table tableClassNames="table-borderless">
          <TableBody>
            <tr>
              <th>Order ID</th>
              <td>{details?.order_id}</td>
            </tr>
            <tr>
              <th>Amount</th>
              <td>{`${POS_CONSTANTS.RUPEE_SIGN} ${to2Decimal(
                details?.amount / 100
              )}`}</td>
            </tr>
            <tr>
              <th>Status</th>
              <td>
                <Badge
                  badgeSize="md mt-1"
                  badgeType={renderTheme(
                    details.status === "captured"
                      ? GENERAL_CONSTANTS.SUCCESS
                      : GENERAL_CONSTANTS.FAILED
                  )}
                  badgeText={(details.status === "captured"
                    ? GENERAL_CONSTANTS.SUCCESS
                    : details.status
                  )?.toLocaleUpperCase()}
                />
              </td>
            </tr>
            <tr>
              <th>Payment Method</th>
              <td>{(details?.method || "").toLocaleUpperCase()}</td>
            </tr>
            <tr>
              <th>Created At</th>
              <td>
                {formatDateTime(new Date(details.created_at * 1000).toString())}
              </td>
            </tr>
            {/* <tr>
              <th>Description</th>
              <td>{details?.description}</td>
            </tr> */}
            <tr>
              <th>Customer</th>
              <td>
                <div className="d-flex flex-column gap-2">
                  <span>{details?.notes?.customer_name}</span>
                  <span className="text-muted">
                    {details?.notes?.customer_contact_number}
                  </span>
                </div>
              </td>
            </tr>
            {details.status === "captured" && (
              <tr>
                <th>Total Fee</th>
                <td>
                  <div className="d-flex flex-column gap-2">
                    <span>{`${POS_CONSTANTS.RUPEE_SIGN} ${to2Decimal(
                      details?.fee / 100
                    )}`}</span>
                    <span className="text-muted text-sm">
                      {` Razorpay Fee - ${POS_CONSTANTS.RUPEE_SIGN} ${
                        to2Decimal(details?.fee / 100) -
                        to2Decimal(details?.tax / 100)
                      }`}
                    </span>
                    <span className="text-muted text-sm">{`GST - ${
                      POS_CONSTANTS.RUPEE_SIGN
                    } ${to2Decimal(details?.tax / 100)}`}</span>
                  </div>
                </td>
              </tr>
            )}

            <tr>
              <th>Details</th>
              <td>
                <div className="d-flex flex-column gap-2">
                  {Object.keys(details?.notes || {})?.length > 0 &&
                    Object.keys(details?.notes || {}).map((key, i) => (
                      <div key={i} className="d-flex flex-column">
                        <span className="bold">
                          {key.replace(/_/g, " ").toUpperCase()}:
                        </span>
                        <span className="text-muted">{details.notes[key]}</span>
                      </div>
                    ))}
                </div>
              </td>
            </tr>
          </TableBody>
        </Table>
      ) : null}
    </OffCanvas>
  );
};

export { PaymentDetailsOffCanvas };
