import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  delMenu,
  delMenuItem,
  getCategory,
  getCMSCategories,
  getCMSProducts,
  getCMSSubCategories,
  getMenus,
  getMenuTypes,
  getPages,
  getProduct,
  getSubCategory,
  setMenu,
  updateMenu,
} from "apis/restApis";
import { FormikHelpers } from "formik";
import { NavigateFunction } from "react-router-dom";
import { setCommonStatus, updateValues } from "store/commonSlice";
import {
  MENU_CONSTANTS,
  STATUSES,
  TGetParams,
  TMenu,
  TMenuType,
  Toast,
  TOAST_CONSTANTS,
} from "utils";

const initialState = {
  menus: [] as TMenu[],
  menu: {} as TMenu,
  menu_types: [] as TMenuType[],
  status: STATUSES.IDLE as string,
  error: null,
  menu_item_options: [] as any[],
};

export const fetchMenus = createAsyncThunk(
  "menu/fetchMenus",
  async (
    { id, active = false, pageNo = 1, query = "" }: TGetParams,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await getMenus({ id, active, pageNo, query });
      dispatch(updateValues(response));
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const addNewMenu = createAsyncThunk(
  "menu/addNewMenu",
  async (
    {
      menuParams,
      actions,
      navigate,
    }: {
      menuParams: any;
      actions: FormikHelpers<any>;
      navigate: NavigateFunction;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await setMenu(menuParams);
      actions.resetForm();
      navigate(MENU_CONSTANTS.NAVIGATE_TO);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateExistingMenu = createAsyncThunk(
  "menu/updateExistingMenu",
  async (
    {
      id,
      menuParams,
      actions,
      navigate,
    }: {
      id: number;
      menuParams: any;
      actions: FormikHelpers<any>;
      navigate: NavigateFunction;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await updateMenu(id, menuParams);
      actions.resetForm();
      navigate(MENU_CONSTANTS.NAVIGATE_TO);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteMenu = createAsyncThunk(
  "menu/deleteMenu",
  async (id: number, { dispatch, rejectWithValue }) => {
    dispatch(setCommonStatus({ state: STATUSES.LOADING, type: "menu-delete" }));
    try {
      const response = await delMenu(id);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    } finally {
      dispatch(setCommonStatus({ state: STATUSES.IDLE, type: "menu-delete" }));
    }
  }
);

export const deleteMenuItem = createAsyncThunk(
  "menu/deleteMenuItem",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await delMenuItem(id);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchMenuItemOptions = createAsyncThunk(
  "menu/fetchMenuItemOptions",
  async (
    {
      pageNo = 1,
      query = "",
      type,
    }: { pageNo?: number; query?: string; type?: string },
    thunkAPI
  ) => {
    thunkAPI.dispatch(
      setCommonStatus({
        state: STATUSES.LOADING,
        type: "menu-item-options",
      })
    );
    try {
      let response = null;
      switch (type) {
        case "Product":
          response = await getCMSProducts({ active: false, pageNo, query });
          response = response.result.results.map((item) => {
            return {
              type: "product",
              value: item.id,
              label: item.product_name,
              slug: item.meta_slug,
            };
          });
          break;
        case "Category":
          response = await getCMSCategories({ active: true, pageNo, query });
          response = response.result.map((item) => {
            return {
              type: "category",
              value: item.id,
              label: item.product_category.category_name,
              slug: item.meta_slug,
            };
          });
          break;
        case "Sub Category":
          response = await getCMSSubCategories({ active: true, pageNo, query });
          response = response.result.map((item) => {
            return {
              type: "sub_category",
              value: item.id,
              label: item.product_sub_category.sub_category_name,
              slug: item.meta_slug,
            };
          });
          break;

        case "Internal Page":
          response = await getPages({ active: true, query });
          response = response.result.map((item) => {
            return {
              type: "page",
              value: item.id,
              label: item.page_name,
              slug: item.meta_slug,
            };
          });
          break;

        case "Mega Menu":
          response = await getMenus({ active: true, query });
          response = response.result.map((item) => {
            return {
              type: "menu",
              value: item.id,
              label: item.menu_title,
            };
          });
          break;

        default:
          response = [];
          break;
      }
      thunkAPI.dispatch(setMenuItemOptions(response));
    } catch (error) {
      thunkAPI.dispatch(
        setCommonStatus({
          state: STATUSES.ERROR,
          type: "menu-item-options",
        })
      );
    } finally {
      thunkAPI.dispatch(
        setCommonStatus({
          state: STATUSES.IDLE,
          type: "menu-item-options",
        })
      );
    }
  }
);

export const fetchMenuTypes = createAsyncThunk(
  "menu/fetchMenuTypes",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response: any = await getMenuTypes();
      return response.result.results;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    setMenuToEdit: (state, action) => {
      state.menu = action.payload;
    },
    setMenuItemOptions: (state, action) => {
      state.menu_item_options = action.payload;
    },

    resetMenuState: (state) => {
      state = initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchMenus.fulfilled, (state, action: any) => {
        if (action.meta.arg.id) {
          state.menu = action.payload.result;
        } else if (action.meta.arg.active) {
          state.menus = action.payload.result;
        } else {
          state.menus = action.payload.result.results;
        }
      })
      .addCase(fetchMenuTypes.fulfilled, (state, action: any) => {
        state.menu_types = action.payload;
      })
      .addCase(addNewMenu.fulfilled, (state, action: any) => {
        state.menus.unshift(action.payload.result);
        state.menus = state.menus.slice(0, 10);
      })
      .addCase(updateExistingMenu.fulfilled, (state, action: any) => {
        // Update the existing menu in the menus array
        const index = state.menus.findIndex(
          (menu) => menu.id === action.payload.result.id
        );
        if (index !== -1) {
          state.menus[index] = action.payload.result;
        }
      })
      .addCase(deleteMenu.fulfilled, (state, action: any) => {
        // update the deleted menu from the menus array
        const index = state.menus.findIndex(
          (menu) => menu.id === action.meta.arg
        );
        if (index !== -1) {
          state.menus[index] = action.payload.result;
        }
      })
      .addCase(deleteMenuItem.fulfilled, (_, action: any) => {
        Toast(action.payload.message, TOAST_CONSTANTS.SUCCESS);
      })
      .addMatcher(
        (action) => action.type.endsWith("/fulfilled"),
        (state) => {
          state.status = STATUSES.IDLE;
        }
      )
      .addMatcher(
        isAnyOf(
          addNewMenu.fulfilled,
          updateExistingMenu.fulfilled,
          deleteMenu.fulfilled
        ),
        (_, action: any) => {
          Toast(action.payload.message, TOAST_CONSTANTS.SUCCESS);
        }
      )
      .addMatcher(
        isAnyOf(
          fetchMenus.pending,
          fetchMenuTypes.pending,
          addNewMenu.pending,
          updateExistingMenu.pending
        ),
        (state) => {
          state.status = STATUSES.LOADING;
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/rejected"),
        (state, action) => {
          state.status = STATUSES.ERROR;
          state.error = action.payload;
          Toast(action.payload, TOAST_CONSTANTS.ERROR);
        }
      );
  },
});

export const {
  setMenuToEdit,
  setMenuItemOptions,
  resetMenuState,
} = menuSlice.actions;

export default menuSlice.reducer;
