import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import { getRole, setRole, updateRole, delRole } from "apis/restApis";
import { FormikHelpers } from "formik";
import { NavigateFunction } from "react-router-dom";
import { getUserPermissions } from "store/authSlice";
import { updateValues } from "store/commonSlice";
import { RootState } from "store/store";
import { STATUSES, Toast, TOAST_CONSTANTS, TRole } from "utils";

interface IntialPermission {
  module: number;
  module_name: string;
  perm_view: boolean;
  perm_add: boolean;
  perm_edit: boolean;
  perm_delete: boolean;
  perm_authorize: boolean;
  bulk_row?: string;
}

const initialState = {
  roles: [] as TRole[],
  roleToEdit: {} as TRole,
  intitalPermissions: [] as IntialPermission[],
  status: STATUSES.IDLE as string,
  error: null,
};

export const fetchRoles = createAsyncThunk(
  "role/fetchRoles",
  async (
    {
      id,
      active = false,
      pageNo = 1,
      query = "",
    }: { id?: number; active?: boolean; pageNo?: number; query?: string },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const endpoint = id
        ? `/api/permissions/user/roles/${id}/`
        : `/api/permissions/user/roles/${
            active
              ? "active/"
              : `?${query ? `query=${query}&` : ""}${
                  pageNo ? "page=" + pageNo : ""
                }`
          }`;
      const response = await getRole(endpoint);
      dispatch(updateValues(response));
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const addNewRole = createAsyncThunk(
  "role/addNewRole",
  async (
    {
      roleParams,
      actions,
      navigate,
    }: {
      roleParams: any;
      actions: FormikHelpers<any>;
      navigate: NavigateFunction;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await setRole(roleParams);
      actions.resetForm();
      navigate(-1);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateExistingRole = createAsyncThunk(
  "role/updateExistingRole",
  async (
    {
      id,
      roleParams,
      actions,
      navigate,
    }: {
      id: number;
      roleParams: any;
      actions: FormikHelpers<any>;
      navigate: NavigateFunction;
    },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const {
        root: {
          auth: { logged_in_user },
        },
      } = getState() as RootState;

      const currentRole = logged_in_user?.role?.id;

      const response: any = await updateRole(id, roleParams);

      if (Number(id) === currentRole) {
        dispatch(getUserPermissions(response.result.permissions));
      }

      actions.resetForm();
      navigate(-1);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteRole = createAsyncThunk(
  "role/deleteRole",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await delRole(id);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const roleSlice = createSlice({
  name: "role",
  initialState,
  reducers: {
    setRoleToEdit: (state, action) => {
      state.roleToEdit = action.payload;
    },
    setIntialPermissions: (state, action) => {
      state.intitalPermissions = action.payload;
    },
    resetRoleState: (state) => {
      state.roles = [] as TRole[];
      state.roleToEdit = {} as TRole;
      state.intitalPermissions = [] as IntialPermission[];
      state.status = STATUSES.IDLE as string;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRoles.fulfilled, (state, action: any) => {
        if (action.meta.arg.id) {
          state.roleToEdit = action.payload.result;
        } else if (action.meta.arg.active) {
          state.roles = action.payload.result;
        } else {
          state.roles = action.payload.result.results;
        }
      })
      .addCase(addNewRole.fulfilled, (state, action: any) => {
        // Add the new role to the beginning of the roles array
        state.roles.unshift(action.payload);
      })
      .addCase(updateExistingRole.fulfilled, (state, action: any) => {
        // Update the existing role in the roles array
        const index = state.roles.findIndex(
          (role) => role.id === action.payload.result.id
        );
        if (index !== -1) {
          state.roles[index] = action.payload.result;
        }
      })
      .addCase(deleteRole.fulfilled, (state, action: any) => {
        // update the deleted role from the roles array
        const index = state.roles.findIndex(
          (role) => role.id === action.meta.arg
        );
        if (index !== -1) {
          state.roles[index] = action.payload.result;
        }
      })
      .addMatcher(
        (action) => action.type.endsWith("/fulfilled"),
        (state) => {
          state.status = STATUSES.IDLE;
        }
      )
      .addMatcher(
        isAnyOf(
          addNewRole.fulfilled,
          updateExistingRole.fulfilled,
          deleteRole.fulfilled
        ),
        (state, action: any) => {
          Toast(action.payload.message, TOAST_CONSTANTS.SUCCESS);
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/pending"),
        (state) => {
          state.status = STATUSES.LOADING;
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/rejected"),
        (state, action) => {
          state.status = STATUSES.ERROR;
          state.error = action.payload;
          Toast(action.payload, TOAST_CONSTANTS.ERROR);
        }
      );
  },
});

export const {
  setRoleToEdit,
  setIntialPermissions,
  resetRoleState,
} = roleSlice.actions;

export default roleSlice.reducer;

// import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// import {
//   STATUSES,
//   ROLE_CONSTANTS,
//   Toast,
//   TGetParams,
//   TRole,
//   TGET,
// } from "utils";
// import { delRole, getRole, setRole, updateRole } from "apis/restApis";

// import { updateValues } from "store/commonSlice";
// import { NavigateFunction } from "react-router-dom";
// import { FormikHelpers } from "formik";

// interface IntialPermission {
//   module: number;
//   module_name: string;
//   perm_view: boolean;
//   perm_add: boolean;
//   perm_edit: boolean;
//   perm_delete: boolean;
//   perm_authorize: boolean;
//   bulk_row?: string;
// }

// const initialState = {
//   roles: [] as TRole[],
//   roleToEdit: {} as TRole,
//   intitalPermissions: [] as IntialPermission[],
//   status: STATUSES.IDLE as string,
//   error: null,
// };

// const roleSlice = createSlice({
//   name: "role",
//   initialState,
//   reducers: {
//     getAllRole: (state, action: PayloadAction<TRole[]>) => {
//       state.roles = action.payload;
//     },
//     addRole: (state, action: PayloadAction<TRole>) => {
//       state.roles.unshift(action.payload);
//     },
//     removeRole: (state, action: PayloadAction<number>) => {
//       state.roles = state.roles.filter((role) => role.id !== action.payload);
//     },
//     editRole: (state, action: PayloadAction<TRole>) => {
//       const { id } = action.payload;
//       const index = state.roles.findIndex((role) => role.id === id);
//       if (index !== -1) {
//         state.roles[index] = action.payload;
//       }
//     },

//     setRoleToEdit: (state, action: PayloadAction<TRole>) => {
//       state.roleToEdit = action.payload;
//     },

//     setIntialPermissions: (
//       state,
//       action: PayloadAction<IntialPermission[]>
//     ) => {
//       state.intitalPermissions = action.payload;
//     },

//     setRoleStatus(state, action) {
//       state.status = action.payload;
//     },
//     setRoleError(state, action) {
//       state.error = action.payload;
//     },
//     resetRoleState: (state) => {
//       state.roles = [] as TRole[];
//       state.roleToEdit = {} as TRole;
//       state.intitalPermissions = [] as IntialPermission[];
//       state.status = STATUSES.IDLE as string;
//       state.error = null;
//     },
//   },
// });

// export const {
//   getAllRole,
//   addRole,
//   removeRole,
//   editRole,

//   setRoleToEdit,

//   setIntialPermissions,

//   setRoleStatus,
//   setRoleError,
//   resetRoleState,
// } = roleSlice.actions;

// export default roleSlice.reducer;

// //CRUD OPERATIONS

// export function getRoles({ id, active, pageNo = 1, query = "" }: TGetParams) {
//   return async function getRolesThunk(dispatch, getState) {
//     dispatch(setRoleStatus(STATUSES.LOADING));
//     const endpoint = id
//       ? `/api/permissions/user/roles/${id}/`
//       : `/api/permissions/user/roles/${
//           active
//             ? "active/"
//             : `?${query ? `query=${query}&` : ""}${
//                 pageNo ? "page=" + pageNo : ""
//               }`
//         }`;
//     try {
//       const response: any = await getRole(endpoint);
//       dispatch(setRoleStatus(STATUSES.IDLE));
//       dispatch(updateValues(response));
//       if (id) {
//         dispatch(setRoleToEdit(response.result));
//       } else if (active) {
//         dispatch(getAllRole(response.result));
//       } else {
//         dispatch(getAllRole(response.result.results));
//       }
//     } catch (error) {
//       dispatch(setRoleStatus(STATUSES.ERROR));
//       dispatch(setRoleError(error.message));
//     } finally {
//       dispatch(setRoleStatus(STATUSES.IDLE));
//     }
//   };
// }

// export function addNewRole(
//   roleParams: Object,
//   actions: FormikHelpers<any>,
//   navigate: NavigateFunction
// ) {
//   return async function addNewRoleThunk(dispatch, getState) {
//     dispatch(setRoleStatus(STATUSES.LOADING));
//     setRole(roleParams)
//       .then((response: any) => {
//         // dispatch(getRoles());
//         actions.resetForm();
//         actions.setSubmitting(false);
//         navigate(ROLE_CONSTANTS.ROLE_NAVIGATE);
//         Toast(`${response.message}`, "success");
//       })
//       .catch((error) => {
//         dispatch(setRoleStatus(STATUSES.ERROR));
//         dispatch(setRoleError(error.message));
//         // Toast(`${error.message}`, "error");
//       })
//       .finally(() => {
//         dispatch(setRoleStatus(STATUSES.IDLE));
//       });
//   };
// }

// export function updateExistingRole(
//   id: number,
//   roleParams: Object,
//   actions: FormikHelpers<any>,
//   navigate: NavigateFunction
// ) {
//   return async function updateExistingRoleThunk(dispatch, getState) {
//     dispatch(setRoleStatus(STATUSES.LOADING));
//     updateRole(id, roleParams)
//       .then((response: any) => {
//         // dispatch(getRoles());
//         actions.resetForm();
//         actions.setSubmitting(false);
//         navigate(ROLE_CONSTANTS.ROLE_NAVIGATE);
//         Toast(`${response.message}`, "success");
//         // dispatch(editRole(response.result));
//       })
//       .catch((error) => {
//         dispatch(setRoleStatus(STATUSES.ERROR));
//         dispatch(setRoleError(error.message));
//         // Toast(`${error.message}`, "error");
//       })
//       .finally(() => {
//         dispatch(setRoleStatus(STATUSES.IDLE));
//       });
//   };
// }

// export function deleteRole(id: number) {
//   return async function deleteRoleThunk(dispatch, getState) {
//     dispatch(setRoleStatus(STATUSES.LOADING));
//     delRole(id)
//       .then((response: any) => {
//         dispatch(editRole(response.result));
//         Toast(`${response.message}`, "success");
//       })
//       .catch((error) => {
//         dispatch(setRoleStatus(STATUSES.ERROR));
//         dispatch(setRoleError(error.message));
//       })
//       .finally(() => {
//         dispatch(setRoleStatus(STATUSES.IDLE));
//       });
//   };
// }
