import { createSlice } from "@reduxjs/toolkit";

import { AppDispatch, RootState } from "store/store";
import {
  getCustomerOrderDetails,
  getIndividualCustomer,
} from "store/ManageCustomers/customerSlice";
import {
  ACCESS_CONSTANTS,
  ICart,
  ICartItem,
  IDiscount,
  IGetBillItem,
  IGetOrder,
  IOrderProduct,
  IProductState,
  POSAdvance,
  STATUSES,
  calculateAllValues,
  calculateProductsInProductGroup,
  calculateTax,
  to2Decimal,
  updateValuesForItem,
} from "utils";
import { getIndividualProduct } from "store/ManageProducts/productSlice";
import { DeleteHoldBill } from "./holdBillSlice";
import { NavigateFunction } from "react-router-dom";
import {
  getBranches,
  setSelectedStore,
} from "store/ManageBranches/branchSlice";

const initialState = {
  cart: {
    cartItems: [] as ICartItem[],
    discount: {
      discount_type: "flat",
      discount_value: 0,
    } as IDiscount,
    net_amount: 0,
    round_off: 0,
    additional_charges: 0,
    credit_note: {
      creditNote: [{ refund_amount: 0, value: 0, label: "" }] as any[],
      applyCredit: 0,
    },
    is_ecommerce_store: false,
    remarks: "",
  } as ICart,
  cancel_order_id: 0,
  advance: false as boolean,
  return: false as boolean,
  returnOrder: {} as IGetOrder,
  currentReturn: {} as IOrderProduct,

  status: STATUSES.IDLE as string,
  error: null,
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setCart: (state, action) => {
      state.cart = action.payload;
    },
    addProductToCart: (state, action) => {
      state.cart.cartItems.unshift(action.payload);
    },
    updateProductInCart: (
      state,
      action: {
        payload: {
          index: number;
          applyTo: ICartItem;
          updateOrder: boolean;
        };
      }
    ) => {
      state.cart.cartItems[action.payload.index] = action.payload.applyTo;
      if (action.payload.updateOrder) {
        state.cart.cartItems.unshift(
          state.cart.cartItems.splice(action.payload.index, 1)[0]
        );
      }
    },
    removeProductFromCart: (state, action) => {
      state.cart.cartItems.splice(action.payload, 1);
    },

    setCartRemarks: (state, action) => {
      state.cart.remarks = action.payload;
    },

    setCartItemDiscount: (state, action) => {
      state.cart.cartItems[action.payload.index].discount =
        action.payload.discount;
    },

    setCartDiscount: (state, action) => {
      state.cart.discount = action.payload;
    },
    clearCartDiscount: (state) => {
      state.cart.discount = {
        discount_type: "flat",
        discount_value: 0,
      } as IDiscount;
    },

    handleProductQuantity: (state, action) => {
      state.cart.cartItems[action.payload.index].quantity +=
        action.payload.quantity;
    },

    setRoundOff: (state, action) => {
      state.cart.round_off = action.payload;
    },
    setAdditionalCharges: (state, action) => {
      state.cart.additional_charges = action.payload;
    },

    resetCart: (state) => {
      state.cart = {
        cartItems: [] as ICartItem[],
        discount: {
          discount_type: "flat",
          discount_value: 0,
        } as IDiscount,
        net_amount: 0,
        round_off: 0,
        additional_charges: 0,
        credit_note: {
          creditNote: [{ refund_amount: 0, value: 0, label: "" }],
          applyCredit: 0,
        },
        remarks: "",
      };
    },

    setCreditNote: (state, action) => {
      state.cart.credit_note = action.payload;
    },

    setAdvance: (state, action) => {
      state.advance = action.payload;
    },

    setReturn: (state, action) => {
      state.return = action.payload;
    },
    setReturnOrder: (state, action) => {
      state.returnOrder = action.payload;
    },
    setCurrentReturn: (state, action) => {
      state.currentReturn = action.payload;
    },

    setCancelOrderId: (state, action) => {
      state.cancel_order_id = action.payload;
    },

    setCartStatus: (state, action) => {
      state.status = action.payload;
    },
    setCartError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const {
  setCart,
  addProductToCart,
  updateProductInCart,
  removeProductFromCart,

  setCartRemarks,
  setCartItemDiscount,
  setCartDiscount,
  clearCartDiscount,
  setRoundOff,
  setAdditionalCharges,

  handleProductQuantity,

  resetCart,

  setCreditNote,

  setAdvance,

  setReturn,
  setReturnOrder,
  setCurrentReturn,

  setCancelOrderId,

  setCartStatus,
  setCartError,
} = cartSlice.actions;

export default cartSlice.reducer;

export function UpdateWholeCart(bill: IGetBillItem) {
  return async function UpdateWholeCartThunk(dispatch: AppDispatch) {
    dispatch(setCartStatus(STATUSES.LOADING));
    try {
      const total_payable = to2Decimal(
        bill.products.reduce((acc: number, item: any) => {
          return acc + to2Decimal(item.price * item.quantity);
        }, 0)
      );
      const initial_net_payable = bill.products.reduce((acc, item) => {
        const item_amount = to2Decimal(item.price);

        const discount_amount =
          item.discount_type === "percentage"
            ? to2Decimal(item_amount * (item.discount_value / 100))
            : item.discount_value;

        const amount_with_discount = to2Decimal(
          (item_amount - discount_amount) * item.quantity
        );

        return acc + to2Decimal(amount_with_discount);
      }, 0);

      const bill_discount =
        bill.discount_value > 0
          ? bill.discount_type === "percentage"
            ? to2Decimal(initial_net_payable * (bill.discount_value / 100))
            : bill.discount_value
          : 0;

      const cartToSet = {
        cartItems: bill.products.map((item: any) => {
          const { discount_amount, payable_amount } = updateValuesForItem(
            item.price,
            item.quantity,
            {
              discount_applicable: "product",
              discount_type: item.discount_type ? item.discount_type : "flat",
              discount_value: item.discount_value ? item.discount_value : 0,
              discount_code: item.discount_code
                ? item.discount_code
                : "OnSpot Discount",
            }
          );
          const tax = {
            id: item?.product?.tax?.id,
            tax_rate: Number(
              item?.product?.tax?.find((item) => item.tax.tax_type !== 2).tax
                .tax_rate
            ),
            cess:
              Number(
                item?.product?.tax?.find((item) => item.tax.tax_type === 2)?.tax
                  ?.tax_rate
              ) || 0,
          };

          const { gst, cess, bill_discount_per_item } = calculateTax(
            payable_amount,
            tax.tax_rate ? tax.tax_rate : 0,
            tax.cess ? tax.cess : 0,
            initial_net_payable,
            bill_discount
          );

          const cartItem = {
            id: item.product.id,
            batch_id: item.batch.id,
            store_id: bill.store,
            print_name: item.product.print_name,
            product_code: item.product.product_code,
            bill_discount_per_item: bill_discount_per_item,
            quantity: item.quantity,
            mrp: item.price,
            selling_price: item.price,
            batch: item.batch.batch_name,
            discount: {
              discount_applicable: "product",
              discount_type: item.discount_type ? item.discount_type : "flat",
              discount_value: item.discount_value ? item.discount_value : 0,
              discount_code: item.discount_code
                ? item.discount_code
                : "OnSpot Discount",
            } as IDiscount,
            total_amount: item.total_amount,
            payable_amount: item.payable_amount,
            tax_applied: to2Decimal(gst + cess),
            discount_applied: discount_amount + bill_discount_per_item,
            tax: tax,
            cess: cess,
            cgst: to2Decimal(gst / 2),
            sgst: to2Decimal(gst / 2),
            igst: 0,
            uom: item.product.uom
              ? {
                  id: item.product.uom.id,
                  uom_code: item.product.uom.uom_code,
                }
              : null,
            hsn: item.product.hsn_code
              ? {
                  id: item.product.hsn_code.id,
                  hsn_code: item.product.hsn_code.hsn_code,
                }
              : null,
            productGroup: item.product_group
              ? {
                  id: item.product_group.id,
                  product_group_code: item.product_group.hamper_code,
                  product_group_name: item.product_group.print_hamper_name,
                  selling_price: 0,
                  products_quantity: to2Decimal(
                    calculateProductsInProductGroup(
                      bill.products,
                      item.product_group.id
                    )
                  ),
                }
              : {
                  id: 0,
                  product_group_code: "",
                  product_group_name: "",
                  selling_price: 0,
                  products_quantity: 0,
                },
            count: item.count,
            meddler:
              item.product.print_name
                .toLocaleLowerCase()
                .includes("new product") ||
              item?.product?.barcode_type?.id === 5 ||
              (item?.product_group && item?.product_group?.id > 0),
          };

          return cartItem;
        }),
        remarks: bill.remarks,
        discount: {
          discount_type: bill.discount_type,
          discount_value: bill.discount_value,
          discount_code: bill.discount_code,
          discount_applicable: "bill",
        } as IDiscount,
        tax: bill.total_tax,
        net_amount: bill.payable_amount,
        round_off: 0,
        additional_charges: 0,
        is_ecommerce_store: false,
        credit_note: {} as {
          creditNote: [{ value: number; label: string }];
          applyCredit: any;
        },
      };

      dispatch(setCart(cartToSet));
      dispatch(getIndividualCustomer(Number(bill.customer.id)));
      dispatch(setCartStatus(STATUSES.IDLE));
      dispatch(UpdateTaxApplied(bill_discount, cartToSet));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(DeleteHoldBill(bill.id));
    }
  };
}

export function LoadCartForCancelOrder(
  order: IGetOrder,
  navigate: NavigateFunction
) {
  return async function LoadCartForCancelOrderThunk(
    dispatch: AppDispatch,
    getState: any
  ) {
    try {
      dispatch(resetCart());
      dispatch(getBranches({ active: true }));
      dispatch(setSelectedStore(Number(order.store)));
      dispatch(setCancelOrderId(order.id));
      const initial_net_payable = order.products.reduce((acc, item) => {
        const item_amount = to2Decimal(item.unit_price);

        const discount_amount =
          item.discount_type === "percentage"
            ? to2Decimal(item_amount * (item.discount_value / 100))
            : item.discount_value;

        const amount_with_discount = to2Decimal(
          (item_amount - discount_amount) * item.quantity
        );

        return acc + to2Decimal(amount_with_discount);
      }, 0);

      const bill_discount =
        order.discount_value > 0
          ? order.discount_type === "percentage"
            ? to2Decimal(initial_net_payable * (order.discount_value / 100))
            : order.discount_value
          : 0;

      const cartToSet = {
        cartItems: order.products.map((item: IOrderProduct) => {
          const { discount_amount, payable_amount } = updateValuesForItem(
            item.unit_price,
            item.quantity,
            {
              discount_applicable: "product",
              discount_type: item.discount_type ? item.discount_type : "flat",
              discount_value: item.discount_value ? item.discount_value : 0,
              discount_code: item.discount_code
                ? item.discount_code
                : "OnSpot Discount",
            }
          );

          const tax = {
            id: item?.product?.tax?.[0]?.id,
            tax_rate: Number(
              item?.product?.tax?.find((item) => item.tax.tax_type !== 2).tax
                .tax_rate
            ),
            cess:
              Number(
                item?.product?.tax?.find((item) => item.tax.tax_type === 2)?.tax
                  ?.tax_rate
              ) || 0,
          };

          const { gst, cess, bill_discount_per_item } = calculateTax(
            payable_amount,
            tax.tax_rate ? tax.tax_rate : 0,
            tax.cess ? tax.cess : 0,
            initial_net_payable,
            bill_discount
          );

          const cartItem = {
            id: item.product.id,
            batch_id: item.batch.id,
            store_id: order.store,
            print_name: item.product.print_name,
            product_code: item.product.product_code,
            quantity: item.quantity,
            mrp: item.unit_price,
            selling_price: item.unit_price,
            batch: item.batch.batch_name,
            discount: {
              discount_applicable: "product",
              discount_type: item.discount_type ? item.discount_type : "flat",
              discount_value: item.discount_value ? item.discount_value : 0,
              discount_code: item.discount_code
                ? item.discount_code
                : "OnSpot Discount",
            } as IDiscount,
            total_amount: item.total_amount,
            payable_amount: item.payable_amount,
            tax_applied: to2Decimal(gst + cess),
            discount_applied: to2Decimal(
              discount_amount +
                to2Decimal(bill_discount_per_item / item.quantity)
            ),
            bill_discount_per_item: to2Decimal(bill_discount_per_item),
            tax: tax,
            cess: cess,
            cgst: to2Decimal(gst / 2),
            sgst: to2Decimal(gst / 2),
            igst: 0,
            uom: item.product.uom
              ? {
                  id: item.product.uom.id,
                  uom_code: item.product.uom.uom_code,
                }
              : null,
            hsn: item.product.hsn_code
              ? {
                  id: item.product.hsn_code.id,
                  hsn_code: item.product.hsn_code.hsn_code,
                }
              : null,
            productGroup: item.product_group
              ? {
                  id: item.product_group.id,
                  product_group_code: item.product_group.hamper_code,
                  product_group_name: item.product_group.print_hamper_name,
                  selling_price: 0,
                  products_quantity: to2Decimal(
                    calculateProductsInProductGroup(
                      order.products,
                      item.product_group.id
                    )
                  ),
                }
              : {
                  id: 0,
                  product_group_code: "",
                  product_group_name: "",
                  selling_price: 0,
                  products_quantity: 0,
                },
            count: item.count,
            meddler:
              item.product.print_name
                .toLocaleLowerCase()
                .includes("new product") ||
              item?.product?.barcode_type?.id === 5 ||
              (item?.product_group && item?.product_group?.id > 0),
          };

          return cartItem;
        }),
        remarks: order.remarks,
        discount: {
          discount_type: order.discount_type,
          discount_value: order.discount_value,
          discount_code: order.discount_code,
          discount_applicable: "bill",
        } as IDiscount,
        tax: order.total_tax,
        net_amount: order.payable_amount,
        round_off: order.roundoff,
        additional_charges: 0,
        credit_note: {} as {
          creditNote: [{ value: number; label: string }];
          applyCredit: any;
        },
      };

      dispatch(setCart(cartToSet));
      dispatch(getIndividualCustomer(Number(order.customer.id)));
      dispatch(setCartStatus(STATUSES.IDLE));
    } catch (error) {
      console.log(error);
    } finally {
      navigate("/admin/sales/pos");
    }
  };
}

export function ResetWholeCart() {
  return async function ResetWholeCartThunk(
    dispatch: AppDispatch,
    getState: any
  ) {
    const {
      customer: { customerToEdit: customer_details, cachedCustomer },
    }: RootState["root"] = getState().root;
    dispatch(resetCart());
    dispatch(
      setCreditNote({
        creditNote: [{ refund_amount: 0, value: 0, label: "" }],
        applyCredit: 0,
      })
    );
    dispatch(setRoundOff(0));
    dispatch(
      setCartDiscount({
        discount_applicable: "bill",
        discount_type: "flat",
        discount_value: 0,
        discount_code: "OnSpot Discount",
      })
    );
    dispatch(setCartRemarks(""));
    dispatch(setCancelOrderId(0));
    dispatch(setReturnOrder({} as IGetOrder));
    dispatch(setReturn(false));
    dispatch(setAdvance(false));
    if (
      Object.keys(customer_details).length > 0 &&
      customer_details?.id !== ACCESS_CONSTANTS.CASH_SALES_ID
    ) {
      dispatch(getIndividualCustomer(55));
    }
  };
}

export function UpdateProductQuantity(
  item: ICartItem,
  quantity: number,
  meddler: boolean = false,
  updateOrder: boolean = false,
  currentIndex?: number
) {
  return async function UpdateProductQuantityThunk(
    dispatch: AppDispatch,
    getState: any
  ) {
    dispatch(setCartStatus(STATUSES.LOADING));

    const {
      cart: { cart },
    }: RootState["root"] = getState().root;

    const index: number = currentIndex;

    const new_quantity = Number(
      (cart.cartItems[index].quantity + quantity).toFixed(3)
    );

    if (!quantity && quantity !== 0) {
      const {
        discount_amount,
        total_amount,
        payable_amount,
      } = updateValuesForItem(item.selling_price, 0, item.discount);

      const { gst, cess } = calculateTax(
        payable_amount,
        item.tax.tax_rate ? item.tax.tax_rate : 0,
        item.tax.cess ? item.tax.cess : 0
      );

      const updateProduct = {
        ...item,
        discount_applied: discount_amount,
        cess: cess,
        cgst: to2Decimal(gst / 2),
        sgst: to2Decimal(gst / 2),
        igst: 0,
        tax_applied: to2Decimal(gst + cess),
        quantity: 0,
        total_amount: total_amount,
        payable_amount: payable_amount,
        count: 0,
      };

      let new_cart = {
        ...cart,
        cartItems: cart.cartItems.map((cartItem, prodIndex) =>
          prodIndex === index ? updateProduct : cartItem
        ),
      };

      const { final_amount_to_pay, bill_discount } = calculateAllValues(
        new_cart
      );

      if (
        final_amount_to_pay - cart.credit_note.applyCredit - bill_discount <
        0
      ) {
        dispatch(
          setCreditNote({
            creditNote: [{ refund_amount: 0, value: 0, label: "" }],
            applyCredit: 0,
          })
        );
        dispatch(clearCartDiscount());
        new_cart = {
          ...cart,
          discount: {
            discount_type: "flat",
            discount_value: 0,
          } as IDiscount,
          credit_note: {
            creditNote: [{ refund_amount: 0, value: 0, label: "" }],
            applyCredit: 0,
          },
        };
      }

      dispatch(UpdateTaxApplied(bill_discount, new_cart));

      dispatch(setCartStatus(STATUSES.IDLE));
      return;
    }

    let temp = [...cart.cartItems];
    temp[index] = {
      ...temp[index],
      quantity: new_quantity,
    };

    const current_cart = { ...cart };
    current_cart.cartItems = temp;

    const {
      initial_net_payable,
      bill_discount,
      total_discount,
    } = calculateAllValues(current_cart);

    const {
      discount_amount,
      total_amount,
      payable_amount,
    } = updateValuesForItem(item.selling_price, new_quantity, item.discount);

    const { gst, cess, bill_discount_per_item } = calculateTax(
      payable_amount,
      item.tax.tax_rate ? item.tax.tax_rate : 0,
      item.tax.cess ? item.tax.cess : 0,
      initial_net_payable,
      bill_discount
    );

    if (new_quantity < 0) {
      const new_cart = {
        ...cart,
        cartItems: cart.cartItems.filter((_, prodIndex) => prodIndex !== index),
      };

      const { final_amount_to_pay } = calculateAllValues(new_cart);
      const credit_note = cart.credit_note.applyCredit;

      if (to2Decimal(final_amount_to_pay - credit_note) < 0) {
        dispatch(clearCartDiscount());
      }

      dispatch(RemoveProduct(item, index));

      dispatch(setCartStatus(STATUSES.IDLE));
    } else {
      const updateProduct = {
        ...item,
        discount_applied: to2Decimal(
          discount_amount + to2Decimal(bill_discount_per_item / new_quantity)
        ),
        cess: cess,
        cgst: to2Decimal(gst / 2),
        sgst: to2Decimal(gst / 2),
        igst: 0,
        tax_applied: to2Decimal(gst + cess),
        quantity: new_quantity,
        total_amount: total_amount,
        payable_amount: payable_amount,
        count: meddler
          ? item.count
          : item.meddler
          ? item.count + 1
          : item.count + quantity,
      };

      let new_cart = {
        ...cart,
        cartItems: cart.cartItems.map((cartItem, prodIndex) =>
          prodIndex === index ? updateProduct : cartItem
        ),
      };

      const {
        final_amount_to_pay: new_final_amount_to_pay,
        bill_discount: new_bill_discount,
      } = calculateAllValues(new_cart);

      if (
        new_final_amount_to_pay -
          cart.credit_note.applyCredit -
          new_bill_discount <
        0
      ) {
        dispatch(
          setCreditNote({
            creditNote: [{ refund_amount: 0, value: 0, label: "" }],
            applyCredit: 0,
          })
        );
        dispatch(clearCartDiscount());
        new_cart = {
          ...cart,
          discount: {
            discount_type: "flat",
            discount_value: 0,
          } as IDiscount,
          credit_note: {
            creditNote: [{ refund_amount: 0, value: 0, label: "" }],
            applyCredit: 0,
          },
        };
      }
      dispatch(
        UpdateTaxApplied(
          new_bill_discount,
          updateOrder
            ? {
                ...new_cart,
                cartItems: [
                  new_cart.cartItems[index],
                  ...new_cart.cartItems.filter(
                    (item, filterIndex) => filterIndex !== index
                  ),
                ],
              }
            : new_cart
        )
      );

      dispatch(setCartStatus(STATUSES.IDLE));
    }
  };
}

export function SetAdvanceOrder(creditNote: POSAdvance) {
  return async function SetAdvanceOrderThunk(
    dispatch: AppDispatch,
    getState: any
  ) {
    dispatch(setAdvance(true));
    dispatch(setCartStatus(STATUSES.LOADING));
    dispatch(
      setCreditNote({
        creditNote: [
          {
            ...creditNote,
            refund_amount: creditNote.refund_amount,
            value: creditNote.id,
            label: creditNote.credit_note_code,
          },
        ],
        applyCredit: 0,
      })
    );

    for (let i = 0; i < creditNote.products.length; i++) {
      dispatch(
        getIndividualProduct({
          id: creditNote.products[i].product.id,
          advanceOrder: {
            quantity: creditNote.products[i].quantity,
            batch_id: creditNote.products[i].batch.id,
            product_group: creditNote.products[i].product_group
              ? {
                  id: creditNote.products[i].product_group.id,
                  product_group_code:
                    creditNote.products[i].product_group.hamper_code,
                  product_group_name:
                    creditNote.products[i].product_group.print_hamper_name,
                  selling_price: 0,
                  products_quantity: calculateProductsInProductGroup(
                    creditNote.products,
                    creditNote.products[i].product_group.id
                  ),
                }
              : {
                  id: 0,
                  product_group_code: "",
                  product_group_name: "",
                  selling_price: 0,
                  products_quantity: 0,
                },
          },
        })
      );
    }
    dispatch(getIndividualCustomer(creditNote.customer.id));
    dispatch(getCustomerOrderDetails(creditNote.customer.id));
    dispatch(setCartStatus(STATUSES.IDLE));
  };
}

export function AddPOSProduct(item: any, batchIndex?: number) {
  return async function AddPOSProductThunk(
    dispatch: AppDispatch,
    getState: any
  ) {
    dispatch(setCartStatus(STATUSES.LOADING));
    const {
      cart: { cart },
    }: RootState["root"] = getState().root;

    const index = cart.cartItems.findIndex((cartItem: any) => {
      if (
        cartItem.batch_id ===
        (batchIndex !== undefined
          ? item.batch[batchIndex].batch_id
          : item.batch_id)
      ) {
        if (item.product_group && item.product_group.id > 0) {
          return cartItem.productGroup.id === item.product_group.id;
        } else if (cartItem.productGroup && cartItem.productGroup.id === 0) {
          return true;
        }
      } else {
        return false;
      }
    });

    const { bill_discount } = calculateAllValues(cart);

    if (index === -1) {
      const {
        discount_amount,
        total_amount,
        payable_amount,
      } = updateValuesForItem(
        batchIndex !== undefined
          ? item.batch[batchIndex].selling_price
          : item.selling_price,
        item.quantity ? item.quantity : 1,
        {
          discount_type: "flat",
          discount_value: 0,
        } as IDiscount
      );

      const { gst, cess } = calculateTax(
        payable_amount,
        item.tax.tax_rate ? item.tax.tax_rate : 0,
        item.tax.cess ? item.tax.cess : 0
      );

      const newItem = {
        id: item.id,
        print_name: item.print_name,
        product_code: item.product_code,
        batch_id:
          batchIndex !== undefined
            ? item.batch[batchIndex].batch_id
            : item.batch_id,
        store_id: item.store_id,
        batch:
          batchIndex !== undefined ? item.batch[batchIndex].batch : item.batch,
        quantity: item.quantity ? item.quantity : 1,
        mrp:
          batchIndex !== undefined
            ? item.batch[batchIndex].selling_price
            : item.selling_price,
        selling_price:
          batchIndex !== undefined
            ? item.batch[batchIndex].selling_price
            : item.selling_price,
        total_amount: total_amount,
        cess: cess,
        cgst: to2Decimal(gst / 2),
        sgst: to2Decimal(gst / 2),
        igst: 0,
        tax_applied: to2Decimal(gst + cess),
        discount_applied: discount_amount,
        payable_amount: payable_amount,
        bill_discount_per_item: 0,
        discount: {
          discount_type: "flat",
          discount_value: 0,
        } as IDiscount,
        tax: {
          id: item?.tax?.id,
          tax_rate: item.tax.tax_rate,
          cess: item.tax.cess,
        },
        uom: item.selling_uom
          ? {
              id: item.selling_uom.id,
              uom_code: item.selling_uom.uom_code,
            }
          : null,
        hsn: item.hsn
          ? {
              id: item.hsn.id,
              hsn_code: item.hsn.hsn_code,
            }
          : null,
        count: item.count,
        meddler: item.meddler,
        productGroup: item.product_group,
      };

      dispatch(setCartStatus(STATUSES.IDLE));
      dispatch(addProductToCart(newItem));
      dispatch(
        UpdateTaxApplied(bill_discount, {
          ...cart,
          cartItems: [newItem, ...cart.cartItems],
        })
      );
    } else {
      dispatch(
        UpdateProductQuantity(
          cart.cartItems[index],
          item.quantity ? item.quantity : 1,
          false,
          true,
          index
        )
      );
      dispatch(setCartStatus(STATUSES.IDLE));
      return;
    }
  };
}

export function AddProduct(
  item: IProductState,
  batch: {
    batch_id: number;
    store_id: number;
    batch_name: string;
    batch_mrp: number;
    batch_selling_price: number;
  }
) {
  return async function AddProductThunk(dispatch: AppDispatch, getState: any) {
    dispatch(setCartStatus(STATUSES.LOADING));
    try {
      const {
        cart: { cart },
        branch: { selectedStore },
      }: RootState["root"] = getState().root;

      let index: number;

      if (
        !(
          item.print_name.toLocaleLowerCase().includes("new product") &&
          item.productGroup.id === 0
        )
      ) {
        index = cart.cartItems.findIndex((cartItem: any) => {
          if (cartItem.batch_id === batch.batch_id) {
            if (item.productGroup && item.productGroup.id > 0) {
              return cartItem.productGroup.id === item.productGroup.id;
            } else if (
              cartItem.productGroup &&
              cartItem.productGroup.id === 0
            ) {
              return true;
            }
          } else {
            return false;
          }
        });
      }

      const { bill_discount } = calculateAllValues(cart);

      if (
        index === -1 ||
        (item.print_name.toLocaleLowerCase().includes("new product") &&
          item.productGroup.id === 0)
      ) {
        const {
          discount_amount,
          total_amount,
          payable_amount,
        } = updateValuesForItem(
          batch.batch_selling_price,
          item.quantity ? item.quantity : 1,
          {
            discount_type: "flat",
            discount_value: 0,
          } as IDiscount
        );

        const { gst, cess } = calculateTax(
          payable_amount,
          item.tax.tax_rate ? item.tax.tax_rate : 0,
          item.tax.cess ? item.tax.cess : 0
        );

        const newItem: ICartItem = {
          id: item.id,
          print_name: item.print_name,
          product_code: item.product_code,
          batch_id: batch.batch_id,
          store_id: item.store_id,
          batch: batch.batch_name,
          quantity: item.advanceOrder
            ? item.advanceOrder.quantity
            : item.quantity
            ? item.quantity
            : 1,
          mrp: batch.batch_selling_price,
          selling_price: batch.batch_selling_price,
          total_amount: total_amount,
          cess: cess,
          cgst: to2Decimal(gst / 2),
          sgst: to2Decimal(gst / 2),
          igst: 0,
          tax_applied: to2Decimal(gst + cess),
          discount_applied: discount_amount,
          payable_amount: payable_amount,
          discount: {
            discount_type: "flat",
            discount_value: 0,
          } as IDiscount,
          tax: {
            id: item.tax.id,
            tax_rate: item.tax.tax_rate ? item.tax.tax_rate : 0,
            cess: item.tax.cess ? item.tax.cess : 0,
          },
          uom: {
            id: item.uom.id,
            uom_code: item.uom.uom_code,
          },
          hsn: {
            id: item.hsn.id,
            hsn_code: item.hsn.hsn_code,
          },
          count: item.count,
          meddler:
            item.print_name.toLocaleLowerCase().includes("new product") &&
            item.productGroup.id === 0
              ? true
              : item.meddler,
          productGroup: item.productGroup,
        };

        dispatch(setCartStatus(STATUSES.IDLE));
        dispatch(addProductToCart(newItem));
        dispatch(
          UpdateTaxApplied(bill_discount, {
            ...cart,
            cartItems: [newItem, ...cart.cartItems],
          })
        );
      } else {
        dispatch(
          UpdateProductQuantity(
            cart.cartItems[index],
            item.quantity ? item.quantity : 1,
            false,
            true,
            index
          )
        );
        dispatch(setCartStatus(STATUSES.IDLE));
        return;
      }
    } catch (error) {
      console.log(error);
    }
  };
}

export function RemoveProduct(item: ICartItem, currentIndex?: number) {
  return async function RemoveProductThunk(
    dispatch: AppDispatch,
    getState: any
  ) {
    dispatch(setCartStatus(STATUSES.LOADING));

    const {
      cart: { cart },
    }: RootState["root"] = getState().root;

    try {
      const index: number = currentIndex;

      const new_cart = {
        ...cart,
        cartItems: cart.cartItems.filter((_, prodIndex) => prodIndex !== index),
      };
      dispatch(removeProductFromCart(index));

      const { final_amount_to_pay, bill_discount } = calculateAllValues(
        new_cart
      );
      const credit_note = cart.credit_note.applyCredit;

      if (to2Decimal(final_amount_to_pay - credit_note - bill_discount) < 0) {
        dispatch(clearCartDiscount());
        dispatch(
          UpdateTaxApplied(0, {
            ...new_cart,
            discount: {
              discount_type: "flat",
              discount_value: 0,
            } as IDiscount,
            credit_note: {
              creditNote: [{ refund_amount: 0, value: 0, label: "" }],
              applyCredit: 0,
            },
          })
        );
      } else {
        dispatch(UpdateTaxApplied(bill_discount, new_cart));
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setCartStatus(STATUSES.IDLE));
    }
  };
}

export function AddDiscount(
  applyTo: ICartItem | false,
  discount: IDiscount,
  currentIndex?: number,
  setModal?: React.Dispatch<React.SetStateAction<boolean>>
) {
  return async function AddDiscountThunk(dispatch: any, getState: any) {
    dispatch(setCartStatus(STATUSES.LOADING));
    const {
      cart: { cart },
    }: RootState["root"] = getState().root;

    const { bill_discount, initial_net_payable } = calculateAllValues(cart);

    if (applyTo) {
      const index: number = currentIndex;

      const {
        discount_amount,
        total_amount,
        payable_amount,
      } = updateValuesForItem(
        applyTo.selling_price,
        applyTo.quantity,
        discount
      );

      const { gst, cess } = calculateTax(
        to2Decimal(payable_amount - applyTo.bill_discount_per_item),
        applyTo.tax.tax_rate ? applyTo.tax.tax_rate : 0,
        applyTo.tax.cess ? applyTo.tax.cess : 0
      );

      const updatedProduct = {
        ...applyTo,
        discount_applied: to2Decimal(
          discount_amount + applyTo.bill_discount_per_item
        ),
        cess: cess,
        cgst: to2Decimal(gst / 2),
        sgst: to2Decimal(gst / 2),
        igst: 0,
        tax_applied: to2Decimal(gst + cess),
        discount: discount,
        total_amount: total_amount,
        payable_amount: to2Decimal(
          payable_amount - applyTo.bill_discount_per_item
        ),
      };

      dispatch(
        UpdateTaxApplied(bill_discount, {
          ...cart,
          cartItems: cart.cartItems.map((cartItem, prodIndex) =>
            prodIndex === index ? updatedProduct : cartItem
          ),
        })
      );
      setModal && setModal(false);
      dispatch(setCartStatus(STATUSES.IDLE));
    } else {
      if (discount) {
        dispatch(setCartDiscount(discount));
        setModal && setModal(false);
        dispatch(setCartStatus(STATUSES.IDLE));
      } else {
        dispatch(
          setCartDiscount({
            discount_type: "flat",
            discount_value: 0,
          } as IDiscount)
        );
        dispatch(setCartStatus(STATUSES.IDLE));
      }
    }
  };
}

export function RemoveDiscount(applyto: ICartItem, currentIndex?: number) {
  return async function RemoveDiscountThunk(dispatch: AppDispatch, getState) {
    dispatch(setCartStatus(STATUSES.LOADING));
    const {
      cart: { cart },
    }: RootState["root"] = getState().root;

    const { bill_discount, initial_net_payable } = calculateAllValues(cart);

    const index: number = currentIndex;

    // const index = cart.cartItems.findIndex((cartItem: any) => {
    //   if (cartItem.batch_id === applyto.batch_id) {
    //     if (applyto.productGroup && applyto.productGroup.id > 0) {
    //       return cartItem.productGroup.id === applyto.productGroup.id;
    //     } else if (cartItem.productGroup && cartItem.productGroup.id === 0) {
    //       return true;
    //     }
    //   } else {
    //     return false;
    //   }
    // });
    // const index = cart.findIndex((cartItem: any) => cartItem.id === applyto.id);
    const currentItem: ICartItem = cart.cartItems[index];

    const {
      discount_amount,
      total_amount,
      payable_amount,
    } = updateValuesForItem(currentItem.selling_price, currentItem.quantity, {
      discount_type: "flat",
      discount_value: 0,
    } as IDiscount);

    const {
      gst,
      cess,
      bill_discount_per_item,
      new_payable_amount,
    } = calculateTax(
      payable_amount - currentItem.bill_discount_per_item,
      currentItem.tax.tax_rate ? currentItem.tax.tax_rate : 0,
      currentItem.tax.cess ? currentItem.tax.cess : 0,
      initial_net_payable,
      bill_discount
    );

    const updatedProduct = {
      ...applyto,
      discount_applied: to2Decimal(discount_amount + bill_discount_per_item),
      cess: cess,
      cgst: to2Decimal(gst / 2),
      sgst: to2Decimal(gst / 2),
      igst: 0,
      tax_applied: to2Decimal(gst + cess),
      discount: {
        discount_type: "flat",
        discount_value: 0,
      } as IDiscount,
      total_amount: total_amount,
      payable_amount: to2Decimal(new_payable_amount),
    };

    dispatch(
      updateProductInCart({
        index,
        applyTo: updatedProduct,
        updateOrder: false,
      })
    );
    dispatch(setCartStatus(STATUSES.IDLE));
  };
}

export function UpdateTaxApplied(bill_discount?: number, currentCart?: ICart) {
  return async function UpdateTaxAppliedThunk(
    dispatch: AppDispatch,
    getState: any
  ) {
    const {
      cart: { cart },
    }: RootState["root"] = getState().root;

    const cart_to_use = currentCart ? currentCart : cart;

    const {
      initial_net_payable,
      bill_discount: current_cart_discount,
    } = calculateAllValues(cart_to_use);

    for (let i in cart_to_use.cartItems) {
      const {
        discount_amount,
        total_amount,
        payable_amount,
      } = updateValuesForItem(
        cart_to_use.cartItems[i].selling_price,
        cart_to_use.cartItems[i].quantity,
        cart_to_use.cartItems[i].discount
      );

      const {
        gst,
        cess,
        bill_discount_per_item,
        new_payable_amount,
      } = calculateTax(
        payable_amount,
        cart_to_use.cartItems[i].tax.tax_rate,
        cart_to_use.cartItems[i].tax.cess,
        initial_net_payable,
        currentCart ? current_cart_discount : bill_discount
      );

      const updatedProduct = {
        ...cart_to_use.cartItems[i],
        discount_applied:
          discount_amount +
          bill_discount_per_item / cart_to_use.cartItems[i].quantity,
        cess: cess,
        cgst: to2Decimal(gst / 2),
        sgst: to2Decimal(gst / 2),
        igst: 0,
        tax_applied: to2Decimal(gst + cess),
        total_amount: total_amount,
        payable_amount: to2Decimal(new_payable_amount),
        bill_discount_per_item: to2Decimal(bill_discount_per_item),
      };

      dispatch(
        updateProductInCart({
          index: Number(i),
          applyTo: updatedProduct,
          updateOrder: false,
        })
      );
    }
  };
}
