import {
  faAdd,
  faCheck,
  faScaleUnbalanced,
  faBan,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CustomInput,
  EmptyFormMessage,
  FontIcon,
  IconButton,
  Table,
  TableBody,
  TableHeader,
} from "components";
import { ErrorMessage, FieldArray, FormikProps } from "formik";
import React, { useRef } from "react";
import { deleteWeightVariation } from "store/Ecommerce";
import { BUTTON_CONSTANTS, FORM_CONSTANTS, useAppDispatch } from "utils";

const WeightForm = ({
  props,
  setConfirmModal,
  setCurrentItemToDelete,
}: {
  props: FormikProps<any>;
  setConfirmModal: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentItemToDelete: React.Dispatch<
    React.SetStateAction<{
      id: number;
      removeIndex: () => void;
      type: string;
    }>
  >;
}) => {
  const dispatch = useAppDispatch();
  const weightArrayHelpers = useRef<any>();

  const weight_variation_col = ["Weight", "Actions"];

  return (
    <Card>
      <CardHeader>
        <div className="row align-items-center">
          <div className="col-4">
            <h3 className="card-title">Product Weights</h3>
          </div>
          <div className="col-8 text-end">
            <IconButton
              icon={faAdd}
              btnClassNames="btn btn-outline-primary"
              btnText="Add Weight"
              onClickHandler={() => {
                weightArrayHelpers.current?.push({
                  weight: 0,
                  new: true,
                });
              }}
              type={BUTTON_CONSTANTS.BUTTON}
            />
          </div>
        </div>
      </CardHeader>
      <CardBody>
        <Table>
          <TableHeader cols={weight_variation_col} />
          <TableBody>
            <FieldArray
              name="weight_variations"
              render={(arrayHelpers) => {
                weightArrayHelpers.current = arrayHelpers;
                return props.values?.weight_variations &&
                  props.values?.weight_variations?.length > 0 ? (
                  props.values?.weight_variations?.map((weight, index) => (
                    <tr key={index} className="text-sm">
                      <td>
                        <CustomInput
                          isDisabled={weight?.id}
                          prependText={props.values?.uom?.[0]?.code}
                          name={`weight_variations[${index}].weight`}
                          type={FORM_CONSTANTS.NUMBER}
                          placeholder="Enter weight"
                        />
                        <ErrorMessage
                          name={`weight_variations[${index}].weight`}
                          component={FORM_CONSTANTS.ERROR_PARENT}
                          className={FORM_CONSTANTS.ERROR}
                        />
                      </td>
                      <td className="align-middle" align={"center"}>
                        <Button
                          text={
                            <FontIcon
                              icon={
                                weight.is_active
                                  ? faBan
                                  : weight?.id
                                  ? faCheck
                                  : faTrash
                              }
                            />
                          }
                          isDisabled={
                            weight.id &&
                            weight.is_active &&
                            !props.values.weight_variations.some(
                              (item) =>
                                item.id !== undefined && item.is_active === 1
                            )
                          }
                          type={BUTTON_CONSTANTS.BUTTON}
                          btnClassNames={`btn btn-${
                            !weight.is_active && weight?.id
                              ? "success"
                              : "danger"
                          } btn-sm table-button`}
                          onClickHandler={() => {
                            if (weight?.id) {
                              if (weight?.is_active) {
                                setConfirmModal(true);
                                setCurrentItemToDelete({
                                  id: weight.id,
                                  removeIndex: () => {
                                    props.setFieldValue(
                                      `weight_variations[${index}]`,
                                      {
                                        ...weight,
                                        is_active: 0,
                                      }
                                    );
                                    //   arrayHelpers.remove(index);
                                  },
                                  type: "weight",
                                });
                              } else {
                                dispatch(
                                  deleteWeightVariation({
                                    id: weight.id,
                                    is_active: true,
                                  })
                                );
                                props.setFieldValue(
                                  `weight_variations[${index}]`,
                                  {
                                    ...weight,
                                    is_active: 1,
                                  }
                                );
                              }
                            } else {
                              arrayHelpers.remove(index);
                            }
                          }}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={weight_variation_col.length}>
                      <EmptyFormMessage
                        disabled={props.values?.product?.length === 0}
                        disabledMessage={
                          "Please choose a product before adding weight variations"
                        }
                        emptyMessage={"Add weight variations"}
                        icon={faScaleUnbalanced}
                        iconSize={"lg"}
                        actions={() => {
                          weightArrayHelpers.current?.push({
                            weight: 0,
                            new: true,
                          });
                        }}
                      />
                    </td>
                  </tr>
                );
              }}
            />
            <ErrorMessage
              name="weight_variations"
              component={FORM_CONSTANTS.ERROR_PARENT}
              className={FORM_CONSTANTS.ERROR}
            />
          </TableBody>
        </Table>
      </CardBody>
    </Card>
  );
};

export { WeightForm };
