import { Button, CustomInput, MultiSelect, OffCanvas } from "components";
import { ErrorMessage, Form, Formik, FormikProps } from "formik";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useParams, useSearchParams } from "react-router-dom";
import {
  fetchMenuItemOptions,
  fetchPages,
  setMenuItemOptions,
} from "store/Ecommerce";
import {
  BUTTON_CONSTANTS,
  debounce,
  FORM_CONSTANTS,
  GENERAL_CONSTANTS,
  MENU_CONSTANTS,
  MenuItemSchema,
  STATUSES,
  TMenu,
  TMenuType,
  useAppDispatch,
  useAppSelector,
  usePagination,
} from "utils";

interface IProps {
  index: number;
  childIndex: number;
  parent: any;
  setParent: React.Dispatch<React.SetStateAction<any>>;
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  props: FormikProps<TMenu>;
  arrayHelpers: any;
  sorting?: boolean;
}

const MenuItemForm = ({
  index,
  childIndex,
  parent,
  setParent,
  show,
  setShow,
  props,
  arrayHelpers,
  sorting,
}: IProps) => {
  const {
    root: {
      common: { current_page, total_items, total_pages, page_size, status },
    },
    ecommerce: {
      menu: { menu_item_options, menu_types },
    },
  } = useAppSelector((state) => state);

  const dispatch = useAppDispatch();

  const { id: menuId } = useParams();

  const SubPropsref = useRef<FormikProps<any>>();

  const [query, setQuery] = useState("");

  const [menutype, setMenuType] = useState("");

  const [searchParams, setSearchParams] = useSearchParams();

  const inititalData = parent
    ? props.values.menu_items[index]?.children[childIndex]
    : props.values.menu_items?.[index];

  const initialValues = {
    ...inititalData,
    menu_type: parent
      ? props.values.menu_items[index]?.children[childIndex]?.menu_type || []
      : props.values.menu_items?.[index]?.menu_type || [],
    menu_item: parent
      ? props.values.menu_items[index]?.children[childIndex]?.menu_item || []
      : props.values.menu_items?.[index]?.menu_item || [],
    menu_item_title: parent
      ? props.values.menu_items[index]?.children[childIndex]?.menu_item_title ||
        ""
      : props.values.menu_items?.[index]?.menu_item_title || "",
    url: parent
      ? props.values.menu_items[index]?.children[childIndex]?.url || ""
      : props.values.menu_items?.[index]?.url || "",
    parent: parent ? parent.menu_item_title : null,
    is_active: parent
      ? props.values.menu_items[index]?.children[childIndex]?.is_active || [
          {
            value: 1,
            label: GENERAL_CONSTANTS.ACTIVE,
          },
        ]
      : props.values.menu_items?.[index]?.is_active || [
          {
            value: 1,
            label: GENERAL_CONSTANTS.ACTIVE,
          },
        ],
    children: props.values.menu_items?.[index]?.children || [],
  };

  const menuTypeOptions = useMemo(() => {
    return menu_types.map((item: TMenuType) => ({
      value: item.id,
      label: item.type,
    }));
  }, [menu_types?.length]);

  const update_page = useCallback(
    (pageNo, query, type = menutype) => {
      dispatch(
        fetchMenuItemOptions({
          pageNo,
          query,
          type,
        })
      );
    },
    [menutype]
  );

  const searchValues = (values) => {
    setQuery(values);
  };

  const optimizeSearch = useCallback(debounce(searchValues), []);

  const { resetState } = usePagination({
    update_page,
    current_page,
    total_items,
    total_pages,
    page_size,
    query,
  });

  useEffect(() => {
    return () => {
      resetState();
    };
  }, [dispatch]);

  return (
    <OffCanvas
      title={`${
        props.values.menu_items[index]?.children[childIndex]?.menu_item_title ||
        props.values.menu_items?.[index]?.menu_item_title
          ? "Edit "
          : "Add "
      } ${parent ? MENU_CONSTANTS.SUB_MENU_ITEM : MENU_CONSTANTS.MENU_ITEM}`}
      show={show}
      onClickHandler={() => {
        setSearchParams({});
        setParent(null);
        setShow(false);
      }}
    >
      <Formik
        enableReinitialize={!sorting}
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          if (parent) {
            if (childIndex === -1) {
              values.parent = parent.title;
              props.setFieldValue(`${MENU_CONSTANTS.MENU_ITEMS}[${index}]`, {
                ...parent,
                children: [...parent.children, values],
              });
            } else {
              props.setFieldValue(
                `${MENU_CONSTANTS.MENU_ITEMS}[${index}].children[${childIndex}]`,
                values
              );
            }
          } else {
            if (index === -1) {
              arrayHelpers.push(values);
            } else {
              props.setFieldValue(
                `${MENU_CONSTANTS.MENU_ITEMS}[${index}]`,
                values
              );
            }
          }
          setSearchParams({});
          setParent(null);
          setShow(false);
          actions.resetForm();
        }}
        validationSchema={MenuItemSchema}
      >
        {(subProps) => {
          SubPropsref.current = subProps;
          return (
            <Form>
              <div className="row">
                <div className="col-12">
                  {parent && (
                    <div className="row">
                      <CustomInput
                        type={FORM_CONSTANTS.TEXT}
                        name={MENU_CONSTANTS.PARENT}
                        label={MENU_CONSTANTS.PARENT_LABEL}
                        isDisabled={true}
                      />
                      <ErrorMessage
                        name={MENU_CONSTANTS.PARENT}
                        component={FORM_CONSTANTS.ERROR_PARENT}
                        className={FORM_CONSTANTS.ERROR}
                      />
                    </div>
                  )}
                  <div className="row">
                    <MultiSelect
                      select={false}
                      name={MENU_CONSTANTS.MENU_TYPE}
                      label={`${parent ? "Sub" : ""} Menu Type`}
                      value={subProps.values.menu_type}
                      options={
                        parent
                          ? menuTypeOptions.filter((item) => item.value !== 6)
                          : menuTypeOptions
                      }
                      onChangeHandler={(e, actions) => {
                        subProps.setFieldValue(MENU_CONSTANTS.ITEM, []);
                        subProps.setFieldValue(MENU_CONSTANTS.URL, "");
                        subProps.setFieldValue(
                          MENU_CONSTANTS.MENU_ITEM_TITLE,
                          ""
                        );
                        setQuery("");
                        dispatch(setMenuItemOptions([]));

                        if (actions.action === "clear") {
                          subProps.setFieldValue(MENU_CONSTANTS.MENU_TYPE, []);
                          dispatch(setMenuItemOptions([]));
                          setMenuType("");
                          update_page(1, "");
                          return;
                        }

                        if (e.type === "page") {
                          dispatch(fetchPages({ id: e.value }));
                        }
                        subProps.setFieldValue(MENU_CONSTANTS.MENU_TYPE, [e]);
                        setMenuType(e.label);
                        update_page(1, "", e.label);
                      }}
                    />
                    <ErrorMessage
                      name={MENU_CONSTANTS.MENU_TYPE}
                      component={FORM_CONSTANTS.ERROR_PARENT}
                      className={FORM_CONSTANTS.ERROR}
                    />
                  </div>
                  {subProps.values?.menu_type?.[0]?.value !== 5 && (
                    <div className="row">
                      <MultiSelect
                        select={false}
                        name={MENU_CONSTANTS.ITEM}
                        label={
                          subProps.values.menu_type?.[0]?.label ||
                          MENU_CONSTANTS.MENU_ITEM
                        }
                        disabled={
                          subProps.values.menu_type.length === 0 ||
                          subProps.values.menu_type[0] === null
                        }
                        isLoading={
                          status.state === STATUSES.LOADING &&
                          status.type ===
                            MENU_CONSTANTS.MENU_ITEM_OPTION_LOADING_STATE
                        }
                        value={subProps.values.menu_item}
                        options={
                          menuId &&
                          menu_item_options.some((item) => item.type === "menu")
                            ? menu_item_options.filter(
                                (item) => item.value !== Number(menuId)
                              )
                            : menu_item_options
                        }
                        onFocusHandler={() => {
                          if (subProps.values.menu_type.length !== 0) {
                            update_page(
                              1,
                              "",
                              subProps.values.menu_type[0].label
                            );
                          }
                        }}
                        onInputChangeHandler={(value) => {
                          value.length > 0 && optimizeSearch(value);
                        }}
                        onChangeHandler={(e, actions) => {
                          if (actions.action === "clear") {
                            subProps.setFieldValue(MENU_CONSTANTS.ITEM, []);
                            subProps.setFieldValue(MENU_CONSTANTS.URL, "");
                            subProps.setFieldValue(
                              MENU_CONSTANTS.MENU_ITEM_TITLE,
                              ""
                            );
                            return;
                          }
                          subProps.setFieldValue(MENU_CONSTANTS.ITEM, [e]);

                          if (
                            [
                              "product",
                              "category",
                              "sub_category",
                              "page",
                            ].includes(e.type)
                          ) {
                            subProps.setFieldValue(
                              MENU_CONSTANTS.URL,
                              `${parent ? `${parent?.url}/` : ""}${e.slug}`
                            );
                            subProps.setFieldValue(
                              MENU_CONSTANTS.MENU_ITEM_TITLE,
                              e.label
                            );
                            subProps.setFieldValue(
                              MENU_CONSTANTS.IS_MEGA_MENU,
                              [{ value: 0, label: "No" }]
                            );
                          } else if (e.type === "menu") {
                            subProps.setFieldValue(
                              MENU_CONSTANTS.IS_MEGA_MENU,
                              [{ value: 1, label: "Yes" }]
                            );
                            subProps.setFieldValue(MENU_CONSTANTS.URL, "#");
                          }
                        }}
                      />
                      <ErrorMessage
                        name={MENU_CONSTANTS.ITEM}
                        component={FORM_CONSTANTS.ERROR_PARENT}
                        className={FORM_CONSTANTS.ERROR}
                      />
                    </div>
                  )}
                  <div className="row">
                    <CustomInput
                      type={FORM_CONSTANTS.TEXT}
                      name={MENU_CONSTANTS.MENU_ITEM_TITLE}
                      label={MENU_CONSTANTS.MENU_ITEM_TITLE_LABEL}
                      placeholder={MENU_CONSTANTS.MENU_ITEM_TITLE_PLACEHOLDER}
                      value={subProps.values.menu_item_title}
                    />
                    <ErrorMessage
                      name={MENU_CONSTANTS.MENU_ITEM_TITLE}
                      component={FORM_CONSTANTS.ERROR_PARENT}
                      className={FORM_CONSTANTS.ERROR}
                    />
                  </div>
                  <div className="row">
                    <CustomInput
                      type={FORM_CONSTANTS.TEXT}
                      isDisabled={subProps.values?.menu_type?.[0]?.value !== 5}
                      name={MENU_CONSTANTS.URL}
                      label={MENU_CONSTANTS.URL_LABEL}
                      placeholder={MENU_CONSTANTS.URL_PLACEHOLDER}
                      value={subProps.values.url}
                    />
                    <ErrorMessage
                      name={MENU_CONSTANTS.URL}
                      component={FORM_CONSTANTS.ERROR_PARENT}
                      className={FORM_CONSTANTS.ERROR}
                    />
                  </div>
                  <div className="row">
                    <MultiSelect
                      select={false}
                      name={MENU_CONSTANTS.STATUS}
                      label={MENU_CONSTANTS.STATUS_LABEL}
                      options={GENERAL_CONSTANTS.STATUS_OPTIONS}
                    />
                    <ErrorMessage
                      name={MENU_CONSTANTS.STATUS}
                      component={FORM_CONSTANTS.ERROR_PARENT}
                      className={FORM_CONSTANTS.ERROR}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <Button
                    type={BUTTON_CONSTANTS.BUTTON}
                    text={FORM_CONSTANTS.SUBMIT}
                    btnClassNames={"btn btn-primary align-self-center w-25"}
                    onClickHandler={() => {
                      subProps.submitForm();
                    }}
                  />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </OffCanvas>
  );
};

export { MenuItemForm };
