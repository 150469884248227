import {
  faAdd,
  faImages,
  faPenToSquare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  ConfirmModal,
  Container,
  ContentHeader,
  CustomInput,
  EmptyFormMessage,
  FontIcon,
  IconButton,
  Loader,
  MultiSelect,
  SelectStatus,
  SEODetailsform,
  Summernote,
} from "components";
import { ScrollToFieldError } from "components/ScollToFieldError";
import { ErrorMessage, FieldArray, Form, Formik, FormikProps } from "formik";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  addNewCMSProduct,
  clearProductOptions,
  deleteMediaMapping,
  deleteWeightVariation,
  fetchAvaryaEcommerceProducts,
  fetchCMSCategories,
  fetchCMSSubCategories,
  fetchFormValues,
  resetCategoryState,
  resetSubCategoryState,
  setCMSCategoryToEdit,
  setCMSSubCategoryToEdit,
  updateExistingCMSProduct,
} from "store/Ecommerce";
import { getIndividualProduct } from "store/ManageProducts/productSlice";
import {
  BUTTON_CONSTANTS,
  FORM_CONSTANTS,
  ECOMMERCE_PRODUCT_CONSTANTS,
  EcommerceProductSchema,
  GENERAL_CONSTANTS,
  useAppDispatch,
  useAppSelector,
  STATUSES,
  debounce,
  usePagination,
  ROLE_CONSTANTS,
  SEO_CONSTANTS,
} from "utils";
import { MediaOffCanvas } from "./MediaOffCanvas";
import { ReactSortable } from "react-sortablejs";
import { WeightForm } from "./WeightForm";
import { ERPProductDetails } from "./ERPProductDetails";

const ProductsForm = () => {
  const {
    root: {
      common: {
        current_page,
        total_items,
        total_pages,
        page_size,
        status: commonStatus,
      },
    },
    ecommerce: {
      category: {
        category: selectedCMSCategory,
        categories: CMSCategories,
        status: CMSCategoryStatus,
      },
      subCategory: {
        sub_category: selectedCMSSubCategory,
        sub_categories: CMSSubCategories,
        status: CMSSubCategoryStatus,
      },
      product: {
        product_options,
        product: productToEdit,
        status: product_status,
      },
    },
  } = useAppSelector((state) => state);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const { id } = useParams();

  const [mediaOffCanvas, setMediaOffCanvas] = useState({
    state: false,
    type: "",
  });
  const [mediaIndex, setMediaIndex] = useState(-1);
  const mediaArrayHelpers = useRef<any>();

  const [query, setQuery] = useState("");

  const [currentItemToDelete, setCurrentItemToDelete] = useState<{
    id: number;
    removeIndex: () => void;
    type: string;
  }>({ id: 0, removeIndex: () => {}, type: "" });

  const [confirmModal, setConfirmModal] = useState(false);

  const [sorting, setSorting] = useState(false);

  const PropsRef = useRef<FormikProps<any>>();

  const initialValues = {
    product: [],
    product_category: [],
    product_sub_category: [],

    is_active: [
      {
        value: 1,
        label: GENERAL_CONSTANTS.ACTIVE,
      },
    ],

    product_name: "",
    expiration_days: "",

    net_weight: "",
    uom: [],

    category: [],
    sub_category: [],

    tax: [],
    hsn_code: [],

    product_code: "",
    selling_uom: [],

    shelf_life: "",
    description: "",
    disclaimer: "",

    media: [],

    weight_variations: [],

    slugHelper: "",
    meta_title: "",
    meta_description: "",
    meta_slug: "",
    meta_keywords: "",
  };

  const getCategoryOptions = useMemo(() => {
    return CMSCategories.map((category: any) => ({
      id: category.id,
      label: category.display_name,
      value: category.product_category?.id,
    }));
  }, [CMSCategories.length]);

  const getSubCategoryOptions = useMemo(() => {
    return CMSSubCategories.map((sub_category: any) => ({
      id: sub_category.id,
      label: sub_category.display_name,
      value: sub_category.product_sub_category?.id,
    }));
  }, [
    CMSSubCategories.length,
    PropsRef.current?.values?.product_category?.[0]?.value,
  ]);

  const getProductOptions = useMemo(() => {
    return product_options.map((product: any) => ({
      value: product.id,
      label: product.print_name,
      brand: product?.brand?.id,
    }));
  }, [product_options.length]);

  const update_page = (pageNo) => {
    if (query) {
      dispatch(
        fetchAvaryaEcommerceProducts({
          pageNo: pageNo,
          query: query,
          extra: `category[]=${PropsRef.current?.values?.product_category?.[0]?.value}&sub_category[]=${PropsRef.current?.values?.product_sub_category?.[0]?.value}&cms=1`,
        })
      );
    }
  };

  const searchValues = (values) => {
    setQuery(values);
  };

  const optimizeSearch = useCallback(debounce(searchValues), []);

  const onCategoryChange = (value, actions) => {
    PropsRef.current?.setFieldValue("product_sub_category", []);
    PropsRef.current?.setFieldValue("product_name", "");
    PropsRef.current?.setFieldValue("product", []);
    PropsRef.current?.setFieldValue("meta_slug", "");

    dispatch(clearProductOptions());

    if (actions.action === "clear") {
      PropsRef.current?.setFieldValue("product_category", []);
      dispatch(setCMSCategoryToEdit(null));
      return;
    }

    const selected_option = Array.isArray ? value : [value];

    PropsRef.current?.setFieldValue("product_category", [selected_option]);

    dispatch(
      fetchCMSCategories({
        id: selected_option?.id,
      })
    );

    dispatch(
      fetchCMSSubCategories({
        active: true,
        extra: `category[]=${selected_option?.value}`,
      })
    );
  };

  const onSubCategoryChange = (value, actions) => {
    PropsRef.current?.setFieldValue("product_name", "");
    PropsRef.current?.setFieldValue("product", []);
    PropsRef.current?.setFieldValue("meta_slug", "");

    if (actions.action === "clear") {
      PropsRef.current?.setFieldValue("product_sub_category", []);

      dispatch(setCMSSubCategoryToEdit(null));
      return;
    }

    const selected_option = Array.isArray ? value : [value];

    PropsRef.current?.setFieldValue("product_sub_category", [selected_option]);

    dispatch(
      fetchCMSSubCategories({
        id: selected_option?.id,
      })
    );

    dispatch(
      fetchAvaryaEcommerceProducts({
        active: true,
        extra: `category[]=${PropsRef.current?.values?.product_category?.[0]?.value}&sub_category[]=${selected_option?.value}&cms=1`,
      })
    );
  };

  const onProductChange = (value, actions) => {
    if (actions.action === "clear") {
      PropsRef.current?.setFieldValue("product", []);
      PropsRef.current?.setFieldValue("product_name", "");
      PropsRef.current?.setFieldValue("meta_slug", "");

      setProductDetails(null);

      return;
    }

    PropsRef.current?.setFieldValue("product", [value]);
    PropsRef.current?.setFieldValue(
      "meta_slug",
      `${value?.label?.replace(/ /g, "-").toLowerCase()}`
    );
    dispatch(
      getIndividualProduct({
        id: value.value,
        barcode: true,
        addProduct: (value) => {
          setProductDetails(value);
        },
      })
    );
  };

  const { resetState } = usePagination({
    update_page,
    current_page,
    total_items,
    total_pages,
    page_size,
    query,
  });

  const setProductDetails = (product) => {
    PropsRef.current?.setFieldValue(
      "product_name",
      product ? product?.print_name : ""
    );

    PropsRef.current?.setFieldValue(
      SEO_CONSTANTS.TITLE,
      product ? product?.print_name : ""
    );

    PropsRef.current?.setFieldValue(
      "product_code",
      product ? product?.product_code : ""
    );

    PropsRef.current?.setFieldValue(
      "expiration_days",
      product ? product?.expiration_days : ""
    );

    PropsRef.current?.setFieldValue(
      "net_weight",
      product ? product?.net_weight : ""
    );
    PropsRef.current?.setFieldValue(
      "uom",
      product
        ? [
            {
              code: product?.uom?.uom_code,
              value: product?.uom?.id,
              label: product?.uom?.uom_name,
            },
          ]
        : []
    );

    PropsRef.current?.setFieldValue(
      "category",
      product
        ? [
            {
              value: product?.category?.id,
              label: product?.category?.category_name,
            },
          ]
        : []
    );
    PropsRef.current?.setFieldValue(
      "sub_category",
      product
        ? [
            {
              value: product?.sub_category?.id,
              label: product?.sub_category?.sub_category_name,
            },
          ]
        : []
    );

    PropsRef.current?.setFieldValue(
      "tax",
      product
        ? product?.tax?.length > 0
          ? product?.tax?.map((item) => ({
              id: item.id,
              value: item.tax.id,
              label: item.tax.tax_name,
              rate: item.tax.tax_rate,
            }))
          : []
        : []
    );
    PropsRef.current?.setFieldValue(
      "hsn_code",
      product
        ? [
            {
              value: product?.hsn_code?.id,
              label: product?.hsn_code?.hsn_code,
            },
          ]
        : []
    );

    PropsRef.current?.setFieldValue(
      "selling_uom",
      product
        ? [
            {
              code: product?.selling_uom?.uom_code,
              value: product?.selling_uom?.id,
              label: product?.selling_uom?.uom_name,
            },
          ]
        : []
    );
  };

  const handleSubmit = (values, actions) => {
    const dataToSend = {
      id: values?.id,
      brand: values?.product?.[0]?.brand,
      product: values?.product?.[0]?.value,
      category: values?.product_category?.[0]?.id,
      sub_category: values?.product_sub_category?.[0]?.id,
      product_name: values?.product_name,
      description: values?.description,
      disclaimer: values?.disclaimer,
      media: values?.media?.map((item, mediaIndex) => ({
        id: item?.id,
        media_file:
          typeof item.media_file === "string"
            ? item.media_file
            : item.media_file?.preview,
        alt: item?.alt || "",
        priority: mediaIndex + 1,
      })),
      weight_variations: values?.weight_variations.map((item) => ({
        id: item?.id,
        weight: item?.weight,
      })),
      meta_title: values?.meta_title,
      meta_description: values?.meta_description,
      meta_slug: values?.meta_slug,
      // (selectedCMSCategory && selectedCMSCategory?.meta_slug
      //   ? `/${selectedCMSCategory?.meta_slug
      //       ?.replace(/ /g, "-")
      //       .toLowerCase()}${
      //       selectedCMSSubCategory && selectedCMSSubCategory?.meta_slug
      //         ? `/${selectedCMSSubCategory?.meta_slug
      //             ?.replace(/ /g, "-")
      //             .toLowerCase()}`
      //         : ""
      //     }/`
      //   : "") + values?.meta_slug,
      meta_keywords: values?.meta_keywords,
      is_active: values?.is_active?.[0]?.value,
    };
    if (id) {
      dispatch(
        updateExistingCMSProduct({
          id: Number(id),
          productParams: {
            id: Number(id),
            ...dataToSend,
            media: dataToSend.media.filter((item) => item.id === undefined),
            weight_variations: dataToSend.weight_variations.filter(
              (item) => item.id === undefined
            ),
          },
          actions: actions,
          navigate: navigate,
        })
      );
    } else {
      dispatch(
        addNewCMSProduct({
          productParams: dataToSend,
          actions: actions,
          navigate: navigate,
        })
      );
    }
  };

  useEffect(() => {
    dispatch(
      fetchCMSCategories({
        active: true,
      })
    );
    if (id) {
      dispatch(
        fetchFormValues({
          id: Number(id),
        })
      );
    }
    return () => {
      resetState();
      dispatch(resetCategoryState());
      dispatch(resetSubCategoryState());
      dispatch(setCMSSubCategoryToEdit(null));
      dispatch(setCMSCategoryToEdit(null));
    };
  }, [dispatch]);

  return (
    <div className="content-wrapper">
      <Container>
        <ContentHeader
          pageHeader={`${
            id ? ROLE_CONSTANTS.EDIT_LABEL : ROLE_CONSTANTS.ADD_LABEL
          } ${ECOMMERCE_PRODUCT_CONSTANTS.ADD_HEADER}`}
          editValue={productToEdit?.product_name}
        />
        <div className="col-12">
          <Formik
            enableReinitialize={!sorting}
            initialValues={
              productToEdit && Object?.keys(productToEdit)?.length > 0
                ? JSON.parse(JSON.stringify(productToEdit))
                : initialValues
            }
            validationSchema={EcommerceProductSchema}
            onSubmit={handleSubmit}
          >
            {(props) => {
              const bigMedia = [];
              const smallMedia = [];

              if (props.values?.media?.length > 0) {
                props?.values?.media.forEach((media) => {
                  if (media.media_type === "full") {
                    bigMedia.push(media);
                  } else {
                    smallMedia.push(media);
                  }
                });
              }

              PropsRef.current = props;
              return (
                <Form>
                  <ScrollToFieldError />
                  {product_status === STATUSES.LOADING && !props.isSubmitting && (
                    <div className="d-flex justify-content-center">
                      <Loader />
                    </div>
                  )}
                  <div className="row">
                    <div className="col-8">
                      <div className="row">
                        <div className="col-12">
                          <Card>
                            <CardBody>
                              <div className="row">
                                <div className="col-6">
                                  <MultiSelect
                                    showNoOptionMsg
                                    select={false}
                                    disabled={id !== undefined}
                                    name="product_category"
                                    label="Category"
                                    options={getCategoryOptions}
                                    placeholder="Select category"
                                    isLoading={
                                      CMSCategoryStatus === STATUSES.LOADING
                                    }
                                    onChangeHandler={onCategoryChange}
                                  />
                                  <ErrorMessage
                                    name="product_category"
                                    component={FORM_CONSTANTS.ERROR_PARENT}
                                    className={FORM_CONSTANTS.ERROR}
                                  />
                                </div>
                                <div className="col-6">
                                  <MultiSelect
                                    showNoOptionMsg
                                    select={false}
                                    disabled={
                                      props.values?.product_category?.length ===
                                        0 ||
                                      props.values?.product_category?.[0] ===
                                        null ||
                                      id !== undefined
                                    }
                                    name="product_sub_category"
                                    label="Sub Category"
                                    options={getSubCategoryOptions}
                                    placeholder="Select sub-category"
                                    isLoading={
                                      CMSSubCategoryStatus === STATUSES.LOADING
                                    }
                                    // onFocusHandler={() => {
                                    //   dispatch(
                                    //     fetchCMSSubCategories({
                                    //       active: true,
                                    //       extra: `category[]=${props.values?.product_category?.[0]?.value}`,
                                    //     })
                                    //   );
                                    // }}
                                    onChangeHandler={onSubCategoryChange}
                                  />
                                  <ErrorMessage
                                    name="product_sub_category"
                                    component={FORM_CONSTANTS.ERROR_PARENT}
                                    className={FORM_CONSTANTS.ERROR}
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-12">
                                  <MultiSelect
                                    showNoOptionMsg
                                    select={false}
                                    disabled={
                                      props.values?.product_sub_category
                                        ?.length === 0 ||
                                      props.values
                                        ?.product_sub_category?.[0] === null ||
                                      id !== undefined
                                    }
                                    name={"product"}
                                    label={"Product"}
                                    isLoading={
                                      commonStatus.state === STATUSES.LOADING &&
                                      commonStatus.type ===
                                        "fetchAvaryaProducts"
                                    }
                                    placeholder="Select Product"
                                    options={getProductOptions}
                                    // onFocusHandler={() => {
                                    //   product_options.length === 0 &&
                                    //     dispatch(
                                    //       fetchAvaryaEcommerceProducts({
                                    //         extra: `category[]=${props.values?.product_category?.[0]?.value}&sub_category[]=${props.values?.product_sub_category?.[0]?.value}`,
                                    //       })
                                    //     );
                                    // }}
                                    onInputChangeHandler={(value) => {
                                      value.length > 0 && optimizeSearch(value);
                                    }}
                                    onChangeHandler={onProductChange}
                                  />
                                  <ErrorMessage
                                    name="product"
                                    component={FORM_CONSTANTS.ERROR_PARENT}
                                    className={FORM_CONSTANTS.ERROR}
                                  />
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </div>
                        <div className="col-12">
                          <Card>
                            <CardHeader>
                              <div className="d-flex justify-content-between">
                                <h4 className="card-title">
                                  Ecommerce Details
                                </h4>
                              </div>
                            </CardHeader>
                            <CardBody>
                              <div className="row">
                                <div className="col-md-6">
                                  <CustomInput
                                    type={FORM_CONSTANTS.TEXT}
                                    name={"product_name"}
                                    label={"Product Display Name"}
                                    placeholder={"Enter product display name"}
                                    value={props.values.product_name}
                                  />
                                  <ErrorMessage
                                    name="product_name"
                                    component={FORM_CONSTANTS.ERROR_PARENT}
                                    className={FORM_CONSTANTS.ERROR}
                                  />
                                </div>
                                <div className="col-6">
                                  <SelectStatus
                                    props={props}
                                    name="is_active"
                                    label="Status"
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-12">
                                  <Summernote
                                    name={"description"}
                                    label={"Description"}
                                    value={props.values?.description || ""}
                                    onChange={(value) =>
                                      props.setFieldValue("description", value)
                                    }
                                    summerNoteHeight={250}
                                  />
                                  <ErrorMessage
                                    name="description"
                                    component={FORM_CONSTANTS.ERROR_PARENT}
                                    className={FORM_CONSTANTS.ERROR}
                                  />
                                </div>
                              </div>
                              {/* <div className="row">
                                <div className="col-md-12">
                                  <TextArea
                                    rows={3}
                                    name={"disclaimer"}
                                    label={"Disclaimer"}
                                    placeholder={
                                      "Enter Disclaimer for the product"
                                    }
                                  />
                                  <ErrorMessage
                                    name={"disclaimer"}
                                    component={FORM_CONSTANTS.ERROR_PARENT}
                                    className={FORM_CONSTANTS.ERROR}
                                  />
                                </div>
                              </div> */}
                            </CardBody>
                          </Card>
                        </div>
                        <SEODetailsform
                          slugHelper={
                            (selectedCMSCategory &&
                            selectedCMSCategory?.meta_slug
                              ? `/${selectedCMSCategory?.meta_slug
                                  ?.replace(/ /g, "-")
                                  .toLowerCase()}${
                                  selectedCMSSubCategory &&
                                  selectedCMSSubCategory?.meta_slug
                                    ? `/${selectedCMSSubCategory?.meta_slug
                                        ?.replace(/ /g, "-")
                                        .toLowerCase()}`
                                    : ""
                                }`
                              : props.values.slugHelper) || ""
                          }
                        />
                        <div className="col-md-6">
                          <Button
                            type={BUTTON_CONSTANTS.BUTTON}
                            text={FORM_CONSTANTS.SUBMIT}
                            loading={
                              props.isSubmitting &&
                              product_status === STATUSES.LOADING
                            }
                            btnClassNames={
                              "btn btn-primary align-self-center w-25"
                            }
                            onClickHandler={() => {
                              props.submitForm();
                              console.log(props.errors);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="row">
                        {props.values?.product?.length > 0 && (
                          <div className="col-12">
                            <ERPProductDetails props={props} />
                          </div>
                        )}
                        <div className="col-12">
                          <WeightForm
                            props={props}
                            setConfirmModal={setConfirmModal}
                            setCurrentItemToDelete={setCurrentItemToDelete}
                          />
                        </div>
                        <div className="col-12">
                          <Card>
                            <CardHeader>
                              <div className="row align-items-center">
                                <div className="col-6">
                                  <h3 className="card-title">
                                    Big Media Details
                                  </h3>
                                </div>
                                <div className="col-6 text-end">
                                  <IconButton
                                    icon={faAdd}
                                    isDisabled={
                                      props.values?.product?.length === 0
                                    }
                                    btnClassNames="btn btn-outline-primary btn-xs"
                                    btnText="Add Big Media"
                                    onClickHandler={() => {
                                      setMediaIndex(-1);
                                      setMediaOffCanvas({
                                        state: true,
                                        type: "big",
                                      });
                                    }}
                                    type={BUTTON_CONSTANTS.BUTTON}
                                  />
                                </div>
                              </div>
                            </CardHeader>
                            <CardBody>
                              <div className="col-12">
                                <FieldArray
                                  name="media"
                                  render={(arrayHelpers) => {
                                    mediaArrayHelpers.current = arrayHelpers;
                                    const { values } = props;

                                    return bigMedia && bigMedia?.length > 0 ? (
                                      <ReactSortable
                                        key={"media"}
                                        animation={100}
                                        easing="ease-in-out"
                                        className="d-flex flex-row gap-2 overflow-x-scroll"
                                        list={values.media || []}
                                        setList={(newList) => {
                                          props.setFieldValue("media", newList);
                                        }}
                                        onSort={() => {
                                          setSorting(true);
                                        }}
                                        onChoose={() => {
                                          setSorting(true);
                                        }}
                                      >
                                        {bigMedia &&
                                          bigMedia?.length > 0 &&
                                          bigMedia?.map((media, mediaIndex) => (
                                            <div
                                              key={mediaIndex}
                                              className="hover_img_container"
                                              style={{
                                                borderRadius: 2,
                                                border: "1px solid #eaeaea",
                                              }}
                                            >
                                              <div className="hover_img_middle">
                                                <Button
                                                  text={
                                                    <FontIcon
                                                      icon={faPenToSquare}
                                                    />
                                                  }
                                                  btnClassNames="hover_img_button btn-sm btn btn-info mr-2"
                                                  onClickHandler={() => {
                                                    setMediaIndex(mediaIndex);
                                                    setMediaOffCanvas({
                                                      state: true,
                                                      type: "big",
                                                    });
                                                  }}
                                                  type={BUTTON_CONSTANTS.BUTTON}
                                                />

                                                <Button
                                                  text={
                                                    <FontIcon icon={faTrash} />
                                                  }
                                                  btnClassNames="hover_img_button btn-sm btn btn-danger"
                                                  onClickHandler={() => {
                                                    setConfirmModal(true);
                                                    setCurrentItemToDelete({
                                                      id: media.id,
                                                      removeIndex: () => {
                                                        arrayHelpers.remove(
                                                          mediaIndex
                                                        );
                                                      },
                                                      type: "media",
                                                    });
                                                  }}
                                                  type={BUTTON_CONSTANTS.BUTTON}
                                                />
                                              </div>
                                              <img
                                                src={
                                                  media.media_file.preview ||
                                                  media.media_file ||
                                                  ""
                                                }
                                                className="hover_img img-responsive w-100 h-100"
                                              />
                                            </div>
                                          ))}
                                      </ReactSortable>
                                    ) : (
                                      <EmptyFormMessage
                                        disabled={
                                          props?.values?.product?.length === 0
                                        }
                                        disabledMessage={
                                          "Please choose a product before adding images"
                                        }
                                        emptyMessage={"Add Images / Videos"}
                                        icon={faImages}
                                        iconSize={"lg"}
                                        actions={() => {
                                          setMediaIndex(-1);
                                          setMediaOffCanvas({
                                            state: true,
                                            type: "big",
                                          });
                                        }}
                                      />
                                    );
                                  }}
                                />
                              </div>
                              <ErrorMessage
                                name="media"
                                component={FORM_CONSTANTS.ERROR_PARENT}
                                className={FORM_CONSTANTS.ERROR}
                              />
                            </CardBody>
                          </Card>
                        </div>
                        <div className="col-12">
                          <Card>
                            <CardHeader>
                              <div className="row align-items-center">
                                <div className="col-6">
                                  <h3 className="card-title">
                                    Small Media Details
                                  </h3>
                                </div>
                                <div className="col-6 text-end">
                                  <IconButton
                                    icon={faAdd}
                                    isDisabled={
                                      props.values?.product?.length === 0
                                    }
                                    btnClassNames="btn btn-outline-primary btn-xs"
                                    btnText="Add Small Media"
                                    onClickHandler={() => {
                                      setMediaIndex(-1);
                                      setMediaOffCanvas({
                                        state: true,
                                        type: "small",
                                      });
                                    }}
                                    type={BUTTON_CONSTANTS.BUTTON}
                                  />
                                </div>
                              </div>
                            </CardHeader>
                            <CardBody>
                              <div className="col-12">
                                <FieldArray
                                  name="media"
                                  render={(arrayHelpers) => {
                                    mediaArrayHelpers.current = arrayHelpers;
                                    const { values } = props;

                                    return smallMedia &&
                                      smallMedia?.length > 0 ? (
                                      <ReactSortable
                                        key={"media"}
                                        animation={100}
                                        easing="ease-in-out"
                                        className="d-flex flex-row gap-2 overflow-x-scroll"
                                        list={values.media || []}
                                        setList={(newList) => {
                                          props.setFieldValue("media", newList);
                                        }}
                                        onSort={() => {
                                          setSorting(true);
                                        }}
                                        onChoose={() => {
                                          setSorting(true);
                                        }}
                                      >
                                        {smallMedia &&
                                          smallMedia?.length > 0 &&
                                          smallMedia?.map(
                                            (media, mediaIndex) => (
                                              <div
                                                key={mediaIndex}
                                                className="hover_img_container"
                                                style={{
                                                  borderRadius: 2,
                                                  border: "1px solid #eaeaea",
                                                }}
                                              >
                                                <div className="hover_img_middle">
                                                  <Button
                                                    text={
                                                      <FontIcon
                                                        icon={faPenToSquare}
                                                      />
                                                    }
                                                    btnClassNames="hover_img_button btn-sm btn btn-info mr-2"
                                                    onClickHandler={() => {
                                                      setMediaIndex(mediaIndex);
                                                      setMediaOffCanvas({
                                                        state: true,
                                                        type: "small",
                                                      });
                                                    }}
                                                    type={
                                                      BUTTON_CONSTANTS.BUTTON
                                                    }
                                                  />

                                                  <Button
                                                    text={
                                                      <FontIcon
                                                        icon={faTrash}
                                                      />
                                                    }
                                                    btnClassNames="hover_img_button btn-sm btn btn-danger"
                                                    onClickHandler={() => {
                                                      setConfirmModal(true);
                                                      setCurrentItemToDelete({
                                                        id: media.id,
                                                        removeIndex: () => {
                                                          arrayHelpers.remove(
                                                            mediaIndex
                                                          );
                                                        },
                                                        type: "media",
                                                      });
                                                    }}
                                                    type={
                                                      BUTTON_CONSTANTS.BUTTON
                                                    }
                                                  />
                                                </div>
                                                <img
                                                  src={
                                                    media.media_file.preview ||
                                                    media.media_file ||
                                                    ""
                                                  }
                                                  className="hover_img img-responsive w-100 h-100"
                                                />
                                              </div>
                                            )
                                          )}
                                      </ReactSortable>
                                    ) : (
                                      <EmptyFormMessage
                                        disabled={
                                          props?.values?.product?.length === 0
                                        }
                                        disabledMessage={
                                          "Please choose a product before adding images"
                                        }
                                        emptyMessage={"Add Images / Videos"}
                                        icon={faImages}
                                        iconSize={"lg"}
                                        actions={() => {
                                          setMediaIndex(-1);
                                          setMediaOffCanvas({
                                            state: true,
                                            type: "small",
                                          });
                                        }}
                                      />
                                    );
                                  }}
                                />
                              </div>
                              <ErrorMessage
                                name="media"
                                component={FORM_CONSTANTS.ERROR_PARENT}
                                className={FORM_CONSTANTS.ERROR}
                              />
                            </CardBody>
                          </Card>
                        </div>
                      </div>
                    </div>
                  </div>
                  <MediaOffCanvas
                    props={props}
                    arrayHelpers={mediaArrayHelpers.current}
                    index={mediaIndex}
                    show={mediaOffCanvas}
                    setShow={setMediaOffCanvas}
                  />
                </Form>
              );
            }}
          </Formik>
        </div>
      </Container>
      <ConfirmModal
        modal={confirmModal}
        setModal={setConfirmModal}
        title={
          currentItemToDelete.type === "weight"
            ? ECOMMERCE_PRODUCT_CONSTANTS.DELETE_PRODUCT_WEIGHT
            : currentItemToDelete.type === "media"
            ? ECOMMERCE_PRODUCT_CONSTANTS.DELETE_PRODUCT_IMAGE
            : ECOMMERCE_PRODUCT_CONSTANTS.DELETE_PRODUCT_TASTE
        }
        message={
          currentItemToDelete.type === "weight"
            ? ECOMMERCE_PRODUCT_CONSTANTS.DELETE_PRODUCT_WEIGHT_MSG
            : currentItemToDelete.type === "media"
            ? ECOMMERCE_PRODUCT_CONSTANTS.DELETE_PRODUCT_IMAGE_MSG
            : ECOMMERCE_PRODUCT_CONSTANTS.DELETE_PRODUCT_TASTE_MSG
        }
        confirmClick={() => {
          setConfirmModal(false);
          currentItemToDelete.removeIndex();
          switch (currentItemToDelete.type) {
            case "weight":
              dispatch(
                deleteWeightVariation({
                  id: currentItemToDelete.id,
                  is_active: false,
                })
              );
              break;

            case "media":
              dispatch(deleteMediaMapping(currentItemToDelete.id));
              break;

            default:
              break;
          }
        }}
        rejectClick={() => {
          setConfirmModal(false);
        }}
      />
    </div>
  );
};

export { ProductsForm as Ecommerce_ProductsForm };
