import { TypeOptions } from "react-toastify";
import {
  ButtonConstantstype,
  TModalHeight,
  TModalSize,
  TPOSHeaderQuery,
} from "./types";

// API request method object
export const REQUEST_METHOD = Object.freeze({
  POST: "POST",
  GET: "GET",
  PUT: "PUT",
  DELETE: "DELETE",
});

export const ROUTES = Object.freeze({
  LOGIN: "/admin/login",
  ADMIN: "/admin",
  DASHBOARD: "/admin/dashboard",
  ROLES: "/admin/roles",
  EMPLOYEE: "/admin/employee",
  BANK: "/admin/banks",
  CUSTOMER: "/admin/customers",
  BRANCh: "/admin/branches",
  PRODUCT: "/admin/products",
  CATEGORY: "category",
  SUBCATEGORY: "sub-category",
  BRAND: "brands",
  TAX: "tax",
  GROUP: "groups",
  DISCOUNT: "/admin/discounts",
  VENDOR: "/admin/vendors",
  PAYMENT_MODE: "modes",
  PAYMENT_TERM: "terms",
  HSNCODE: "hsn-codes",
  UOM: "uom",
  SALES: "/admin/sales",
  POS: "pos",
  CASHIER: "cashier",
  ORDER: "orders",
  PENDINGORDER: "pending-orders",
  ADVANCE: "advance",
  CREDITNOTE: "credit-notes",
  REPORT: "/admin/reports",
  KPI: "kpi",
  UTILITIES: "/admin/utilities",
  LABEL: "label",
  PRINTER: "printer",
  BARCODE: "barcode",
});

export const S3_FOLDER_CONSTANTS = Object.freeze({
  CATEGORY_ICON: "category/icons",
  SUB_CATEGORY_ICON: "sub-category/icons",
  PRODUCT_WIDGET: "product/content",
  SITE_SETTING: "site-settings",
});

export const RESPONSIVE_CONSTANTS = Object.freeze({
  MEDIUM: 1024,
});

// Request status object
export const STATUSES = Object.freeze({
  LOADING: "loading" as string,
  IDLE: "idle" as string,
  ERROR: "error" as string,
  TIME_DELAY: "time_delay" as string,
  NO_INTERNET: "no_internet" as string,
});

// Request Content type
export const REQUEST_CONTENT_TYPE = Object.freeze({
  APP_JSON: "application/json",
  FORM_DATA: "multipart/form-data",
});

export const BRAND_NAME = Object.freeze({
  BRAND_NAME: "Avarya Retail",
});

export const PERMISSION_CONSTANTS = Object.freeze({
  CATEGORY: "Manage Product Category",
  SUB_CATEGORY: "Manage Product Sub Category",
  PRODUCT: "Manage Products",
  //POS RELATED
  CANCEL_ORDER: "Cancel Order",
  //ECOMMERCE RELATED
  MENU: "Manage Menu",
  FAQ: "Manage FAQs",
  PAGES: "Manage Pages",
  EMAIL_TEMPLATE: "Manage Email Templates",
  TESTIMONIALS: "Manage Testimonials",
  WIDGETS: "Manage Widgets",
  TASTE: "Manage Taste",
});

export const ACCESS_CONSTANTS = Object.freeze({
  AVARYA_FULL_ACCESS: [
    "admin@avarya.in",
    "vipesh@avarya.in",
    "nikhil@avarya.in",
  ],
  AVARYA_BRAND_ID: 669,
  CASH_SALES_ID: 55,
  ECOMMERCE_STORE: 45,
  PRICE_DATA_STORE: 4,
});

//ROUTES CONSTANTS
export const ROUTES_CONSTANTS = Object.freeze({
  ADD: "add",
  EDIT: "edit",
  ID: "id",
});

//FORM CONSTANTS
export const FORM_CONSTANTS = Object.freeze({
  ERROR_PARENT: "div",
  ERROR: "text-danger",
  EMAIL: "email",
  PASSWORD: "password",
  TEXT: "text",
  NUMBER: "number",
  DATE: "date",
  TIME: "time",
  TEXTAREA: "textarea",
  CHECKBOX: "checkbox",
  RADIO: "radio",
  SAVE: "Save",
  SUBMIT: "Submit",
  PRICING_COLS: [
    "Store",
    "MRP",
    "Selling Discount",
    "Selling Price",
    "MSQ",
    // "Actions",
  ],
  PRODUCT_GRP_PRICING_COLS: [
    "Store",
    "MRP",
    "Selling Discount",
    "Selling Price",
  ],
  DAYS_OF_WEEK: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
  NAME_QUANTITY: [
    "Product",
    "Batch",
    "Net Weight",
    "Quantity",
    "Total Amount",
    "Actions",
  ],
  DEFAULT_CITY: [
    {
      value: 133024,
      label: "Mumbai",
    },
  ],
  DEFAULT_STATE: [
    {
      value: 4008,
      label: "Maharashtra",
    },
  ],
  DEFAULT_COUNTRY: [
    {
      value: 101,
      label: "India",
    },
  ],
  DEFAULT_PINCODE: 400001,
});

export const LOCAL_STORAGE_CONSTANTS = Object.freeze({
  PENDING_PAY_LATER_ORDERS: "pending_pay_later_filters",
});

//GENERAL CONSTANTS
export const GENERAL_CONSTANTS = Object.freeze({
  DATE_FORMAT: "YYYY-MM-DD",
  COMPACT_DATE_FORMAT: "DD/MM/YY",
  TIME_FORMAT: "hh:mm",
  STRING: "string",
  EMPTY_STRING: "",
  EMPTY_ARRAY: [],
  CLEAR: "clear",
  ENTER: "Enter",
  BACK: "Back",
  LOADING: "Loading...",
  SEARCH: "Search ...",
  CONFIRM_MODAL_MESSAGE: "Are you sure you want to proceed?",
  PRIMARY: "primary",
  SECONDARY: "secondary",
  SUCCESS: "success",
  DANGER: "danger",
  WARNING: "warning",
  DARK: "dark",
  INFO: "info",
  FUCHSIA: "fuchsia",
  INDIGO: "indigo",
  MAROON: "maroon",
  NAVY: "navy",
  LIME: "lime",
  LIGHT_BLUE: "lightblue",
  TRUE: true,
  FALSE: false,
  ACTIVE: "Active",
  INACTIVE: "Inactive",
  PERCENTAGE: "percentage",
  PERCENTAGE_SYMBOL: "%",
  FLAT: "flat",
  FLAT_SYMBOL: "₹",
  NA: "N/A",
  YES: "Yes",
  CONFIRMED: "confirmed",
  REFUNDED: "refunded",
  PLACED: "placed",
  PROCESSED: "processed",
  FAILED: "failed",
  SHIPPED: "shipped",
  OUT_FOR_DELIVERY: "out_for_delivery",
  DELIVERED: "delivered",
  COMPLETED: "completed",
  PENDING: "pending",
  CANCELLLED: "cancelled",
  NO: "No",
  ALL: "all",
  STATUS_OPTIONS: [
    { value: "1", label: "Active" },
    { value: "0", label: "Inactive" },
  ],
  YES_NO_OPTIONS: [
    { value: 1, label: "Yes" },
    { value: 0, label: "No" },
  ],
  TAG_OPTIONS: [
    { value: "1", label: "Tag 1" },
    { value: "2", label: "Tag 2" },
    { value: "3", label: "Tag 3" },
    { value: "4", label: "Tag 4" },
    { value: "5", label: "Tag 5" },
    {
      value: "6",
      label: "Tag 6",
    },
  ],
  LARGE: "lg" as TModalSize,
  MEDIUM: "md" as TModalSize,
  SMALL: "sm" as TModalSize,
  EXTRA_SMALL: "xs" as TModalSize,
  EXTRA_LARGE: "xl" as TModalSize,
  EXTRA_EXTRA_LARGE: "xxl" as TModalSize,
  NAVIGATE: "navigate",
  PROCEED: "Proceed",
  CONFIRM_CHANGES: "Confirm Changes",
  APPLY: "Apply",
});

export const EVENT_CONSTANTS = Object.freeze({
  KEYUP: "keyup",
  KEYDOWN: "keydown",
});

//BUTTON CONSTANTS
export const BUTTON_CONSTANTS: ButtonConstantstype = Object.freeze({
  BUTTON: "button",
  SUBMIT: "submit",
  RESET: "reset",
  SAVE: "Save",
});

//App Constants
export const LOGIN_CONSTANTS = Object.freeze({
  LOGIN_MSG: "Sign in to start your session",
  INVALID_EMAIL: "Please enter valid email",
  REQUIRED: "This field is required",
  EMAIL: "login_email",
  EMAIL_LABEL: "Email",
  EMAIL_PLACEHOLDER: "Enter email",
  PASSWORD: "login_password",
  PASSWORD_LABEL: "Password",
  PASSWORD_PLACEHOLDER: "Enter password",
  SHOW_PASSWORD: "Show Password",
  LOGIN: "Login",
});

//Error Constants
export const ERROR_CONSTANTS = Object.freeze({
  ERROR_404: "404",
  PAGE_NOT_FOUND: "Oops! Page not found.",
  ERROR_MSG:
    "We could not find the page you were looking for. Meanwhile, you may ",
  RUNTIME_ERROR_MSG:
    "Something went wrong. Please try again later. Meanwhile, you may ",
  RETURN_TO_DASHBOARD: "return to dashboard",
  SOMETHING_WENT_WRONG: "Something went wrong!",
});

//Sidebar Constants
export const SIDEBAR_CONSTANTS = Object.freeze({
  DASHBOARD: "Dashboard",
  MASTERS: "MASTERS & POS",
  ONLINE_STORE: "ONLINE STORE",
  UTILITIES: "Utilities",
  PRINT: "Printer",
  LABEL: "Label",
});

//Bank Constants
export const BANK_CONSTANTS = Object.freeze({
  BANK_NAVIGATE: "/admin/banks",
  BANK_HEADER: "Manage Banks",

  PATH: "banks",
  LABEL: "Bank",

  BANK_COLS: ["#", "Bank Name", "Bank Status", "Action"],
  ADD_HEADER: "Add Bank",
  EDIT_HEADER: "Edit Bank",
  DELETE_BANK: "Delete Bank",
  DELETE_BANK_MSG: "Are you sure you want to make this bank inactive?",

  //form fields

  //Bank Details
  CARD_TITLE_1: "Bank Details",
  //Bank Name
  BANK_NAME_LABEL: "Name",
  BANK_NAME: "bank_name",
  BANK_NAME_PLACEHOLDER: "Enter Bank Name",
  //Bank Status
  BANK_STATUS_LABEL: "Status",
  BANK_STATUS: "is_active",
  BANK_STATUS_PLACEHOLDER: "Select Bank Status",
  BANK_STATUS_OPTIONS_LABEL: "Select Bank Status",
  BANK_STATUS_OPTIONS: [
    { value: "1", label: "Active" },
    { value: "0", label: "Inactive" },
  ],
  //Bank IFSC Code
  BANK_IFSC_CODE_LABEL: "IFSC Code",
  BANK_IFSC_CODE: "ifsc_code",
  BANK_IFSC_CODE_PLACEHOLDER: "Enter IFSC Code",
  //Branch Name
  BANK_BRANCH_NAME_LABEL: "Branch Name",
  BANK_BRANCH_NAME: "branch_name",
  BANK_BRANCH_NAME_PLACEHOLDER: "Enter Branch Name",
  //Bank Address
  CARD_TITLE_2: "Address Details",

  ADDRESS_LINE_1_LABEL: "Address",
  ADDRESS_LINE_1: "bank_address_line_1",
  ADDRESS_LINE_1_PLACEHOLDER: "Enter address line 1",
  ADDRESS_LINE_2_LABEL: "Address",
  ADDRESS_LINE_2: "bank_address_line_2",
  ADDRESS_LINE_2_PLACEHOLDER: "Enter address line 2",
  //Bank City
  CITY_LABEL: "City",
  CITY: "bank_city",
  CITY_PLACEHOLDER: "Enter city",
  //State
  STATE_LABEL: "State",
  STATE: "bank_state",
  STATE_PLACEHOLDER: "Enter state",
  //Country
  COUNTRY_LABEL: "Country",
  COUNTRY: "bank_country",
  COUNTRY_PLACEHOLDER: "Enter country",
  //Bank Pincode
  PINCODE_LABEL: "Pincode",
  PINCODE: "bank_pincode",
  PINCODE_PLACEHOLDER: "Enter pincode",

  //Account Details
  CARD_TITLE_3: "Account Details",
  //Account Holder Name
  ACCOUNT_HOLDER_NAME_LABEL: "Account Holder Name",
  ACCOUNT_HOLDER_NAME: "account_holder_name",
  ACCOUNT_HOLDER_NAME_PLACEHOLDER: "Enter Account Holder Name",
  //Account Number
  ACCOUNT_NUMBER_LABEL: "Account Number",
  ACCOUNT_NUMBER: "account_number",
  ACCOUNT_NUMBER_PLACEHOLDER: "Enter Account Number",
  //Opening Credit Balance
  OPENING_CREDIT_BALANCE_LABEL: "Opening Credit Balance",
  OPENING_CREDIT_BALANCE: "opening_credit_balance",
  OPENING_CREDIT_BALANCE_PLACEHOLDER: "Enter Opening Credit Balance",
  //Opening Debit Balance
  OPENING_DEBIT_BALANCE_LABEL: "Opening Debit Balance",
  OPENING_DEBIT_BALANCE: "opening_debit_balance",
  OPENING_DEBIT_BALANCE_PLACEHOLDER: "Enter Opening Debit Balance",
  //UPI Available
  UPI_AVAILABLE_LABEL: "UPI Available",
  UPI_AVAILABLE: "is_upi_available",
  UPI_AVAILABLE_OPTIONS_LABEL: "Is UPI Available",
  UPI_AVAILABLE_OPTIONS: [
    { value: 1, label: "Yes" },
    { value: 0, label: "No" },
  ],
});

//Role Constants
export const ROLE_CONSTANTS = Object.freeze({
  ROLE_NAVIGATE: "/admin/roles",
  ROLE_HEADER: "Manage Roles",

  PATH: "roles",
  LABEL: "Role",

  ROLE_COLS: ["#", "Role Name", "Status", "Actions"],
  PERMISSION_COLS: [
    "#",
    "Module Name",
    "View",
    "Add",
    "Edit",
    "Delete",
    "Authorize",
  ],

  ADD_HEADER: "Add Role",
  EDIT_HEADER: "Edit Role",
  DELETE_ROLE: "Delete Role",
  DELETE_ROLE_MSG: "Are you sure you want to make this role inactive?",
  //form fields
  //Role Name
  ROLE_NAME_LABEL: "Role Name",
  ROLE_NAME: "role_name",
  ROLE_NAME_PLACEHOLDER: "Enter Role Name",
  //Role Status
  ROLE_STATUS_LABEL: "Role Status",
  ROLE_STATUS: "is_active",
  ROLE_STATUS_PLACEHOLDER: "Select Role Status",
  ROLE_STATUS_OPTIONS_LABEL: "Select Role Status",
  ROLE_STATUS_OPTIONS: [
    { value: "1", label: "Active" },
    { value: "0", label: "Inactive" },
  ],
  //Permissions
  PERMISSIONS_LABEL: "Permissions",
  PERMISSIONS: "permissions",
  PERMISSIONS_OPTIONS_LABEL: "Select Permissions",
  BULK_ROW: "bulk_row",
  BULK_VIEW: "bulk_view",
  VIEW_LABEL: "View",
  VIEW: "perm_view",
  BULK_ADD: "bulk_add",
  ADD_LABEL: "Add",
  ADD: "perm_add",
  BULK_EDIT: "bulk_edit",
  EDIT_LABEL: "Edit",
  EDIT: "perm_edit",
  BULK_DELETE: "bulk_delete",
  DELETE_LABEL: "Delete",
  DELETE: "perm_delete",
  BULK_AUTHORIZE: "bulk_authorize",
  AUTHORIZE_LABEL: "Authorize",
  AUTHORIZE: "perm_authorize",
});

//Permissions for modules in Role
export const ROLE_PERMISSIONS = Object.freeze({
  view: false,
  add: false,
  edit: false,
  delete: false,
  authorize: false,
});

//Brand Constants
export const BRANCH_CONSTANTS = Object.freeze({
  BRANCH_NAVIGATE: "/admin/branches",
  BRANCH_HEADER: "Manage Branches",

  PATH: "branches",
  LABEL: "Branch",

  BRANCH_COLS: [
    "#",
    "Branch Name",
    "Branch Code",
    "Outlet Type",
    "Status",
    "Actions",
  ],
  ADD_HEADER: "Add Branch",
  EDIT_HEADER: "Edit Branch",
  DELETE_BRANCH: "Delete Branch",
  DELETE_BRANCH_MSG: "Are you sure you want to make this branch inactive?",

  //form fields
  CARD_TITLE_1: "Branch Details",
  //Branch Name
  BRANCH_NAME_LABEL: "Branch Name",
  BRANCH_NAME: "store_name",
  BRANCH_NAME_PLACEHOLDER: "Enter Branch Name",
  //Branch Email
  BRANCH_EMAIL_LABEL: "Branch Email",
  BRANCH_EMAIL: "email",
  BRANCH_EMAIL_PLACEHOLDER: "Enter Branch Email",
  //Branch Code
  BRANCH_CODE_LABEL: "Branch Code",
  BRANCH_CODE: "store_code",
  BRANCH_CODE_PLACEHOLDER: "Enter Branch Code",
  //Branch Status
  BRANCH_STATUS_LABEL: "Branch Status",
  BRANCH_STATUS: "is_active",
  BRANCH_STATUS_PLACEHOLDER: "Select Branch Status",
  BRANCH_STATUS_OPTIONS: [
    { value: "1", label: "Active" },
    { value: "0", label: "Inactive" },
  ],
  //Branch Outlet Type
  BRANCH_OUTLET_TYPE_LABEL: "Outlet Type",
  BRANCH_OUTLET_TYPE: "outlet_type",
  BRANCH_OUTLET_TYPE_PLACEHOLDER: "Select Outlet Type",
  //Contact Name
  CONTACT_NAME_LABEL: "Contact Name",
  CONTACT_NAME: "contact_name",
  CONTACT_NAME_PLACEHOLDER: "Enter Contact Name",
  //Contact Number
  CONTACT_NUMBER_LABEL: "Contact Number",
  CONTACT_NUMBER: "contact_number",
  CONTACT_NUMBER_PLACEHOLDER: "Enter Contact Number",
  //GSTN
  GSTN_LABEL: "GSTN",
  GSTN: "gstn",
  GSTN_PLACEHOLDER: "Enter GSTN",
  //PAN
  PAN_LABEL: "PAN",
  PAN: "pan",
  PAN_PLACEHOLDER: "Enter PAN",
  //FSSAI
  FSSAI_LABEL: "FSSAI",
  FSSAI: "fssai_number",
  FSSAI_PLACEHOLDER: "Enter FSSAI",

  //Markup
  MARKUP_LABEL: "Markup",
  MARKUP: "markup",
  MARKUP_PLACEHOLDER: "Enter Markup to be applied for the copied stores",

  //Is Ecommerce
  IS_ECOMMERCE_LABEL: "Is Ecommerce ?",
  IS_ECOMMERCE: "is_ecommerce",
  IS_ECOMMERCE_PLACEHOLDER: "Is this branch for Ecommerce ?",

  //Copy Store
  COPY_STORE: "store_price_copy",
  COPY_STORE_LABEL: "Copy Store",
  COPY_STORE_PLACEHOLDER: "Select store to copy price from",

  //Branch Address
  CARD_TITLE_2: "Address Details",
  LOCATION: "address",
  ADDRESS_LINE_1: "address_line_1",
  ADDRESS_LINE_1_LABEL: "Address Line 1",
  ADDRESS_LINE_1_PLACEHOLDER: "Enter address line 1",
  ADDRESS_LINE_2: "address_line_2",
  ADDRESS_LINE_2_LABEL: "Address Line 2",
  ADDRESS_LINE_2_PLACEHOLDER: "Enter address line 2",
  //Branch Address
  BRANCH_ADDRESS_LABEL: "Address",
  BRANCH_ADDRESS: "address",
  BRANCH_ADDRESS_PLACEHOLDER: "Enter address",
  //Branch City
  BRANCH_CITY_LABEL: "City",
  BRANCH_CITY: "city",
  BRANCH_CITY_PLACEHOLDER: "Enter city",
  //Branch State
  BRANCH_STATE_LABEL: "State",
  BRANCH_STATE: "state",
  BRANCH_STATE_PLACEHOLDER: "Enter state",
  //Branch Country
  BRANCH_COUNTRY_LABEL: "Country",
  BRANCH_COUNTRY: "country",
  BRANCH_COUNTRY_PLACEHOLDER: "Enter country",
  //Branch Pincode
  BRANCH_PINCODE_LABEL: "Pincode",
  BRANCH_PINCODE: "pincode",
  BRANCH_PINCODE_PLACEHOLDER: "Enter pincode",
  //Bank Details
  CARD_TITLE_3: "Bank Details",
  //Bank Name
  BANK_NAME_LABEL: "Bank",
  BANK_NAME: "bank_name",
  BANK_NAME_PLACEHOLDER: "Enter bank name",
  //Bank Account Number
  BANK_ACCOUNT_NUMBER_LABEL: "Bank Account Number",
  BANK_ACCOUNT_NUMBER: "account_no",
  BANK_ACCOUNT_NUMBER_PLACEHOLDER: "Enter bank account number",
  //Bank Account Holder Name
  BANK_ACCOUNT_HOLDER_NAME_LABEL: "Bank Account Holder Name",
  BANK_ACCOUNT_HOLDER_NAME: "account_holder_name",
  BANK_ACCOUNT_HOLDER_NAME_PLACEHOLDER: "Enter Bank Account Holder Name",
  //Bank Billing Address
  CARD_TITLE_4: "Billing Address Details",
  BILLING_ADDRESS: "purchase_billing_address",
  //Bank Billing Address

  BILLING_ADDRESS_LINE_1_LABEL: "Address Line 1",
  BILLING_ADDRESS_LINE_1_PLACEHOLDER: "Enter billing address line 1",
  BILLING_ADDRESS_LINE_2_LABEL: "Address Line 2",
  BILLING_ADDRESS_LINE_2_PLACEHOLDER: "Enter billing address line 2",
  //Bank Billing City
  BILLING_CITY_LABEL: "City",
  BILLING_CITY_PLACEHOLDER: "Enter City",
  //Bank State
  BILLING_STATE_LABEL: "State",
  BILLING_STATE_PLACEHOLDER: "Enter State",
  //Bank Country
  BILLING_COUNTRY_LABEL: "Country",
  BILLING_COUNTRY_PLACEHOLDER: "Enter Country",
  //Bank Pincode
  BILLING_PINCODE_LABEL: "Pincode",
  BILLING_PINCODE_PLACEHOLDER: "Enter Pincode",
});

//Category Constants
export const CUSTOMERS_CONSTANTS = Object.freeze({
  CUSTOMERS_NAVIGATE: "/admin/customers",
  CUSTOMERS_HEADER: "Manage Customers",

  PATH: "customers",
  LABEL: "Customer",

  CUSTOMERS_COLS: ["#", "Name", "Email", "Phone", "Status", "Actions"],
  ADD_HEADER: "Add Customer",
  EDIT_HEADER: "Edit Customer",
  DELETE_CUSTOMER: "Delete Customer",
  DELETE_CUSTOMER_MSG: "Are you sure you want to make this customer inactive?",
  //form fields
  CARD_TITLE_1: "Customer Details",

  //Customer Designation
  DESIGNATION_LABEL: "Designation",
  DESIGNATION: "designation",
  DESIGNATION_OPTIONS: [
    { label: "Mr.", value: "Mr." },
    { label: "Mrs.", value: "Mrs." },
    { label: "Ms.", value: "Ms." },
    { label: "Dr.", value: "Dr." },
    { label: "Prof.", value: "Prof." },
  ],
  //Customer Name
  CUSTOMER_NAME_LABEL: "Name",
  CUSTOMER_NAME: "name",
  CUSTOMER_NAME_PLACEHOLDER: "Enter customer name",
  //Customer Company Name
  CUSTOMER_COMPANY_NAME_LABEL: "Company Name",
  CUSTOMER_COMPANY_NAME: "company_name",
  CUSTOMER_COMPANY_NAME_PLACEHOLDER: "Enter customer company name",
  //Customer Email
  CUSTOMER_EMAIL_LABEL: "Email",
  CUSTOMER_EMAIL: "email",
  CUSTOMER_EMAIL_PLACEHOLDER: "Enter customer email",
  //Customer MObile Number
  CUSTOMER_MOBILE_NUMBER_LABEL: "Mobile Number",
  CUSTOMER_MOBILE_NUMBER: "contact_number",
  CUSTOMER_MOBILE_NUMBER_PLACEHOLDER: "Enter customer mobile number",
  //Customer Whatsapp Number
  CUSTOMER_WHATSAPP_NUMBER_LABEL: "Whatsapp Number",
  CUSTOMER_WHATSAPP_NUMBER: "whatsapp_number",
  CUSTOMER_WHATSAPP_NUMBER_PLACEHOLDER: "Enter customer whatsapp number",
  //Customer DOB
  CUSTOMER_DOB_LABEL: "DOB",
  CUSTOMER_DOB: "dob",
  CUSTOMER_DOB_PLACEHOLDER: "Enter Customer DOB",
  //Customer Anniversary Date
  CUSTOMER_ANNIVERSARY_DATE_LABEL: "Anniversary Date",
  CUSTOMER_ANNIVERSARY_DATE: "anniversary",
  CUSTOMER_ANNIVERSARY_DATE_PLACEHOLDER: "Enter Customer Anniversary Date",
  //Customer Remarks
  CUSTOMER_REMARKS_LABEL: "Remarks",
  CUSTOMER_REMARKS: "remarks",
  CUSTOMER_REMARKS_PLACEHOLDER: "Enter customer remarks",

  ADD_CUSTOMER_ADDRESS: "Add Address",

  //Customer Address
  CARD_TITLE_2: "Address Details",
  //Customer Address
  // CUSTOMER_ADDRESS: "customer_address",
  CUSTOMER_ADDRESS: "address",

  ADDRESS_LINE_1: "address_line_1",
  ADDRESS_LINE_1_LABEL: "Address Line 1",
  ADDRESS_LINE_1_PLACEHOLDER: "Enter address line 1",
  ADDRESS_LINE_2: "address_line_2",
  ADDRESS_LINE_2_LABEL: "Address Line 2",
  ADDRESS_LINE_2_PLACEHOLDER: "Enter address line 2",
  //City
  CITY_LABEL: "City",
  CITY: "city",
  CITY_PLACEHOLDER: "Enter city",
  //State
  STATE_LABEL: "State",
  STATE: "state",
  STATE_PLACEHOLDER: "Enter state",
  //Country
  COUNTRY_LABEL: "Country",
  COUNTRY: "country",
  COUNTRY_PLACEHOLDER: "Enter country",
  //Pincode
  PINCODE_LABEL: "Pincode",
  PINCODE: "pincode",
  PINCODE_PLACEHOLDER: "Enter pincode",

  //Customer STatus
  CUSTOMER_STATUS_LABEL: "Customer Status",
  CUSTOMER_STATUS: "is_active",
  CUSTOMER_STATUS_OPTIONS: [
    { value: "1", label: "Active" },
    { value: "0", label: "Inactive" },
  ],
});

//Discounts Constants
export const DISCOUNTS_CONSTANTS = Object.freeze({
  DISCOUNT_NAVIGATE: "/admin/discounts",

  DISCOUNT_HEADER: "Manage Discounts",

  ADD_HEADER: "Add Discounts",
  EDIT_HEADER: "Edit Discount",
  DELETE_DISCOUNT: "Delete Discount",
  DELETE_DISCOUNT_MSG: "Are you sure you want to make this discount inactive?",

  PATH: "discounts",
  LABEL: "Discount",

  DISCOUNT_COLS: [
    "#",
    "Discount Code",
    "Discount type",
    "Discount value",
    "Status",
    "Actions",
  ],
  CONTENT_HEADER: "Add Discount",

  //form fields
  CARD_TITLE_1: "Discount Details",
  //Discount Code
  DISCOUNT_CODE_LABEL: "Discount Code",
  DISCOUNT_CODE: "discount_code",
  DISCOUNT_CODE_PLACEHOLDER: "Enter discount code",
  //Discount Applicable To
  DISCOUNT_APPLICABLE_LABEL: "Discount Applicable",
  DISCOUNT_APPLICABLE: "discount_applicable",

  DISCOUNT_STATUS: "is_active",
  DISCOUNT_STATUS_LABEL: "Discount Status",

  //Discount Auto Apply
  DISCOUNT_AUTO_APPLY_LABEL: "Discount Auto Apply",
  DISCOUNT_AUTO_APPLY: "discount_auto_apply",
  DISCOUNT_AUTO_APPLY_OPTIONS: [
    { value: 1, label: "Yes" },
    { value: 0, label: "No" },
  ],
  //Exclude Products
  EXCLUDE_PRODUCTS_LABEL: "Exclude Products",
  EXCLUDE_PRODUCTS: "exclude_products_with_discount",
  EXCLUDE_PRODUCTS_OPTIONS: [
    { value: 1, label: "Yes" },
    { value: 0, label: "No" },
  ],
  //Discount Type
  DISCOUNT_TYPE_LABEL: "Discount Type",
  DISCOUNT_TYPE: "discount_type",
  DISCOUNT_TYPE_OPTIONS: [
    { value: 0, label: "Percentage" },
    { value: 1, label: "Fixed Amount" },
    {
      value: 2,
      label: "Buy-One-Get-One: Primary Product",
    },
    {
      value: 3,
      label: "Buy-One-Get-One: Free Product",
    },
  ],
  //Discount Value
  DISCOUNT_VALUE_LABEL: "Discount Value",
  DISCOUNT_VALUE: "discount_value",
  PERCENTAGE: "Percentage",
  DISCOUNT_VALUE_PERCENTAGE: "Enter percentage value",
  DISCOUNT_VALUE_FIXED_AMOUNT: "Enter amount value",
  //Branches
  BRANCHES_LABEL: "Branches",
  BRANCHES: "stores",

  CARD_TITLE_2: "Discount Applies To Details",
  DISCOUNT_APPLIES_TO: "discount_applies_to",
  //Discount Category
  DISCOUNT_CATEGORY_LABEL: "Main Category",
  DISCOUNT_CATEGORY: "categories",
  //Discount Sub Category
  DISCOUNT_SUB_CATEGORY_LABEL: "Sub Category",
  DISCOUNT_SUB_CATEGORY: "sub_categories",
  //Discount Brand
  DISCOUNT_BRAND_LABEL: "Brand",
  DISCOUNT_BRAND: "brands",
  //Discount Product
  DISCOUNT_PRODUCT_LABEL: "Product",
  DISCOUNT_PRODUCT: "products",

  CARD_TITLE_3: "Discount Usage Details",
  //Minimum Requirement
  MINIMUM_REQUIREMENT_LABEL: "Minimum Requirement",
  MINIMUM_REQUIREMENT: "minimum_requirement_type",
  MINIMUM_REQUIREMENT_OPTIONS: [
    { value: 0, label: "None" },
    { value: 1, label: "Purchase Amount Range" },
    { value: 2, label: "Quantity Range" },
  ],
  //Minimum Amount
  MINIMUM: "minimum_range",
  MAXIMUM: "maximum_range",
  MINIMUM_REQUIREMENT_AMOUNT: "Purchase Amount Range",
  MINIMUM_AMOUNT_LABEL: "Minimum Amount",
  MINIMUM_AMOUNT_PLACEHOLDER: "Enter minimum amount",
  MAXIMUM_AMOUNT_LABEL: "Maximum Amount",
  MAXIMUM_AMOUNT_PLACEHOLDER: "Enter maximum amount",

  //Minimum Quantity
  MINIMUM_REQUIREMENT_QUANTITY: "Quantity Range",
  MINIMUM_QUANTITY_LABEL: "Minimum Quantity",
  MINIMUM_QUANTITY_PLACEHOLDER: "Enter minimum quantity",
  MAXIMUM_QUANTITY_LABEL: "Maximum Quantity",
  MAXIMUM_QUANTITY_PLACEHOLDER: "Enter maximum quantity",
  //CUStomer ELigibility
  CUSTOMER_ELIGIBILITY_LABEL: "Customer Eligibility",
  CUSTOMER_ELIGIBILITY: "customer_eligibility",
  CUSTOMER_ELIGIBILITY_OPTIONS: [
    { value: 0, label: "None" },
    { value: 1, label: "All Customers" },
    { value: 2, label: "Specific Customers" },
  ],
  //Coupon usage
  COUPON_USAGE_LABEL: "Coupon Usage",
  COUPON_USAGE: "coupon_usage",
  COUPON_USAGE_OPTIONS: [
    { value: 1, label: "One Time" },
    { value: 2, label: "One Time Per Customer" },
    {
      value: 3,
      label: "Number of Times Per Customer",
    },
  ],
  COUPON_USAGE_NUMBER_OF_TIMES: "coupon_usage_time",
  COUPON_USAGE_NUMBER_OF_TIMES_LABEL: "Number of Times Per Customer",
  COUPON_USAGGE_CHECK: "Number of time per customer",
  COUPON_USAGE_NUMBER_OF_TIMES_PLACEHOLDER: "Enter number of times",

  //Coupon Validity
  COUPON_START_DATE: "coupon_start_date",
  COUPON_START_DATE_LABEL: "Coupon Start Date",
  COUPON_END_DATE: "coupon_end_date",
  COUPON_END_DATE_LABEL: "Coupon End Date",
});

//Employee Constants
export const EMPLOYEE_CONSTANTS = Object.freeze({
  EMPLOYEE_NAVIGATE: "/admin/employee",
  EMPLOYEE_HEADER: "Manage Employees",
  PATH: "employee",
  LABEL: "Employee",

  EMPLOYEE_COLS: ["#", "Username", "Email", "Stores", "Status", "Actions"],
  ADD_HEADER: "Add Employee",
  EDIT_HEADER: "Edit Employee",
  DELETE_EMPLOYEE: "Delete Employee",
  DELETE_EMPLOYEE_MSG: "Are you sure you want to make this employee inactive?",
  //form fields
  CARD_TITLE_1: "Employee Details",
  //Employee UserName
  EMPLOYEE_USERNAME_LABEL: "Username",
  EMPLOYEE_USERNAME: "username",
  EMPLOYEE_USERNAME_PLACEHOLDER: "Enter employee username",
  //Employee Password
  EMPLOYEE_PASSWORD_LABEL: "Password",
  EMPLOYEE_PASSWORD: "password",
  EMPLOYEE_PASSWORD_PLACEHOLDER: "Enter employee password",
  //Employee First Name
  EMPLOYEE_FIRST_NAME_LABEL: "First Name",
  EMPLOYEE_FIRST_NAME: "first_name",
  EMPLOYEE_FIRST_NAME_PLACEHOLDER: "Enter employee first name",
  //Employee Last Name
  EMPLOYEE_LAST_NAME_LABEL: "Last Name",
  EMPLOYEE_LAST_NAME: "last_name",
  EMPLOYEE_LAST_NAME_PLACEHOLDER: "Enter employee last name",
  //Employee Data Joined
  EMPLOYEE_DATE_JOINED_LABEL: "Date Joined",
  EMPLOYEE_DATE_JOINED: "date_joined",
  EMPLOYEE_DATE_JOINED_PLACEHOLDER: "Enter employee date joined",
  //Employee Email
  EMPLOYEE_EMAIL_LABEL: "Email",
  EMPLOYEE_EMAIL: "email",
  EMPLOYEE_EMAIL_PLACEHOLDER: "Enter employee email",
  //Employee Mobile Number
  EMPLOYEE_MOBILE_NUMBER_LABEL: "Mobile Number",
  EMPLOYEE_MOBILE_NUMBER: "mobile_number",
  EMPLOYEE_MOBILE_NUMBER_PLACEHOLDER: "Enter employee mobile number",
  //Employee Pan
  EMPLOYEE_PAN_LABEL: "PAN No.",
  EMPLOYEE_PAN: "pan",
  EMPLOYEE_PAN_PLACEHOLDER: "Enter PAN number",
  //Employee Role
  ROLE: "role",
  ROLE_LABEL: "Role",
  //Employee Branch
  STORE: "stores",
  STORE_LABEL: "Branch/Store",
  // //Employee Department
  // DEPARTMENT: "departments",
  // DEPARTMENT_LABEL: "Department",
  //EMployee Salary
  EMPLOYEE_SALARY_LABEL: "Salary",
  EMPLOYEE_SALARY: "salary",
  EMPLOYEE_SALARY_PLACEHOLDER: "Enter employee salary",
  //Employee Commission
  EMPLOYEE_COMMISSION_LABEL: "Commission",
  EMPLOYEE_COMMISSION: "commissions",
  EMPLOYEE_COMMISSION_PLACEHOLDER: "Enter employee commission",
  //EMployee Wages
  EMPLOYEE_WAGES_LABEL: "Wages",
  EMPLOYEE_WAGES: "extra_wages",
  EMPLOYEE_WAGES_PLACEHOLDER: "Enter employee wages",
  // //Employee scanned Doc
  // EMPLOYEE_DOCS: "scannedDocuments",
  // EMPLOYEE_DOCS_LABEL: "Scanned Documents",
  //Extra Comments:
  EXTRA_COMMENTS_LABEL: "Extra Comments",
  EXTRA_COMMENTS: "comments",
  EXTRA_COMMENTS_PLACEHOLDER: "Enter extra comments",

  IS_ACTIVE: "is_active",
  IS_ACTIVE_LABEL: "Employee Status",
  IS_ACTIVE_OPTIONS: [
    { value: "1", label: "Active" },
    { value: "0", label: "Inactive" },
  ],

  CARD_TITLE_2: "Employee Address Details",
  //Employee Address
  EMPLOYEE_ADDRESS: "address",
  ADDRESS: "address",
  ADDRESS_LINE_1_LABEL: "Address Line 1",
  ADDRESS_LINE_1: "address_line_1",
  ADDRESS_LINE_1_PLACEHOLDER: "Enter address line 1",
  ADDRESS_LINE_2_LABEL: "Address Line 2",
  ADDRESS_LINE_2: "address_line_2",
  ADDRESS_LINE_2_PLACEHOLDER: "Enter address line 2",
  //Employee City
  CITY_LABEL: "City",
  CITY: "city",
  CITY_PLACEHOLDER: "Enter employee city",
  //Employee STate
  STATE_LABEL: "State",
  STATE: "state",
  STATE_PLACEHOLDER: "Enter employee state",
  //Employee Pincode
  PINCODE_LABEL: "Pincode",
  PINCODE: "pincode",
  PINCODE_PLACEHOLDER: "Enter employee pincode",
  //Employee Country
  COUNTRY_LABEL: "Country",
  COUNTRY: "country",
  COUNTRY_PLACEHOLDER: "Enter employee country",

  CARD_TITLE_3: "Employee Bank Details",
  //Employee Account Holder Name
  EMPLOYEE_ACCOUNT_HOLDER_NAME_LABEL: "Account Holder Name",
  EMPLOYEE_ACCOUNT_HOLDER_NAME: "account_holder",
  EMPLOYEE_ACCOUNT_HOLDER_NAME_PLACEHOLDER: "Enter Account Holder Name",
  //EMployee ACcount Number
  EMPLOYEE_ACCOUNT_NUMBER_LABEL: "Account Number",
  EMPLOYEE_ACCOUNT_NUMBER: "account_no",
  EMPLOYEE_ACCOUNT_NUMBER_PLACEHOLDER: "Enter Account Number",
  //Employee Bank
  EMPLOYEE_BANK_LABEL: "Employee Bank",
  EMPLOYEE_BANK: "bank_name",
  EMPLOYEE_BANK_PLACEHOLDER: "Enter Bank Name",
  //Employee Branch
  EMPLOYEE_BRANCH_LABEL: "Employee Branch",
  EMPLOYEE_BRANCH: "bank_branch",
  EMPLOYEE_BRANCH_PLACEHOLDER: "Enter Employee Branch",
  //Employee IFSC Code
  EMPLOYEE_IFSC_CODE_LABEL: "IFSC Code",
  EMPLOYEE_IFSC_CODE: "ifsc_code",
  EMPLOYEE_IFSC_CODE_PLACEHOLDER: "Enter IFSC Code",
});

//Payment Terms Constants
export const PAYMENT_TERMS_CONSTANTS = Object.freeze({
  PAYMENT_TERMS_COLS: [
    "#",
    "Payment Term Name",
    "Payment Term Type",
    "Payment Term Days",
    "Status",
    "Actions",
  ],
  PAYMENT_TERMS_HEADER: "Manage Payment Terms",
  ADD_HEADER: "Add Payment Term",
  EDIT_HEADER: "Edit Payment Term",
  DELETE_TERM: "Delete Term",
  DELETE_TERM_MSG: "Are you sure you want to make this term inactive?",

  PATH: "",
  LABEL: "Payment Term",

  //form fields
  CARD_TITLE_1: "Payment Term Details",
  //Payment Term Name
  PAYMENT_TERM_TYPE_LABEL: "Payment Term Type",
  PAYMENT_TERM_TYPE: "term_type",
  PAYMENT_TERM_OPTIONS: [
    { value: "onspot_payment", label: "Onspot Payment" },
    { value: "credit", label: "Credit" },
  ],
  //Paymenr NAme
  PAYMENT_TERM_NAME_LABEL: "Payment Term Name",
  PAYMENT_TERM_NAME: "term_name",
  PAYMENT_TERM_NAME_PLACEHOLDER: "Enter payment term name",
  //Payment Term Days
  PAYMENT_TERM_DAYS_LABEL: "Payment Term Days",
  PAYMENT_TERM_DAYS: "term_days_after_delivery",
  PAYMENT_TERM_DAYS_PLACEHOLDER: "Enter payment term days",
  //Payment Term Status
  PAYMENT_TERM_STATUS_LABEL: "Payment Term Status",
  PAYMENT_TERM_STATUS: "is_active",
  PAYMENT_TERM_STATUS_OPTIONS: [
    { value: "1", label: "Active" },
    { value: "0", label: "Inactive" },
  ],
});

//Paymenr Modes Constants
export const PAYMENT_MODES_CONSTANTS = Object.freeze({
  PAYMENT_MODES_HEADER: "Manage Payment Modes",
  ADD_HEADER: "Add Payment Mode",
  EDIT_HEADER: "Edit Payment Mode",
  DELETE_MODE: "Delete Mode",
  DELETE_MODE_MSG: "Are you sure you want to make this mode inactive?",

  PATH: "",
  LABEL: "Payment Mode",

  PAYMENT_MODES_COLS: ["#", "Payment Mode Name", "Status", "Actions"],

  //form fields
  CARD_TITLE_1: "Payment Mode Details",
  //Payment Mode Name
  PAYMENT_MODE_NAME_LABEL: "Payment Mode Name",
  PAYMENT_MODE_NAME: "payment_mode",
  PAYMENT_MODE_NAME_PLACEHOLDER: "Enter payment mode name",
  //Payment Mode Status
  PAYMENT_MODE_STATUS_LABEL: "Payment Mode Status",
  PAYMENT_MODE_STATUS: "is_active",
  PAYMENT_MODE_STATUS_OPTIONS: [
    { value: "1", label: "Active" },
    { value: "0", label: "Inactive" },
  ],
});

//Products
export const PRODUCTS_CONSTANTS = Object.freeze({
  PRODUCTS_HEADER: "Manage Products",

  ADD_HEADER: "Add Product",
  EDIT_HEADER: "Edit Product",
  DELETE_PRODUCT: "Delete Product",
  DELETE_PRODUCT_MSG: "Are you sure you want to make this product inactive?",

  PATH: "products",
  LABEL: "Product",

  PRODUCTS_COLS: [
    "#",
    "Name",
    "Product Code",
    "MRP",
    "Selling Price",
    "UOM",
    "Status",
    "Actions",
  ],

  BARCODE_COLS: [
    "Barcode",
    //  "Barcode Type",
    "Actions",
  ],

  NUTRITION_COLS: [
    "Nutrient Name",
    "Nutrient Value",
    "Nutrient UOM",
    "Actions",
  ],
  PRICING_COLS: [
    "Store",
    "MRP",
    "Selling Discount",
    "Ceil",
    "Selling Price",
    "MSQ",
  ],
  PRODUCT_NAVIGATE: "/admin/products",

  //form fields
  CARD_TITLE_1: "Product Details",
  //Product Name
  PRODUCT_NAME_LABEL: "Print Name",
  PRODUCT_NAME: "print_name",
  PRODUCT_NAME_PLACEHOLDER: "Enter product name",
  //Product Net Weight
  PRODUCT_NET_WEIGHT_LABEL: "Net Weight",
  PRODUCT_NET_WEIGHT: "net_weight",
  PRODUCT_NET_WEIGHT_PLACEHOLDER: "Enter product net weight",
  //Product Code
  PRODUCT_CODE_LABEL: "Product Code",
  PRODUCT_CODE: "product_code",
  PRODUCT_CODE_PLACEHOLDER: "Enter product code",
  PRODUCT_CODE_ALT_PLACEHOLDER: "Product Code will be auto generated",
  //Product Category
  PRODUCT_CATEGORY_LABEL: "Category",
  PRODUCT_CATEGORY: "category",
  //Product Sub Category
  PRODUCT_SUB_CATEGORY_LABEL: "Sub Category",
  PRODUCT_SUB_CATEGORY: "sub_category",
  //Product HSN Codes
  PRODUCT_HSN_CODES_LABEL: "HSN Codes",
  PRODUCT_HSN_CODES: "hsn_code",

  PRODUCT_TAX_LABEL: "Tax",
  // PRODUCT_TAX: "tax",
  PRODUCT_TAX: "tax",
  //Product UOM
  PRODUCT_UOM_LABEL: "Net Weight UOM",
  PRODUCT_UOM: "uom",
  //Product Selling UOM
  PRODUCT_SELLING_UOM_LABEL: "UOM",
  PRODUCT_SELLING_UOM: "selling_uom",
  //Product Expiration Date
  PRODUCT_EXPIRATION_DAYS_LABEL: "Expiration Days",
  PRODUCT_EXPIRATION_DAYS: "expiration_days",
  PRODUCT_EXPIRATION_DAYS_PLACEHOLDER: "Enter product expiration days",
  PRODUCT_STATUS_LABEL: "Status",
  PRODUCT_STATUS: "is_active",
  PRODUCT_STATUS_OPTIONS: [
    { value: "1", label: "Active" },
    { value: "0", label: "Inactive" },
  ],
  //Product Brands
  PRODUCT_BRAND_LABEL: "Brand",
  PRODUCT_BRAND: "brand",

  //Product Ingredients
  PRODUCT_INGREDIENTS_LABEL: "Ingredients",
  PRODUCT_INGREDIENTS: "ingredients",
  PRODUCT_INGREDIENTS_PLACEHOLDER: "Enter product ingredients",

  //Product Barcode
  PRODUCT_BARCODE_LABEL: "Product Barcode",
  PRODUCT_BARCODE: "barcode",

  //Product Barcode Type
  PRODUCT_BARCODE_TYPE_LABEL: "Product Barcode Type",
  PRODUCT_BARCODE_TYPE: "barcode_type",

  UPC_BARCODE_ID: 4,
  BARCODE_LOADING_STATE: "generateNewBarcode",

  //Product Description
  PRODUCT_DESCRIPTION_LABEL: "Description",
  PRODUCT_DESCRIPTION: "description",
  PRODUCT_DESCRIPTION_PLACEHOLDER: "Enter product description",
  CARD_TITLE_BARCODE: "Barcode Details",
  BARCODE_MAPPING: "barcode_mapping",
  BARCODE_TYPE: "barcode_type",
  BARCODE_TYPE_LABEL: "Barcode Type",
  BARCODE_STORE: "store",

  BARCODE: "product_code",
  BARCODE_LABEL: "Barcode",
  BARCODE_PLACEHOLDER: "Enter barcode",

  CARD_TITLE_2: "Product Nutrition Details",
  PRODUCT_NUTRITIONS: "nutrition_details",
  NUTRIENT_NAME: "nutrient",
  NUTRIENT_NAME_PLACEHOLDER: "Enter nutrient name",
  NUTRIENT_NUMERIC_VALUE: "nutrition_numeric_value",
  NUTRIENT_VALUE: "nutrition_value",
  NUTRIENT_VALUE_PLACEHOLDER: "Enter nutrient value",
  NUTRIENT_UOM: "uom",

  CARD_TITLE_3: "Product Pricing Details",
  //Product Pricing
  PRODUCT_PRICING: "price",
  PRODUCT_STORE_PRICING: "store_prices",
  //Product Pricing Store
  PRODUCT_PRICING_STORE_PLACEHOLDER: "Store",
  PRODUCT_PRICING_STORE: "store",

  BATCH_NAME_LABEL: "Batch Name",
  BATCH_NAME: "batch_name",
  BATCH_NAME_PLACEHOLDER: "Enter batch name",

  //Product Pricing MRP
  PRODUCT_PRICING_MRP: "mrp",
  PRODUCT_PRICING_MRP_PLACEHOLDER: "MRP",
  //Product Pricing Selling Discount
  PRODUCT_PRICING_SELLING_DISCOUNT: "selling_discount",
  SELLING_DISCOUNT_PERCENTAGE: "%",
  SELLING_DISCOUNT_FLAT: "flat",
  PRODUCT_PRICING_DISCOUNT_TYPE: "selling_discount_type",
  //Product Pricing Selling Price
  PRODUCT_PRICING_SELLING_PRICE: "selling_price",
  PRODUCT_PRICING_SELLING_PRICE_PLACEHOLDER: "Selling Price",
  //Product Pricing Ceil
  PRODUCT_PRICING_CEIL: "ceil",
  PRODUCT_PRICING_CEIL_PLACEHOLDER: "Ceil",
  //Product Pricing MSQ
  PRODUCT_PRICING_MSQ: "minimum_selling_quantity",
  PRODUCT_PRICING_MSQ_PLACEHOLDER: "MSQ",

  CARD_TITLE_4: "Product MSQ Details",
  //Product MSQ
  PRODUCT_MSQ: "minimum_selling_quantity",
  //Product MSQ Store
  PRODUCT_MSQ_STORE_PLACEHOLDER: "Store",
  PRODUCT_MSQ_STORE: "store",
  //Product MSQ Days
  PRODUCT_MSQ_DAYS: "days",
  PRODUCT_MSQ_DAYS_PLACEHOLDER: "MSQ Days",
  //Product MSQ Actions

  PRODUCT_MSQ_ACTIONS_PLACEHOLDER: "Actions",
});

//Product Category
export const PRODUCT_CATEGORY_CONSTANTS = Object.freeze({
  PRODUCT_CATEGORY_HEADER: "Manage Product Category",
  ADD_HEADER: "Add Product Category",
  EDIT_HEADER: "Edit Product Category",
  DELETE_CATEGORY: "Delete Category",
  DELETE_CATEGORY_MSG: "Are you sure you want to make this category inactive?",

  PATH: "",
  LABEL: "Product Category",

  PRODUCT_CATEGORY_COLS: [
    "#",
    "Category Name",
    "Category Code",
    "Status",
    "Actions",
  ],

  //form fields
  CARD_TITLE_1: "Product Category Details",
  //Product Category Name
  PRODUCT_CATEGORY_NAME_LABEL: "Product Category Name",
  PRODUCT_CATEGORY_NAME: "category_name",
  PRODUCT_CATEGORY_NAME_PLACEHOLDER: "Enter product category name",
  //Product Category Code
  PRODUCT_CATEGORY_CODE_LABEL: "Product Category Code",
  PRODUCT_CATEGORY_CODE: "category_code",
  PRODUCT_CATEGORY_CODE_PLACEHOLDER: "Enter product category code",
  //Product Category Description
  PRODUCT_CATEGORY_DESCRIPTION_LABEL: "Product Category Description",
  PRODUCT_CATEGORY_DESCRIPTION: "description",
  PRODUCT_CATEGORY_DESCRIPTION_PLACEHOLDER:
    "Enter product category description",
  //Product Department
  PRODUCT_DEPARTMENT_LABEL: "Product Department",
  PRODUCT_DEPARTMENT: "department",
  //Product Category Status
  PRODUCT_CATEGORY_STATUS_LABEL: "Product Category Status",
  PRODUCT_CATEGORY_STATUS: "is_active",
  PRODUCT_CATEGORY_STATUS_OPTIONS: [
    { value: "1", label: "Active" },
    { value: "0", label: "Inactive" },
  ],
});

//Product Sub Category
export const PRODUCT_SUB_CATEGORY_CONSTANTS = Object.freeze({
  PRODUCT_SUB_CATEGORY_HEADER: "Manage Product Sub Category",

  ADD_HEADER: "Add Sub-Category",
  EDIT_HEADER: "Edit Sub-Category",
  DELETE_SUB_CATEGORY: "Delete Sub-Category",
  DELETE_SUB_CATEGORY_MSG:
    "Are you sure you want to make this sub-category inactive?",

  PATH: "",
  LABEL: "Product Sub Category",

  PRODUCT_SUB_CATEGORY_COLS: [
    "#",
    "Sub-Category Name",
    "Parent-Category",
    "Status",
    "Actions",
  ],

  //form fields
  CARD_TITLE_1: "Product Sub Category Details",
  //Product Sub Category Name
  PRODUCT_SUB_CATEGORY_NAME_LABEL: "Product Sub Category Name",
  PRODUCT_SUB_CATEGORY_NAME: "sub_category_name",
  PRODUCT_SUB_CATEGORY_NAME_PLACEHOLDER: "Enter product sub category name",
  //Product Sub Category Code
  PRODUCT_SUB_CATEGORY_CODE_LABEL: "Product Sub Category Code",
  PRODUCT_SUB_CATEGORY_CODE: "sub_category_code",
  PRODUCT_SUB_CATEGORY_CODE_PLACEHOLDER: "Enter product sub category code",
  //Product Sub Category Description
  PRODUCT_SUB_CATEGORY_DESCRIPTION_LABEL: "Product Sub Category Description",
  PRODUCT_SUB_CATEGORY_DESCRIPTION: "description",
  PRODUCT_SUB_CATEGORY_DESCRIPTION_PLACEHOLDER:
    "Enter product sub category description",
  //Product Category
  PARENT_CATEGORY_LABEL: "Parent Category",
  PARENT_CATEGORY: "categories",
  //Product Sub Category Status
  PRODUCT_SUB_CATEGORY_STATUS_LABEL: "Product Sub Category Status",
  PRODUCT_SUB_CATEGORY_STATUS: "is_active",
  PRODUCT_SUB_CATEGORY_STATUS_OPTIONS: [
    { value: "1", label: "Active" },
    { value: "0", label: "Inactive" },
  ],
});

//Product Brand
export const PRODUCT_BRAND_CONSTANTS = Object.freeze({
  PRODUCT_BRAND_HEADER: "Manage Product Brand",
  ADD_HEADER: "Add Product Brand",
  EDIT_HEADER: "Edit Product Brand",
  DELETE_BRANDS: "Delete Brand",
  DELETE_BRANDS_MSG: "Are you sure you want to make this brand inactive?",

  PATH: "",
  LABEL: "Product Brand",

  PRODUCT_BRAND_COLS: ["#", "Brand Name", "Brand Code", "Status", "Actions"],

  //form fields
  CARD_TITLE_1: "Product Brand Details",
  //Product Brand Name
  PRODUCT_BRAND_NAME_LABEL: "Product Brand Name",
  PRODUCT_BRAND_NAME: "brand_name",
  PRODUCT_BRAND_NAME_PLACEHOLDER: "Enter product brand name",
  //Product Brand Code
  PRODUCT_BRAND_CODE_LABEL: "Product Brand Code",
  PRODUCT_BRAND_CODE: "brand_code",
  PRODUCT_BRAND_CODE_PLACEHOLDER: "Enter product brand code",
  //Product Brand Description
  PRODUCT_BRAND_DESCRIPTION_LABEL: "Product Brand Description",
  PRODUCT_BRAND_DESCRIPTION: "brand_description",
  PRODUCT_BRAND_DESCRIPTION_PLACEHOLDER: "Enter product brand description",

  //Product Brand Status
  PRODUCT_BRAND_STATUS_LABEL: "Product Brand Status",
  PRODUCT_BRAND_STATUS: "is_active",
  PRODUCT_BRAND_STATUS_OPTIONS: [
    { value: "1", label: "Active" },
    { value: "0", label: "Inactive" },
  ],
});

//Product Tax
export const PRODUCT_TAX_CONSTANTS = Object.freeze({
  PRODUCT_TAX_HEADER: "Manage Product Tax",

  ADD_HEADER: "Add Product Tax",
  EDIT_HEADER: "Edit Product Tax",
  DELETE_TAX: "Delete Tax",
  DELETE_TAX_MSG: "Are you sure you want to make this tax inactive?",

  PATH: "",
  LABEL: "Product Tax",

  PRODUCT_TAX_COLS: ["#", "Tax Name", "Tax Rate", "Status", "Actions"],

  //form fields
  CARD_TITLE_1: "Product Tax Details",
  //Product Tax Name
  PRODUCT_TAX_NAME_LABEL: "Product Tax Name",
  PRODUCT_TAX_NAME: "tax_name",
  PRODUCT_TAX_NAME_PLACEHOLDER: "Enter product tax name",
  //Product Tax Code
  PRODUCT_TAX_RATE_LABEL: "Product Tax Rate",
  PRODUCT_TAX_RATE: "tax_rate",
  PRODUCT_TAX_RATE_PLACEHOLDER: "Enter product tax rate",
  //Product Tax Description
  PRODUCT_TAX_HSN_CODES_LABEL: "Product Tax HSN Codes",
  PRODUCT_TAX_HSN_CODES: "hsn_codes",
  //Product Tax Status
  PRODUCT_TAX_STATUS_LABEL: "Product Tax Status",
  PRODUCT_TAX_STATUS: "is_active",
  PRODUCT_TAX_STATUS_OPTIONS: [
    { value: "1", label: "Active" },
    { value: "0", label: "Inactive" },
  ],
});

//Product Groups
export const PRODUCT_GROUPS_CONSTANTS = Object.freeze({
  PRODUCT_GROUPS_HEADER: "Manage Product Groups",

  ADD_HEADER: "Add Group",
  EDIT_HEADER: "Edit Group",
  DELETE_GRP: "Delete Product Group",
  DELETE_GRP_MSG: "Are you sure you want to make this product group inactive?",

  PATH: "products/groups",
  LABEL: "Product Group",

  PRODUCT_GROUPS_COLS: [
    "#",
    "Print Hamper Name",
    "Hamper Code",
    "Status",
    "Actions",
  ],
  PRODUCT_GROUP_NAVIGATE: "/admin/products/groups",

  //form fields
  CARD_TITLE_1: "Product Group Details",
  //Hamper Name
  PRODUCT_GROUP_PRINT_HAMPER_NAME_LABEL: "Print Hamper Name",
  PRODUCT_GROUP_PRINT_HAMPER_NAME: "print_hamper_name",
  PRODUCT_GROUP_PRINT_HAMPER_NAME_PLACEHOLDER: "Enter print hamper name",
  //Hamper Name
  PRODUCT_GROUP_HAMPER_NAME_LABEL: "Hamper Name",
  PRODUCT_GROUP_HAMPER_NAME: "hamper_name",
  PRODUCT_GROUP_HAMPER_NAME_PLACEHOLDER: "Enter hamper name",
  //Hamper Code
  PRODUCT_GROUP_HAMPER_CODE_LABEL: "Hamper Code",
  PRODUCT_GROUP_HAMPER_CODE: "hamper_code",
  PRODUCT_GROUP_HAMPER_CODE_PLACEHOLDER: "Enter hamper code",
  //Product Category
  PRODUCT_GROUP_CATEGORY_LABEL: "Product Category",
  PRODUCT_GROUP_CATEGORY: "category",
  //Product Sub Category
  PRODUCT_GROUP_SUB_CATEGORY_LABEL: "Product Sub Category",
  PRODUCT_GROUP_SUB_CATEGORY: "sub_category",
  //Product Barcode
  BARCODE_HEADER: "Barcode Details",
  PRODUCT_GROUP_BARCODE_PLACEHOLDER: "Enter barcode",
  PRODUCT_GROUP_BARCODE_LABEL: "Product Barcode",
  PRODUCT_GROUP_BARCODE: "barcodes",
  BARCODE: "barcode",
  //Product Barcode Type
  PRODUCT_GROUP_BARCODE_TYPE_LABEL: "Product Barcode Type",
  PRODUCT_GROUP_BARCODE_TYPE: "barcode_type",
  //Product Group Status
  PRODUCT_GROUP_STATUS_LABEL: "Product Group Status",
  PRODUCT_GROUP_STATUS: "is_active",
  //Product HSN Codes
  PRODUCT_GROUP_HSN_CODES_LABEL: "Product HSN Codes",
  PRODUCT_GROUP_HSN_CODES: "hsn",
  PRODUCT_GROUP_HSN_CODES_PLACEHOLDER: "Enter HSN Codes",
  //Product Description
  PRODUCT_GROUP_DESCRIPTION_LABEL: "Product Description",
  PRODUCT_GROUP_DESCRIPTION: "description",
  PRODUCT_GROUP_DESCRIPTION_PLACEHOLDER: "Enter product description",

  CARD_TITLE_2: "Product Details",
  PRODUCT_GROUP_PRODUCTS: "products",
  PRODUCT_GROUP_ADDITIONAL_CHARGES: "additional_charges",
  PRODUCT_GROUP_ADDITIONAL_CHARGES_LABEL: "Additional Charges",
  PRODUCT_GROUP_ADDITIONAL_CHARGES_PLACEHOLDER: "Enter additional charges",
  //Product List
  PRODUCT_NAME: "product",
  PRODUCT_NAME_PLACEHOLDER: "Enter product name",
  PRODUCT_BATCH: "batch",
  PRODUCT_NET_WEIGHT: "net_weight",
  PRODUCT_TOTAL: "total_amount",
  PRODUCT_BATCH_PLACEHOLDER: "Select product batch",
  PRODUCT_QUANTITY: "quantity",
  PRODUCT_TAX: "tax",
  PRODUCT_TAX_PLACEHOLDER: "Select product tax",
  PRODUCT_QUANTITY_PLACEHOLDER: "Enter product quantity",

  CARD_TITLE_3: "Pricing Details",
  //Product Pricing
  PRODUCT_GROUP_PRICING: "store_price",
  //Product Pricing Store
  PRODUCT_GROUP_PRICING_STORE_PLACEHOLDER: "Store",
  PRODUCT_GROUP_PRICING_STORE: "store",
  //Product Pricing MRP
  PRODUCT_GROUP_PRICING_MRP: "mrp",
  PRODUCT_GROUP_PRICING_MRP_PLACEHOLDER: "MRP",
  //Product Pricing Selling Discount
  PRODUCT_GROUP_PRICING_SELLING_DISCOUNT: "selling_discount",
  PRODUCT_GROUP_PRICING_SELLING_DISCOUNT_PLACEHOLDER: "Selling Discount",
  //Product Pricing Selling Discount Type
  PRODUCT_GROUP_PRICING_SELLING_DISCOUNT_TYPE: "selling_discount_type",
  PRODUCT_GROUP_PRICING_SELLING_DISCOUNT_TYPE_PLACEHOLDER: "Discount Type",
  //Product Pricing Ceil
  PRODUCT_GROUP_PRICING_CEIL: "ceil",
  PRODUCT_GROUP_PRICING_CEIL_PLACEHOLDER: "Ceil",

  //Product Pricing Selling Price
  PRODUCT_GROUP_PRICING_SELLING_PRICE: "selling_price",
  PRODUCT_GROUP_PRICING_SELLING_PRICE_PLACEHOLDER: "Selling Price",
  //Product Pricing MSQ
  PRODUCT_GROUP_PRICING_MSQ: "msq",
  PRODUCT_GROUP_PRICING_MSQ_PLACEHOLDER: "MSQ",

  CARD_TITLE_4: "Product MSQ Details",
  PRODUCT_GROUP_MSQ: "minimum_selling_quantity",
  MSQ_STORE_LABEL: "Store",
  MSQ_STORE: "store",
  MSQ_DAYS_LABEL: "Days",
  MSQ_DAYS: "days",
  MSQ_ACTIONS_LABEL: "Actions",
});

//UOM
export const UOM_CONSTANTS = Object.freeze({
  UOM_HEADER: "Manage UOM",

  ADD_HEADER: "Add UOM",
  EDIT_HEADER: "Edit UOM",
  DELETE_UOM: "Delete UOM",
  DELETE_UOM_MSG: "Are you sure you want to make this uom inactive?",

  PATH: "",
  LABEL: "UOM",

  UOM_COLS: ["#", "UOM Name", "UOM Code", "Status", "Actions"],

  //form fields
  CARD_TITLE_1: "UOM Details",
  //UOM Name
  UOM_NAME_LABEL: "UOM Name",
  UOM_NAME: "uom_name",
  UOM_NAME_PLACEHOLDER: "Enter UOM name",
  //UOM Status
  UOM_STATUS_LABEL: "UOM Status",
  UOM_STATUS: "is_active",
  UOM_STATUS_OPTIONS: [
    { value: "1", label: "Active" },
    { value: "0", label: "Inactive" },
  ],
  //UOM Code
  UOM_CODE_LABEL: "UOM Code",
  UOM_CODE: "uom_code",
  UOM_CODE_PLACEHOLDER: "Enter UOM code",

  UOM_DECIMAL_PLACES_LABEL: "UOM Decimal Places",
  UOM_DECIMAL_PLACES: "uom_no_decimal_places",
  UOM_DECIMAL_PLACES_PLACEHOLDER: "Enter UOM decimal places",
});

//HSN Codes
export const HSN_CODES_CONSTANTS = Object.freeze({
  HSN_CODES_HEADER: "Manage HSN Codes",

  ADD_HEADER: "Add HSN Code",
  EDIT_HEADER: "Edit HSN Code",
  DELETE_HSN: "Delete HSN",
  DELETE_HSN_MSG: "Are you sure you want to make this hsn inactive?",

  PATH: "",
  LABEL: "HSN Code",

  HSN_CODES_COLS: ["#", "HSN Name", "HSN Code", "Status", "Actions"],

  //form fields
  CARD_TITLE_1: "HSN Code Details",
  //HSN Code
  HSN_CODE_LABEL: "HSN Code",
  HSN_CODE: "hsn_code",
  HSN_CODE_PLACEHOLDER: "Enter HSN code",
  //HSN Name
  HSN_NAME_LABEL: "HSN Name",
  HSN_NAME: "hsn_name",
  HSN_NAME_PLACEHOLDER: "Enter HSN name",
  //Product Category
  PRODUCT_CATEGORY_LABEL: "Product Category",
  PRODUCT_CATEGORY: "product_category",
  //HSN Code Status
  HSN_CODE_STATUS_LABEL: "HSN Code Status",
  HSN_CODE_STATUS: "is_active",
  HSN_CODE_STATUS_OPTIONS: [
    { value: "1", label: "Active" },
    { value: "0", label: "Inactive" },
  ],
});

//Store Department
export const STORE_DEPARTMENT_CONSTANTS = Object.freeze({
  STORE_DEPARTMENT_HEADER: "Manage Store Department",
  CONTENT_HEADER: "Add Store Department",

  PATH: "",
  LABEL: "Store Department",

  STORE_DEPARTMENT_COLS: [
    "#",
    "Name",
    "Head Name",
    "Head Number",
    "Head Email",
    "Status",
    "Actions",
  ],

  //form fields
  CARD_TITLE_1: "Store Department Details",
  //Department Name
  DEPARTMENT_NAME_LABEL: "Department Name",
  DEPARTMENT_NAME: "department_name",
  DEPARTMENT_NAME_PLACEHOLDER: "Enter department name",
  //Department Code
  DEPARTMENT_HEAD_NAME: "department_head_name",
  DEPARTMENT_HEAD_NAME_LABEL: "Department Head Name",
  DEPARTMENT_HEAD_NAME_PLACEHOLDER: "Enter department head name",
  //Department Head Number
  DEPARTMENT_HEAD_NUMBER: "department_head_contact_number",
  DEPARTMENT_HEAD_NUMBER_LABEL: "Department Head Number",
  DEPARTMENT_HEAD_NUMBER_PLACEHOLDER: "Enter department head number",
  //Department Head Email
  DEPARTMENT_HEAD_EMAIL: "department_head_email",
  DEPARTMENT_HEAD_EMAIL_LABEL: "Department Head Email",
  DEPARTMENT_HEAD_EMAIL_PLACEHOLDER: "Enter department head email",
  //Department Status
  DEPARTMENT_STATUS_LABEL: "Department Status",
  DEPARTMENT_STATUS: "is_active",
  DEPARTMENT_STATUS_OPTIONS: [
    { value: "1", label: "Active" },
    { value: "0", label: "Inactive" },
  ],
});

//Vendors
export const VENDORS_CONSTANTS = Object.freeze({
  VENDORS_HEADER: "Manage Vendors",

  ADD_HEADER: "Add Vendor",
  EDIT_HEADER: "Edit Vendor",

  STORE_PRODUCTS_HEADER: "Add Store Products",

  PATH: "vendors",
  LABEL: "Vendor",

  VENDORS_COLS: [
    "#",
    "Vendor Name",
    "Vendor Company Name",
    "Status",
    "Actions",
  ],
  VENDOR_NAVIGATE: "/admin/vendors",
  DAYS: [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ],

  //form fields
  CARD_TITLE_1: "Vendor Details",
  //Vendor Name
  VENDOR_NAME_LABEL: "Name",
  VENDOR_NAME: "name",
  VENDOR_NAME_PLACEHOLDER: "Enter vendor name",
  //Vendor Company Name
  VENDOR_COMPANY_NAME_LABEL: "Company Name",
  VENDOR_COMPANY_NAME: "company_name",
  VENDOR_COMPANY_NAME_PLACEHOLDER: "Enter vendor company name",
  //Vendor Email
  VENDOR_EMAIL_LABEL: "Email",
  VENDOR_EMAIL: "email",
  VENDOR_EMAIL_PLACEHOLDER: "Enter vendor email",
  //Vendor Status
  VENDOR_STATUS_LABEL: "Status",
  VENDOR_STATUS: "is_active",
  VENDOR_STATUS_OPTIONS: [
    { value: "1", label: "Active" },
    { value: "0", label: "Inactive" },
  ],
  //Vendor Mobile Number
  PHONE_NUMBER_HEADER: "Additional Phone Numbers",
  VENDOR_PHONE_NUMBER_LABEL: "Phone Number",
  VENDOR_PHONE_NUMBER: "phone_numbers",
  PHONE_NUMBER: "phone_number",
  VENDOR_PHONE_NUMBER_PLACEHOLDER: "Enter vendor phone number",
  //Vendor Whatsapp Number
  VENDOR_WHATSAPP_NUMBER_LABEL: "Whatsapp Ordering Number",
  VENDOR_WHATSAPP_NUMBER: "whatsapp_number",
  VENDOR_WHATSAPP_NUMBER_PLACEHOLDER: "Enter vendor whatsapp ordering number",
  //Vendor Code
  VENDOR_CODE_LABEL: "Code",
  VENDOR_CODE: "code",
  VENDOR_CODE_PLACEHOLDER: "Enter vendor code",
  //Vendor Type
  VENDOR_TYPE_LABEL: "Supplier Type",
  VENDOR_TYPE: "supplier_type",
  VENDOR_TYPE_OPTIONS: [
    { value: 1, label: "Supplier" },
    { value: 2, label: "Manufacturer" },
    { value: 3, label: "Distributor" },
    { value: 4, label: "Retailer" },
  ],
  //Vendor Apply TDS
  VENDOR_APPLY_TDS_LABEL: "Apply TDS",
  VENDOR_APPLY_TDS: "apply_tds",
  VENDOR_APPLY_TDS_OPTIONS: [
    { value: 1, label: "Yes" },
    { value: 0, label: "No" },
  ],
  //Vendor Payment Mode
  VENDOR_PAYMENT_MODE_LABEL: "Payment Mode",
  VENDOR_PAYMENT_MODE: "payment_mode",
  //Payment Terms
  VENDOR_PAYMENT_TERMS_LABEL: "Payment Terms",
  VENDOR_PAYMENT_TERMS: "payment_terms",
  //Vendor Credit Limit
  VENDOR_CREDIT_LIMIT_LABEL: "Credit Limit",
  VENDOR_CREDIT_LIMIT: "credit_limit",
  VENDOR_CREDIT_LIMIT_PLACEHOLDER: "Enter vendor credit limit",
  //Vendor GSTN
  VENDOR_GSTN_LABEL: "GSTN",
  VENDOR_GSTN: "gstn",
  VENDOR_GSTN_PLACEHOLDER: "Enter vendor GSTN",
  //Vendor PAN
  VENDOR_PAN_LABEL: "PAN",
  VENDOR_PAN: "pan",
  VENDOR_PAN_PLACEHOLDER: "Enter vendor PAN",
  //Vendor Remarks
  VENDOR_REMARKS_LABEL: "Remarks",
  VENDOR_REMARKS: "remarks",
  VENDOR_REMARKS_PLACEHOLDER: "Enter vendor remarks",

  CARD_TITLE_2: "Contact Person Details",
  //Vendor Contact Person Name
  VENDOR_CONTACT_PERSON_NAME_LABEL: "Contact Person Name",
  VENDOR_CONTACT_PERSON_NAME: "contact_person_name",
  VENDOR_CONTACT_PERSON_NAME_PLACEHOLDER: "Enter vendor contact person name",
  //Vendor Contact Person Number
  VENDOR_CONTACT_PERSON_NUMBER_LABEL: "Contact Person Number",
  VENDOR_CONTACT_PERSON_NUMBER: "contact_person_number",
  VENDOR_CONTACT_PERSON_NUMBER_PLACEHOLDER:
    "Enter vendor contact person number",
  //Vendor Contact Person Email
  VENDOR_CONTACT_PERSON_EMAIL_LABEL: "Contact Person Email",
  VENDOR_CONTACT_PERSON_EMAIL: "contact_person_email",
  VENDOR_CONTACT_PERSON_EMAIL_PLACEHOLDER: "Enter vendor contact person email",
  //Vendor commission
  VENDOR_COMMISSION_LABEL: "Commission",
  VENDOR_COMMISSION: "commission",
  VENDOR_COMMISSION_PLACEHOLDER: "Enter vendor commission",
  //Vendor Delivery Policy
  VENDOR_DELIVERY_POLICY_LABEL: "Delivery Policy",
  VENDOR_DELIVERY_POLICY: "delivery_policy",
  VENDOR_DELIVERY_POLICY_OPTIONS: [
    { value: 1, label: "Store Delivery" },
    { value: 0, label: "Pickup" },
  ],

  CARD_TITLE_3: "Vendor Bank Details",
  //Vendor Bank Details
  BANK_DETAILS: "bank_details",
  //ACount Number
  VENDOR_ACCOUNT_NUMBER_LABEL: "Account Number",
  VENDOR_ACCOUNT_NUMBER: "account_number",
  VENDOR_ACCOUNT_NUMBER_PLACEHOLDER: "Enter vendor account number",
  //Account Type
  VENDOR_ACCOUNT_TYPE_LABEL: "Account Type",
  VENDOR_ACCOUNT_TYPE: "account_type",
  VENDOR_ACCOUNT_TYPE_OPTIONS: [
    { value: 1, label: "Savings" },
    { value: 2, label: "Current" },
  ],
  //IFSC Code
  VENDOR_IFSC_CODE_LABEL: "IFSC Code",
  VENDOR_IFSC_CODE: "ifsc_code",
  VENDOR_IFSC_CODE_PLACEHOLDER: "Enter vendor IFSC code",

  //Bank Name
  VENDOR_BANK_NAME_LABEL: "Bank Name",
  VENDOR_BANK_NAME: "bank_name",
  //Branch Name
  VENDOR_BRANCH_NAME_LABEL: "Branch Name",
  VENDOR_BRANCH_NAME: "branch_name",
  VENDOR_BRANCH_NAME_PLACEHOLDER: "Enter branch name",

  CARD_TITLE_4: "Vendor Address Details",
  ADDRESS_DETAILS: "address",
  //Address Type
  VENDOR_ADDRESS_TYPE_LABEL: "Address Type",
  VENDOR_ADDRESS_TYPE: "address_type",
  VENDOR_ADDRESS_TYPE_OPTIONS: [
    { value: 1, label: "Dropdown" },
    { value: 2, label: "Office" },
    {
      value: 3,
      label: "Warehouse",
    },
    {
      value: 4,
      label: "Factory",
    },
    {
      value: 5,
      label: "Store",
    },
    {
      value: 6,
      label: "Billing",
    },
    {
      value: 7,
      label: "Shipping",
    },
    {
      value: 8,
      label: "Home",
    },
    {
      value: 9,
      label: "Other",
    },
  ],
  //Vendor Address
  VENDOR_ADDRESS_LINE_1_LABEL: "Address line 1",
  VENDOR_ADDRESS_LINE_1: "address_line_1",
  VENDOR_ADDRESS_LINE_1_PLACEHOLDER: "Enter address line 1",
  VENDOR_ADDRESS_LINE_2_LABEL: "Address line 2",
  VENDOR_ADDRESS_LINE_2: "address_line_2",
  VENDOR_ADDRESS_LINE_2_PLACEHOLDER: "Enter address line 2",
  //Vendor City
  VENDOR_CITY_LABEL: "City",
  VENDOR_CITY: "city",
  VENDOR_CITY_PLACEHOLDER: "Enter city",
  //Vendor State
  VENDOR_STATE_LABEL: "State",
  VENDOR_STATE: "state",
  VENDOR_STATE_PLACEHOLDER: "Enter state",
  //Vendor Country
  VENDOR_COUNTRY_LABEL: "Country",
  VENDOR_COUNTRY: "country",
  VENDOR_COUNTRY_PLACEHOLDER: "Enter country",
  //Vendor Pincode
  VENDOR_PINCODE_LABEL: "Pincode",
  VENDOR_PINCODE: "pincode",
  VENDOR_PINCODE_PLACEHOLDER: "Enter pincode",

  CARD_TITLE_5: "Vendor Product Details",
  PRODUCT: "Product",

  CARD_TITLE_6: "Product details",
  //Product Name

  PRODUCT_LIST: "products",

  //Product Name
  PRODUCT_NAME_LABEL: "Product",
  PRODUCT_NAME: "product",
  STORES: "stores",
  //Product Store
  PRODUCT_STORE: "store",
  PRODUCT_STORE_LABEL: "Select Store",
  //UOM
  PRODUCT_UOM_LABEL: "UOM",
  PRODUCT_UOM: "uom",
  //GST Included
  PRODUCT_GST_INCLUDED_LABEL: "GST Included",
  PRODUCT_GST_INCLUDED: "gst_included",
  PRODUCT_GST_INCLUDED_OPTIONS: [
    { value: 1, label: "Yes" },
    { value: 0, label: "No" },
  ],

  //Product Purchase Price
  PURCHASE_PRICE_LABEL: "Purchase Price",
  PURCHASE_PRICE: "purchase_price",
  PURCHASE_PRICE_PLACEHOLDER: "Enter purchase price",
  //Product Landing Price
  LANDING_PRICE_LABEL: "Landing Cost",
  LANDING_PRICE: "landing_cost",
  LANDING_PRICE_PLACEHOLDER: "Enter landing price",
  //Product Purchase Tax
  PURCHASE_TAX_LABEL: "Purchase Tax Included",
  PURCHASE_TAX: "purchase_tax_included",
  //Product Estimate TAT

  ESTIMATE_TAT: "turn_around_time",
  //min days
  TAT_MIN_DAYS_LABEL: "Minimum days TAT",
  TAT_MIN_DAYS: "min_days",
  TAT_MIN_DAYS_PLACEHOLDER: "Enter minimum days TAT",
  //max days
  TAT_MAX_DAYS_LABEL: "Maximum days TAT",
  TAT_MAX_DAYS: "max_days",
  TAT_MAX_DAYS_PLACEHOLDER: "Enter maximum days TAT",
  //Product MOQ
  MOQ_LABEL: "MOQ",
  MOQ: "moq",
  MOQ_PLACEHOLDER: "Enter MOQ",
  //Product MOQ Pieces
  MOQ_PIECES_LABEL: "MOQ Pieces",
  MOQ_PIECES: "moq_pieces",
  MOQ_PIECES_PLACEHOLDER: "Enter MOQ Pieces",

  CARD_TITLE_7: "Ordering Details",
  ORDER_DETAILS: "order_delivery",
  PREFERRED_DAYS_OF_ORDERING_LABEL: "Preferred Days of Ordering",
  PREFERRED_DAYS_OF_ORDERING: "preferred_ordering_days",
  PREFERRED_DAYS_OF_DELIVERY_LABEL: "Preferred Days of Delivery",
  PREFERRED_DAYS_OF_DELIVERY: "preferred_delivery_days",

  CARD_TITLE_8: "Product Alias Name",
  ALIAS_NAME_LABEL: "Alias Name",
  ALIAS_NAME: "alias",
  PRODUCT_ALIAS_NAME_PLACEHOLDER: "Enter alias name",
  PRODUCT_ALIAS_NAME: "product_alias_name",
});

//Print

export const LABEL_CONSTANTS = Object.freeze({
  LABEL_HEADER: "Manage Labels",

  ADD_HEADER: "Add Label",
  EDIT_HEADER: "Edit Label",
  DELETE_LABEL: "Delete Label",
  DELETE_LABEL_MSG: "Are you sure you want to make this label inactive?",

  PATH: "label",
  LABEL: "Label",

  LABEL_COLS: [
    "#",
    "Label Template Name",
    "Printer Type",
    "Size",
    "Status",
    "Actions",
  ],

  //form fields
  CARD_TITLE_1: "Label Details",
  //Label Name
  LABEL_TEMPLATE_NAME_LABEL: "Label Template Name",
  LABEL_TEMPLATE_NAME: "template",
  // LABEL_TEMPLATE_NAME_PLACEHOLDER: "Enter label name",
  //Label Print Type
  PRINT_TYPE_LABEL: "Label Printer Type",
  PRINT_TYPE: "print_type",
  PRINT_TYPE_OPTIONS: [
    { value: "thermal", label: "Thermal" },
    { value: "laser", label: "Laser" },
  ],
  //Label Size
  LABEL_SIZE_LABEL: "Label Size",
  LABEL_SIZE: "size",
  LABEL_SIZE_OPTIONS: [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
  ],
  //Label Status
  LABEL_STATUS_LABEL: "Label Status",
  LABEL_STATUS: "is_active",
  LABEL_STATUS_OPTIONS: [
    { value: "1", label: "Active" },
    { value: "0", label: "Inactive" },
  ],
  CARD_TITLE_2: "Label Print Mapping",
  //Label Print Mapping
  LABEL_PRINT_MAPPING_LABEL: "Label Print Mapping",
  LABEL_PRINT_MAPPING: "printer_mapping",
  //Label Print Mapping Printer
  LABEL_PRINT_MAPPING_PRINTER_LABEL: "Printer",
  LABEL_PRINT_MAPPING_PRINTER: "printers",
  LABEL_PRINT_MAPPING_PRINTER_PLACEHOLDER: "Enter Printer Name",
  ADD_PRINTER: "Add Printer",
});

export const PRINTER_CONSTANTS = Object.freeze({
  PRINTER_HEADER: "Manage Printer",

  ADD_HEADER: "Add Printer",
  EDIT_HEADER: "Edit Printer",

  PATH: "print",
  LABEL: "Printer",

  PRINTER_COLS: [
    "#",
    "Printer Name",
    "Series Name",
    "Language",
    "Status",
    "Actions",
  ],

  //form fields
  CARD_TITLE_1: "Printer Details",

  // printer_name: "",
  // target: "",
  // series_name: "",
  // language: "",
  // is_active: [{ value: 1, label: "Active" }],

  //Printer Name
  PRINTER_NAME_LABEL: "Printer Name",
  PRINTER_NAME: "printer_name",
  PRINTER_NAME_PLACEHOLDER: "Enter printer name",
  //Printer Target
  PRINTER_TARGET_LABEL: "Printer Target",
  PRINTER_TARGET: "target",
  PRINTER_TARGET_PLACEHOLDER: "Enter printer target",
  //Printer Series Name
  PRINTER_SERIES_NAME_LABEL: "Printer Series Name",
  PRINTER_SERIES_NAME: "series_name",
  PRINTER_SERIES_NAME_PLACEHOLDER: "Enter printer series name",
  //Printer Language
  PRINTER_LANGUAGE_LABEL: "Printer Language",
  PRINTER_LANGUAGE: "language",
  PRINTER_LANGUAGE_PLACEHOLDER: "Enter printer language",
  //Printer Status
  PRINTER_STATUS_LABEL: "Printer Status",
  PRINTER_STATUS: "is_active",
  PRINTER_STATUS_OPTIONS: [
    { value: "1", label: "Active" },
    { value: "0", label: "Inactive" },
  ],
});

export const TOAST_CONSTANTS = Object.freeze({
  INFO: "info" as TypeOptions,
  SUCCESS: "success" as TypeOptions,
  ERROR: "error" as TypeOptions,
  WARNING: "warning" as TypeOptions,
  DEFAULT: "default" as TypeOptions,
});

export const POS_CONSTANTS = Object.freeze({
  RUPEE_SIGN: "₹",
  CASH_ID: 13,
  SUPER_USER_ID: 1,
  CREDIT_NOTE_ID: 18,
  STORE_MANAGER_ID: 54,
  NOT_ITEMS_MESSAGE: " No Items in Cart",
});

export const POS_MODAL_CONSTANTS = Object.freeze({
  MODAL_HEIGHT_SM: "25" as TModalHeight,
  SELECT_STORE: "Select Store",
  CHOOSE_BATCH: "Choose Batch",
  ADVANCE_ORDER: "Advance Order",
  PRINT: "Print",
  REDEEN_CREDIT_NOTE: "Redeem Credit Note",
});

export const POS_HEADER_CONSTANTS = Object.freeze({
  SELECT_STORE_MESSAGE: "Please select a store",
  CHANGE_STORE: "Change Store",
  INVALID_STORE_CODE_MESSAGE:
    "The scanned reciept is from a different store. Please check and try again",
  PRODUCT_QUERY: "product" as TPOSHeaderQuery,
  ORDER_QUERY: "order" as TPOSHeaderQuery,
  CUSTOMER_QUERY: "customer" as TPOSHeaderQuery,
  ADVANCE_ORDER_QUERY: "advance" as TPOSHeaderQuery,
  SCAN_PRODUCT_PLACEHOLDER: "Scan Barcode/Enter Product Name",
  ADD_NEW_CUSTOMER_PLACEHOLDER: "Add new customer",
  VALID_NAME_MESSAGE: "Please enter atleast 3 characters",
  VALID_PHONE_NO_MESSAGE: "Please enter a valid phone number",
  CUSTOMER_PLACEHOLDER: "Select Customer or Add New",
  ORDER_PLACEHOLDER: "Enter Invoice Code",
  CONFIRM_LOAD_ORDER: "Are you sure you want to load this order?",
});

export const POS_CART_CONSTANTS = {
  POS_CART_HEADERS: [
    {
      title: "#",
      colSpan: 1,
      className: "text-sm align-middle text-center",
    },
    {
      title: "Product Code",
      colSpan: 1,
      className: "text-sm align-middle",
    },
    {
      title: "Product Name",
      colSpan: 2,
      className: "text-sm align-middle",
    },
    {
      title: "Quantity",
      colSpan: 1,
      className: "text-sm align-middle text-center",
    },
    {
      title: "MRP",
      colSpan: 1,
      className: "text-sm align-middle text-center",
    },
    {
      title: "Discount",
      colSpan: 2,
      className: "text-sm align-middle text-center",
    },
    {
      title: "Tax Applied",
      colSpan: 1,
      className: "text-sm align-middle text-center",
    },
    {
      title: "Unit Cost",
      colSpan: 1,
      className: "text-sm align-middle text-center",
    },
    {
      title: "Net Amount",
      colSpan: 1,
      className: "text-sm align-middle text-center",
    },
    {
      title: "Actions",
      colSpan: 1,
      className: "text-sm align-middle text-center",
    },
  ],
  DISCOUNT_ERROR_MESSAGE: "Discount cannot be applied",
  ADD_ITEMS_TO_CART_MESSAGE: "Please add items to cart",
  SELECT_CUSTOMER_MESSAGE: "Please select a customer",
  RESET_MESSAGE: "Are you sure you want reset the cart ?",
  CHANGE_STORE_MESSAGE: "It will reset the cart and change the store.",
  CONFIRM_DELETE_MESSAGE:
    "Are you sure you want to delete this product from cart ?",
  WITHOUT_ADDRESS_MESSAGE: "Are you sure to proceed without address?",
  CREDIT_AMOUNT_MESSAGE: "Amount should be less than credit amount",
  TOTAL_AMOUNT_MESSSAGE: "Amount should be less than total amount",
  BILL_REMARKS_PLACEHOLDER: "Bill Remarks",
  TOTAL_MRP_LABEL: "MRP",
  TOTAL_ITEMS_LABEL: "Total Items",
  TOTAL_SHIPPING_LABEL: "Total Shipping",
  DISCOUNT_PLACEHOLDER: "Enter Discount",
  DISCOUNT_LABEL: "Bill Discount",
  NO_DISCOUNT_LABEL: "No Discount",
  TOTAL_DISCOUNT_LABEL: "Total Discount",
  TAX_AMOUNT_LABEL: "Tax Amount",
  ROUND_OFF_LABEL: "Round Off",
  CREDIT_LABEL: "Applied Credit",
  BALANCE_CREDIT: "Balance Credit",
  REDEEM_CREDIT_LABEL: "Redeem Credit",
  NET_AMOUNT_LABEL: "Net Amount",
  HOLD_BILL_SHORTCUT: "F6",
  HOLD_BILL_LABEL: "Hold Bill (F6)",
  HOLD_BILL_MOBILE: "Hold Bill",
  PRINT_BILL_SHORTCUT: "F8",
  PRINT_BILL_LABEL: "Save & Print Bill (F8)",
  PRINT_BILL_MOBILE: "Save Bill",
  PRINT_WHATSAPP_BILL_LABEL: "Save & WhatsApp Bill",
  PAY_LATER_SHORTCUT: "F11",
  PAY_LATER_LABEL: "Pay Later (F11)",
  PAY_LATER_MOBILE: "Pay Later",
  ADVANCE_ORDER_LABEL: "Advance Order",
  PRINT_BILL_LOADING_STATE: "addOrder",
  WHATSAPP_BILL_LOADING_STATE: "whatsappBill",
  PLACE_ORDER_LABEL: "Place Order",
  BILL_CONTENT: "bill",
  BILL_LABEL: "Print Bill",
  EDIT_PAYMENT_DETAILS: "Edit Payment Details",
  BALANCE_CREDIT_CONTENT: "credit",
  BALANCE_CREDIT_LABEL: "Print Balance Credit Note",
  REMARKS: "remarks",
  REMARKS_LABEL: "Remarks",
  REMARKS_PLACEHOLDER: "Enter remarks",
  //Advance Order Modal
  DELIVERY: "delivery",
  PICKUP: "pickup",
  PICKUP_LABEL: "Pickup",
  ADVANCE_AMOUNT_LABEL: "Advance Amount",
  ADVANCE_AMOUNT_PLACEHOLDER: "Enter advance amount",
  ADVANCE_AMOUNT: "advance_amount",
  INCORRECT_ADVANCE_AMOUNT:
    'Advance amount can not be greater than "Net Amount"',
  DELIVERY_DATE_LABEL: "Delivery Date",
  DELIVERY_DATE_PLACEHOLDER: "Select delivery date",
  DELIVERY_DATE: "delivery_date",
  DELIVERY_TIME_LABEL: "Delivery Time",
  DELIVERY_TIME_PLACEHOLDER: "Select delivery time",
  DELIVERY_TIME: "delivery_time",
  DELIVERY_TYPE_LABEL: "Delivery Type",
  DELIVERY_TYPE: "delivery_type",
  DELIVERY_TYPE_OPTIONS: [
    { value: "pickup", label: "Pickup" },
    { value: "delivery", label: "Delivery" },
  ],
  DELIVERY_ADDRESS_TITLE: "Delivery Address",
  //Pay Later MOdal
  GUARANTEE_PERSON: "guaranteer_person",
  GUARANTEE_PERSON_LABEL: "Guarantee Person",
  PAY_LATER_LOADING_STATE: "payLater",
  PAY_LATER: "Pay Later",
  //Redeem Credit Note modal
  CLEAR_CREDIT_NOTE: "Clear Credit Note",
  CREDIT_NOTE: "creditNote",
  CREDIT_NOTE_PLACEHOLDER: "Scan or Select Credit Note",
  CREDIT_DATE: "Credit Date",
  CREDIT_AMOUNT: "Credit Amount",
  APPLY_CREDIT: "applyCredit",
  APPLY_CREDIT_LABEL: "Apply Credit",
  APPLY_CREDIT_PLACEHOLDER: "Enter credit amount",
  CREDIT_BALANCE: "Credit Balance",
  AMOUNT_TO_PAY: "Amount to Pay",
  //Sidebar
  ORDERS: "Orders",
  HOLD_BILL: "Hold Bill",
  CREDIT_NOTE_LABEL: "Credit Note",

  SELECTED_STORE_DETAILS: "Selected Store Details",
  STORE_NAME: "Store Name",
  STORE_PHONE: "Store Phone",
  STORE_GSTIN: "Store GSTIN",

  CUSTOMER_DETAILS: "Customer Details",
  LAST_VISITED: "Last Visited",
  CUSTOMER_LAST_BILL_AMOUNT: "Last Bill Amount",
  TOTAL_PURCHASE: "Total Purchase",
  CUSTOMER_DETAILS_LOADING_STATE: "customerDetails",

  LAST_BILL_DETAILS: "Last Bill Details",
  LAST_BILL: "Last Bill",
  LAST_BILL_AMOUNT: "Last Bill Amount",

  PRINT_LAST_BILL: "Print Last Bill",
  PRINT_LAST_BILL_LOADING_STATE: "printLastBill",

  PRINT_CREDIT_NOTE_LOADING_STATE: "printCreditNote",

  //Refund
  TOTAL_REFUND_ITEMS: "Total Returned Items",
  TOTAL_REFUND_AMOUNT: "Total Refund Amount",
  PAYABLE_REFUND_AMOUNT: "Payable Refund Amount",
  RETURN: "return",
  BILL_AMOUNT: "Bill Amount",
};

export const ORDER_CONSTANTS = Object.freeze({
  PERMISSION_NAME: "Manage Orders",
  TABLE_HEADER: [
    "#",
    "Invoice Code",
    "Customer Name",
    "Total Amount",
    "Order Status",
    "Order Date",
    "Action",
  ],

  ORDER_STATUS_OPTIONS: [
    { value: "all", label: "All" },
    { value: "completed", label: "Completed" },
    { value: "pending", label: "Pending" },
  ],
  ORDER_DELIVERY_OPTIONS: [
    { value: "all", label: "All" },
    { value: "delivery", label: "Delivery" },
    { value: "pickup", label: "Pickup" },
  ],
  ORDER_DATE_FILTER_OPTIONS: [
    { value: "today", label: "Today" },
    { value: "yesterday", label: "Yesterday" },
    { value: "current_week", label: "This Week" },
    { value: "last_week", label: "Last Week" },
    { value: "current_month", label: "This Month" },
    { value: "last_month", label: "Last Month" },
    { value: "custom", label: "Custom Range" },
  ],
  ORDER_FILTER: "order_list_filter",
  ALL: "all",
  ALL_LABEL: "All",
  START_DATE: "start_date",
  START_DATE_LABEL: "Start Date",
  END_DATE: "end_date",
  END_DATE_LABEL: "End Date",
  PAYMENT_TYPE: "payment_type",
  PAYMENT_TYPE_LABEL: "Filter by Payment Type",
  STATUS: "status",
  STATUS_LABEL: "Filter by Status",
  DELIVERY_TYPE: "delivery_type",
  DELIVERY_TYPE_LABEL: "Filter by Delivery Type",
  CANCELLED: "cancelled",
  CANCELLED_LABEL: "Cancelled",
  //Product Order Details
  NO_ITEMS_MESSAGE: "No Items to Display",
  PRODUCT_DETAILS: "Product Details",
  PAYMENT_DETAILS: "Payment History",
  CREDIT_NOTE: "Credit Note",

  REFUND_MODE: "Return",
  EXIT_REFUND: "Exit",
  CANCEL_ENTIRE_ORDER: "Cancel Entire Order",
  CANCEL_SELECTED_PRODUCTS: "Cancel Selected Products",
  //Payment ORder Details
  PAYMENT_TABLE_HEADER: ["#", "Payment Mode", "Date", "Amount"],
  //CreditNote Order Details
  CREDIT_NOTE_TABLE_HEADER: [
    "#",
    "Product Code",
    "Product Name",
    "Refund Quantity",
    "Refund Amount",
  ],
  TOTAL_AMOUNT: "Total Amount",
  FETCH_ORDER_LOADING_STATE: "fetchOrders",
  COMPLETE_ORDER_LOADING_STATE: "completeOrder",
});

export const HOLD_BILL_CONSTANTS = Object.freeze({
  PERMISSION_NAME: "Manage Hold Bill",
  TABLE_HEADER: [
    "#",
    "Bill No",
    "Customer Name",
    "Total Amount",
    "Bill Date",
    "Status",
    "Action",
  ],
  LOAD_BILL_MESSAGE: "Please clear the cart before loading the bill",
  DELETE_HOLD_BILL_MESSAGE: "Are you sure you want to delete this hold bill ?",
});

export const ADVANCE_ORDERS_CONSTANTS = Object.freeze({
  ADVANCE_ORDER_HEADER: "Manage Advance Orders",
  PAYMENT_STATUS_OPTIONS: [
    { label: "All", value: "all" },
    { label: "Paid", value: 1 },
    { label: "Unpaid", value: 0 },
  ],
  ADVANCE_ORDER_DETAIL_ROUTE: "/admin/sales/advance/",
  ADVANCE_ORDERS_LIST: [
    "#",
    "Invoice Code",
    "Customer Name",
    "Order Placed On",
    "Delivery Date",
    "Delivery Type",
    "Advance Amount",
    "Actions",
  ],
  CREDIT_NOTE_TYPE: "advance",
  PAYMENT_STATUS: "payment_status",
  ADVANCE_ORDER_CODE: "code",
  START_DATE: "start_date",
  START_DATE_LABEL: "Start Date",
  END_DATE: "end_date",
  END_DATE_LABEL: "End Date",
  DELIVERY_DETAILS: "Delivery Details",
  DELIVERY_TIME: "Delivery Time",
  DELIVERY_DATE: "Delivery Date",
  DELIVERY_TYPE: "Delivery Type",
  ORDER_PLACED_ON: "Order Placed On",
  NO_ITEMS_MESSAGE: "No Items to Display",
  PRINT_MESSAGE: "Print Advance Receipt",
  LOADING_STATE: "printCreditNote",
  PRODUCT_DETAILS: "Product Details",
  PAYMENT_DETAILS: "Payment Details",
  FILTER_BY_PAID_UNPAID: "Filter by Paid / Unpaid",
});

export const CREDIT_NOTE_CONSTANTS = Object.freeze({
  HEADER: "Credit Notes",
  TABLE_COLS: [
    "#",
    "Credit Note No",
    "Customer",
    "Credit Note Amount",
    "Created At",
    "Status",
    "Action",
  ],
  CREDIT_NOTE_TYPE: "credit_note_type",
  CREDIT_NOTE_TYPE_LABEL: "Credit Note Type",
  CREDIT_NOTE_TYPE_OPTIONS: [
    {
      value: "return",
      label: "Return / Refunds",
    },
    {
      value: "credit",
      label: "Balance Credit Note",
    },
    {
      value: "cash",
      label: "Cash Returns",
    },
  ],
});

export const CASHIER_CONSTANTS = Object.freeze({
  ORDER_HEADER: [
    "#",
    "Invoice Code",
    "Customer Name",
    "Total Amount",
    "Order Status",
    "Order Date",
    "Action",
  ],
  PAY_LATER_HEADER: [
    "#",
    "Invoice Code",
    "Customer Name",
    "Total Amount",
    "Order Date",
    "Action",
  ],
  CREDIT_NOTE_HEADER: [
    "#",
    "Credit Note Code",
    "Customer Name",
    "Order Placed On",
    "Delivery Date",
    "Delivery Type",
    "Advance Amount",
    "Actions",
  ],
  ORDER_DATE_LABEL: "Order Date",
  FILTER_OPTION: [
    { value: 0, label: "Orders" },
    { value: 1, label: "Advance Orders" },
  ],
  FILTER_OPTIONS_DEFAULT_VALUE: {
    value: 0,
    label: "Order",
  },
  PAYMENT_TYPE_DEFAULT_VALUE: {
    value: 0,
    label: "All",
  },
  FILTER_LABEL: "Filter by Orders / Advance Orders",
  CASHIER_HEADER: "Cashier",
  TYPE_LABEL: "Type",
  TYPE: "type",
  CODE: "code",
  NAVIGATE: "navigate",
  CASHIER: "cashier",
  PAYMENT_HEADER: ["#", "Product Name", "Quantity", "Net. Amount"],
  ADVANCE_ORDER_PAYMENT_HEADER: ["Incoice Code", "Amount"],
  PAYMENT_MODE_NOT_SELECTED_MESSAGE: "Please select a payment mode",
  FULL_AMOUNT_MESSAGE: "Please pay the full amount",
  SALES_SUMMARY: "Sales Summary",
  CREDIT_NOTE_AMOUNT: "Credit Note Amount",
  CORRECT_AMOUNT_MESSAGE: "Please enter a valid amount",
  ALREADY_PAID_FULL_AMOUNT: "You have already paid the full amount",
  PAYABLE_AMOUNT: "Payable Amount",
  TOTAL_BILL_AMOUNT: "Total Bill Amount",
  BALANCE_DUE: "Balance Due",
  PAYMENT_MODES: "payment_modes",
  AMOUNT_LABEL: "Amount",
  AMOUNT: "amount",
  AMOUNT_PLACEHOLDER: "Please enter amount",
  PAYMENT_MODE: "payment_mode",
  PAYMENT_MODE_LABEL: "Payment Mode",
  ADD_PAYMENT_MODE: "Add Payment Mode",
  PAY_LATER_CONFIRM_MESSAGE:
    "To proceed with Pay Later please Fill out the customer details",
  PAY_LATER_MESSAGE: "The remaining amount will be considered as Pay Later",

  PENDING_PAY_LATER_HEADER: "Pending Pay Later Orders",
  ADVANCE_ORDER_CODE: "ADC",
  ORDER_TYPE: "order_type",
  STORE: "store",
  VIEW_ALL_ORDERS: "View All Orders",
  WHATSAPP_REMINDER_LOADING_STATE: "whatsappReminder",
});

export const LABEL_GENERATION_CONSTANTS = Object.freeze({
  HEADERS: [
    "#",
    "Item code",
    "Product Name",
    "Batch",
    "Quantity",
    "Packing Date",
    "MRP",
    "Selling Price",
  ],
});

export const REPORTS_CONSTANTS = Object.freeze({
  KPI_HEADER: "KPI Report",
  SELECT_STORE_LABEL: "Select Store",
  SELECT_STORE: "selected_store",
  GENERATE: "Generate Report",
});

export const SEO_CONSTANTS = Object.freeze({
  TITLE: "meta_title",
  TITLE_LABEL: "Title",
  TITLE_PLACEHOLDER: "Enter meta title",

  DESCRIPTION: "meta_description",
  DESCRIPTION_LABEL: "Description",
  DESCRIPTION_PLACEHOLDER: "Enter meta description",

  KEYWORDS: "meta_keywords",
  KEYWORDS_LABEL: "Keywords",
  KEYWORDS_PLACEHOLDER: "Enter meta keywords",

  SLUG: "meta_slug",
  SLUG_LABEL: "Slug",
  SLUG_PLACEHOLDER: "Enter meta slug",
});

export const SITE_MANAGEMENT_CONSTANTS = Object.freeze({
  PATH: "ecommerce/site-management",
  HEADER: "Site Management",
  TABS: [
    "General Details",
    "SMS API Details",
    "Payment API Details",
    "Shipping API Details",
    "Menu Management",
  ],

  IMAGE_FOLDER_NAME: "site-settings",

  GENERAL_HEADER: "General Details",

  SITE_NAME: "site_name",
  SITE_NAME_LABEL: "Site Name",
  SITE_NAME_PLACEHOLDER: "Enter site name",

  SITE_URL: "site_url",
  SITE_URL_LABEL: "Site URL",
  SITE_URL_PLACEHOLDER: "Enter site url",

  SITE_CONTACT_NUMBER: "site_contact_number",
  SITE_CONTACT_NUMBER_LABEL: "Site Contact Number",
  SITE_CONTACT_NUMBER_PLACEHOLDER: "Enter site contact number",

  SITE_CONTACT_EMAIL: "site_contact_email",
  SITE_CONTACT_EMAIL_LABEL: "Site Contact Email",
  SITE_CONTACT_EMAIL_PLACEHOLDER: "Enter site contact email",

  ICON: "icon",
  ICON_LABEL: "Site Icon",

  FAVICON: "favicon",
  FAVICON_LABEL: "Site Favicon",

  PUBLISH_WEBSITE: "publish_website",
  PUBLISH_WEBSITE_LABEL: "Publish Website",
  PUBLISH_WEBSITE_PLACEHOLDER: "Do you want to publish website?",

  FRONT_PAGE: "front_page",
  FRONT_PAGE_LABEL: "Front Page",
  FRONT_PAGE_PLACEHOLDER: "Select front page for website",

  EMAIL_HEADER: "Email Settings",

  EMAIL_DETAILS: "email_details",

  USERNAME: "email_username",
  USERNAME_LABEL: "Username",
  USERNAME_PLACEHOLDER: "Enter email username",

  PASSWORD: "email_password",
  PASSWORD_LABEL: "Password",
  PASSWORD_PLACEHOLDER: "Enter email password",

  HOST: "email_host",
  HOST_LABEL: "Host",
  HOST_PLACEHOLDER: "Enter email host",

  PORT: "email_port",
  PORT_LABEL: "Port",
  PORT_PLACEHOLDER: "Enter email port",

  ANALYTICS_HEADER: "Analytics Settings",

  GOOGLE_ANALYTICS: "google_analytics",
  GOOGLE_ANALYTICS_LABEL: "Google Analytics",
  GOOGLE_ANALYTICS_PLACEHOLDER: "Enter google analytics code",

  FACEBOOK_PIXEL: "facebook_pixel",
  FACEBOOK_PIXEL_LABEL: "Facebook Pixel",
  FACEBOOK_PIXEL_PLACEHOLDER: "Enter facebook pixel code",

  SOCIAL_MEDIA_HEADER: "Social Media Settings",

  FACEBOOK: "facebook",
  FACEBOOK_LABEL: "Facebook",
  FACEBOOK_PLACEHOLDER: "Enter facebook handle",

  TWITTER: "twitter",
  TWITTER_LABEL: "Twitter",
  TWITTER_PLACEHOLDER: "Enter twitter handle",

  INSTAGRAM: "instagram",
  INSTAGRAM_LABEL: "Instagram",
  INSTAGRAM_PLACEHOLDER: "Enter instagram handle",

  YOUTUBE: "youtube",
  YOUTUBE_LABEL: "Youtube",
  YOUTUBE_PLACEHOLDER: "Enter youtube handle",

  DELETE_API: "Delete API Details",
  DELETE_API_MSG: "Are you sure you want to delete this ?",

  SMS_HEADER: "SMS API Details",
  ADD_SMS: "Add SMS API",

  SMS_DETAILS: "sms_details",

  SMS_NAME: "sms_name",
  SMS_NAME_LABEL: "SMS Name",
  SMS_NAME_PLACEHOLDER: "Enter sms name",

  SMS_KEY: "sms_key",
  SMS_KEY_LABEL: "SMS Key",
  SMS_KEY_PLACEHOLDER: "Enter sms api key",

  PAYMENT_HEADER: "Payment API Details",
  ADD_PAYMENT: "Add Payment API",

  PAYMENT_DETAILS: "payment_details",

  PAYMENT_NAME: "payment_name",
  PAYMENT_NAME_LABEL: "Payment Name",
  PAYMENT_NAME_PLACEHOLDER: "Enter payment name",

  PAYMENT_KEY: "payment_key",
  PAYMENT_KEY_LABEL: "Payment Key",
  PAYMENT_KEY_PLACEHOLDER: "Enter payment api key",

  SHIPPING_HEADER: "Shipping API Details",
  ADD_SHIPPING: "Add Shipping API",

  SHIPPING_DETAILS: "shipping_details",

  SHIPPING_NAME: "shipping_name",
  SHIPPING_NAME_LABEL: "Shipping Name",
  SHIPPING_NAME_PLACEHOLDER: "Enter shipping name",

  SHIPPING_KEY: "shipping_key",
  SHIPPING_KEY_LABEL: "Shipping Key",
  SHIPPING_KEY_PLACEHOLDER: "Enter shipping api key",

  MENU_HEADER: "Menu Management",

  MENU_DETAILS: "menu_details",

  HEADER_MENU: "header_menu",
  HEADER_MENU_LABEL: "Header Menu",
  HEADER_MENU_PLACEHOLDER: "Please select header menu",

  FOOTER_MENU: "footer_menu",
  FOOTER_MENU_LABEL: "Footer Menu",
  FOOTER_MENU_PLACEHOLDER: "Please select footer menu",

  MEGA_MENU: "mega_menu",
  MEGA_MENU_LABEL: "Mega Menu",
  MEGA_MENU_PLACEHOLDER: "Please select mega menu",
});

export const PAGES_CONSTANTS = Object.freeze({
  PATH: "ecommerce/pages",
  NAVIGATE_TO: "/admin/ecommerce/pages",
  HEADER: "Pages",
  FORM_HEADER: "Page",
  COLS: ["#", "Page Name", "Status", "Actions"],
  ADD_HEADER: "Page",

  DELETE_PAGE: "Deactivate Page",
  DELETE_PAGE_MSG: "Are you sure you want to deactivate this page?",
  DELETE_STATE: "page-delete",

  CARD_TITLE_1: "Page Details",

  NAME: "page_name",
  NAME_LABEL: "Page Name",
  NAME_PLACEHOLDER: "Enter page name",

  WIDGETS: "widgets",

  STATUS: "is_active",
  STATUS_LABEL: "Status",
});

export const BLOG_CONSTANTS = Object.freeze({
  PATH: "ecommerce/blogs",
  HEADER: "Manage Blogs",
  FORM_HEADER: "Add Blog",
  COLS: ["#", "Title", "Status", "Actions"],
  ADD_HEADER: "Blog",
});

export const TESTIMONIAL_CONSTANTS = Object.freeze({
  PATH: "ecommerce/testimonials",
  NAVIGATE_TO: "/admin/ecommerce/testimonials",
  HEADER: "Manage Testimonials",
  FORM_HEADER: "Add Testimonial",
  COLS: ["#", "Name", "Priority", "Status", "Actions"],
  ADD_HEADER: "Testimonial",

  DELETE_TESTIMONIAL: "Deactivate Testimonial",
  DELETE_TESTIMONIAL_MSG:
    "Are you sure you want to deactivate this testimonial?",
  DELETE_STATE: "testimonial-delete",

  CARD_TITLE_1: "General Details",

  TITLE: "title",
  TITLE_LABEL: "Title",

  NAME: "name",
  NAME_LABEL: "Name",
  NAME_PLACEHOLDER: "Enter name",

  STATUS: "is_active",
  STATUS_LABEL: "Status",

  CARD_TITLE_2: "Testimonial Details",

  DATE: "date",
  DATE_LABEL: "Date",

  PRIORITY: "priority",
  PRIORITY_LABEL: "Priority",
  PRIORITY_PLACEHOLDER: "Enter priority",

  REVIEW: "review",
  REVIEW_LABEL: "Review",
});

export const MENU_CONSTANTS = Object.freeze({
  PATH: "ecommerce/menu",
  NAVIGATE_TO: "/admin/ecommerce/menu",
  HEADER: "Menus",
  FORM_HEADER: "Add Menu",
  COLS: [
    { name: "#", className: "col-1", list: true },
    { name: "Name", className: "col", list: true },
    {
      name: "URL",
      className: "col",
    },
    {
      name: "Status",
      className: "col",
      list: true,
    },
    {
      name: "Actions",
      className: "col",
      list: true,
    },
  ],
  ADD_HEADER: "Menu",
  DELETE_MENU: "Deactivate Menu",
  DELETE_MENU_MSG: "Are you sure you want to deactivate this menu?",
  DELETE_STATE: "menu-delete",

  CARD_TITLE_1: "Menu Details",

  TITLE: "menu_title",
  TITLE_LABEL: "Menu Title",
  TITLE_PLACEHOLDER: "Enter menu title",

  STATUS: "is_active",
  STATUS_LABEL: "Status",

  CARD_TITLE_2: "Menu Items",
  CARD_HEADER_BTN: "Add Menu Item",
  MENU_ITEMS: "menu_items",

  MENU_ITEM: "Menu Item",
  SUB_MENU_ITEM: "Sub Menu Item",

  PARENT: "parent",
  PARENT_LABEL: "Parent",

  MENU_ITEM_TITLE: "menu_item_title",
  MENU_ITEM_TITLE_LABEL: "Menu Item Title",
  MENU_ITEM_TITLE_PLACEHOLDER: "Enter display title",

  MENU_ITEM_OPTION_LOADING_STATE: "menu-item-options",

  MENU_TYPE: "menu_type",
  MENU_TYPE_LABEL: "Menu Type",
  MENU_TYPE_OPTIONS: [
    {
      value: 1,
      label: "Category",
    },
    {
      value: 2,
      label: "Sub Category",
    },
    {
      value: 3,
      label: "Product",
    },
    { value: 4, label: "Internal Page" },
    {
      value: 5,
      label: "Mega Menu",
    },
    {
      value: 6,
      label: "External Page",
    },
  ],

  ITEM: "menu_item",

  IS_MEGA_MENU: "is_mega_menu",
  URL: "url",
  URL_LABEL: "URL",
  URL_PLACEHOLDER: "Enter URL",
});

export const TASTE_CONSTANTS = Object.freeze({
  PATH: "",
  LABEL: "Product Taste",
  NAVIGATE_TO: "/admin/ecommerce/cms/taste",
  HEADER: "Manage Taste",
  FORM_HEADER: "Add Taste",
  COLS: ["#", "Taste", "Status", "Actions"],
  ADD_HEADER: "Taste",
  DELETE_TASTE: "Deactivate Taste",
  DELETE_TASTE_MSG: "Are you sure you want to deactivate this taste?",
  DELETE_STATE: "taste-delete",

  TASTE_NAME: "taste_name",
  TASTE_NAME_LABEL: "Taste Name",
  TASTE_NAME_PLACEHOLDER: "Enter taste",

  TASTE_STATUS: "is_active",
  TASTE_STATUS_LABEL: "Status",

  TASTE_DESCRIPTION: "description",
  TASTE_DESCRIPTION_LABEL: "Description",
  TASTE_DESCRIPTION_PLACEHOLDER: "Enter description",
});

export const WIDGET_CONSTANTS = Object.freeze({
  PATH: "ecommerce/widget",
  NAVIGATE_TO: "/admin/ecommerce/widget",
  HEADER: "Manage Widgets",
  LABEL: "Widget",
  FORM_HEADER: "Add Widget",
  COLS: ["#", "Widget Name", "Status", "Actions"],
  ADD_HEADER: "Widget",
  DELETE_WIDGET: "Deactivate Widget",
  DELETE_WIDGET_MSG: "Are you sure you want to deactivate this widget?",
  DELETE_STATE: "widget-delete",

  WIDGET_NAME: "widget_name",
  WIDGET_NAME_LABEL: "Widget Name",
  WIDGET_NAME_PLACEHOLDER: "Enter widget name",

  WIDGET_TYPE: "widget_type",
  WIDGET_TYPE_LABEL: "Widget Type",
  WIDGET_TYPE_PLACEHOLDER: "Enter widget type",

  WIDGET_TEMPLATE: "widget_template",
  WIDGET_TEMPLATE_LABEL: "Widget Template",

  CONFIGURATION_OPTIONS: "configuration_options",
  CONFIGURATION_OPTIONS_LABEL: "Configuration Options",
  CONFIGURATION_OPTIONS_PLACEHOLDER: "Enter configuration options",

  DESCRIPTION: "description",
  DESCRIPTION_LABEL: "Description",
  DESCRIPTION_PLACEHOLDER: "Enter description",

  IS_ACTIVE: "is_active",
  IS_ACTIVE_LABEL: "Status",

  CARD_TITLE_1: "Content",
  CARD_HEADER_BTN: "Add Widget",

  WIDGETS: "widgets",
  DELETE_WIDGET_CONTENT: "Delete Widget Content",
  DELETE_WIDGET_CONTENT_MSG:
    "Are you sure you want to delete this widget content?",
});

export const ECOMMERCE_PRODUCT_CONSTANTS = Object.freeze({
  PATH: "ecommerce/cms/products",
  NAVIGATE_TO: "/admin/ecommerce/cms/products",
  HEADER: "Manage Products",
  FORM_HEADER: "Add Product",
  COLS: ["#", "Name", "Status", "Actions"],
  ADD_HEADER: "Product",

  DELETE_PRODUCT: "Deactivate Product",
  DELETE_PRODUCT_MSG: "Are you sure you want to deactivate this product?",
  DELETE_STATE: "product-delete",

  DELETE_PRODUCT_IMAGE: "Delete Product Image",
  DELETE_PRODUCT_IMAGE_MSG: "Are you sure you want to delete this image?",
  DELETE_PRODUCT_IMAGE_STATE: "product-image-delete",

  IMAGE_FOLDER_NAME: "product",

  DELETE_PRODUCT_WEIGHT: "Delete Product Weight",
  DELETE_PRODUCT_WEIGHT_MSG: "Are you sure you want to delete this weight?",
  DELETE_PRODUCT_WEIGHT_STATE: "product-weight-delete",

  DELETE_PRODUCT_TASTE: "Delete Product Taste",
  DELETE_PRODUCT_TASTE_MSG: "Are you sure you want to delete this taste?",
  DELETE_PRODUCT_TASTE_STATE: "product-taste-delete",
});

export const ECOMMERCE_CATEGORY_CONSTANTS = Object.freeze({
  PATH: "ecommerce/cms/category",
  NAVIGATE_TO: "/admin/ecommerce/cms/category",
  HEADER: "Manage Categories",
  FORM_HEADER: "Add Category",
  COLS: ["#", "Name", "Status", "Actions"],
  ADD_HEADER: "Category",

  DELETE_CATEGORY: "Deactivate Category",
  DELETE_CATEGORY_MSG: "Are you sure you want to deactivate this category?",
  DELETE_STATE: "category-delete",

  QUERY_CATEGORY: "category",

  GENERAL_HEADER: "General Details",

  CATEGORY: "product_category",
  CATEGORY_LABEL: "Category",
  CATEGORY_PLACEHOLDER: "Select category",

  STATUS: "is_active",
  STATUS_LABEL: "Status",

  ICON: "icon",
  ICON_LABEL: "Icon",

  ALT: "icon_alt",
  ALT_LABEL: "Alt Text",
  ALT_PLACEHOLDER: "Enter alt text",

  DISPLAY_NAME: "display_name",
  DISPLAY_NAME_LABEL: "Display Name",
  DISPLAY_NAME_PLACEHOLDER: "Enter display name",
});

export const ECOMMERCE_SUB_CATEGORY_CONSTANTS = Object.freeze({
  PATH: "ecommerce/cms/sub-category",
  NAVIGATE_TO: "/admin/ecommerce/cms/sub-category",
  HEADER: "Manage Sub Categories",
  FORM_HEADER: "Add Sub Category",
  COLS: ["#", "Name", "Status", "Actions"],
  ADD_HEADER: "Sub Category",

  DELETE_SUB_CATEGORY: "Deactivate Sub Category",
  DELETE_SUB_CATEGORY_MSG:
    "Are you sure you want to deactivate this sub category?",

  GENERAL_HEADER: "General Details",

  CATEGORY: "product_category",
  CATEGORY_LABEL: "Category",
  CATEGORY_PLACEHOLDER: "Select category",

  SUB_CATEGORY: "product_sub_category",
  SUB_CATEGORY_LABEL: "Sub Category",
  SUB_CATEGORY_PLACEHOLDER: "Select sub Category",

  STATUS: "is_active",
  STATUS_LABEL: "Status",

  ICON: "icon",
  ICON_LABEL: "Icon",

  ALT: "icon_alt",
  ALT_LABEL: "Alt Text",
  ALT_PLACEHOLDER: "Enter alt text",

  DISPLAY_NAME: "display_name",
  DISPLAY_NAME_LABEL: "Display Name",
  DISPLAY_NAME_PLACEHOLDER: "Enter display name",
});

export const FAQ_CONSTANTS = Object.freeze({
  PATH: "ecommerce/faq",
  NAVIGATE_TO: "/admin/ecommerce/faq",
  HEADER: "Manage FAQs",
  FORM_HEADER: "Add FAQ",
  COLS: ["#", "FAQ", "Status", "Actions"],
  ADD_HEADER: "FAQ",

  DELETE_FAQ: "Deactivate FAQ",
  DELETE_FAQ_MSG: "Are you sure you want to deactivate this FAQ?",
  DELETE_STATE: "faq-delete",

  IMAGE_FOLDER_NAME: "faq",

  CARD_TITLE_1: "FAQ Details",

  QUESTION: "question",
  QUESTION_LABEL: "Question",
  QUESTION_PLACEHOLDER: "Enter question",

  ANSWER: "answer",
  ANSWER_LABEL: "Answer",
  ANSWER_PLACEHOLDER: "Enter answer",

  STATUS: "is_active",
  STATUS_LABEL: "Status",
});

export const EMAIL_TEMPLATE_CONSTANTS = Object.freeze({
  PATH: "ecommerce/email-template",
  NAVIGATE_TO: "/admin/ecommerce/email-template",
  HEADER: "Manage Email Templates",
  FORM_HEADER: "Add Email Template",
  COLS: ["#", "Template", "Status", "Actions"],
  ADD_HEADER: "Email Template",

  DELETE_EMAIL_TEMPLATE: "Deactivate Email Template",
  DELETE_EMAIL_TEMPLATE_MSG: "Are you sure you want to deactivate this email?",
  DELETE_STATE: "email-template-delete",

  CARD_TITLE_1: "Email Template Details",

  NAME: "name",
  NAME_LABEL: "Template Name",
  NAME_PLACEHOLDER: "Enter name",

  STATUS: "is_active",
  STATUS_LABEL: "Status",

  CONTENT: "content",
  CONTENT_LABEL: "Template Content",
  CONTENT_PLACEHOLDER: "Enter content",
});

export const ECOMMERCE_ORDER_CONSTANTS = Object.freeze({
  PATH: "ecommerce/orders",
  HEADER: "Manage Orders",
  ADD_HEADER: "Add Order",
  COLS: [
    "#",
    "Invoice Code",
    "Customer Name",
    "Order Date",
    "Total",
    "Order Status",
    "Payment Status",
    "Actions",
  ],
  ORDER_STATUS_OPTIONS: [
    { value: "all", label: "All" },
    { value: "pending", label: "Pending" },
    { value: "placed", label: "Placed" },
    { value: "processed", label: "Processed" },
    { value: "shipped", label: "Shipped" },
    { value: "out_for_delivery", label: "Out for Delivery" },
    { value: "delivered", label: "Delivered" },
    { value: "cancelled", label: "Cancelled" },
    { value: "failed", label: "Failed" },
  ],
  PAYMENT_STATUS_OPTIONS: [
    { value: "all", label: "All" },
    { value: "success", label: "Success" },
    { value: "pending", label: "Pending" },
    { value: "failed", label: "Failed" },
  ],
  CHANGE_PRODUCT_STATUS: "Change Product Status",
  WEIGHT_VARIATION_OPTIONS: [
    {
      value: 12,
      label: "300 gms",
    },
    {
      value: 13,
      label: "500 gms",
    },
    {
      value: 14,
      label: "1 kg",
    },
    {
      value: 15,
      label: "2 kg",
    },
    {
      value: 16,
      label: "5 kg",
    },
    {
      value: 17,
      label: "10 kg",
    },
    {
      value: 18,
      label: "20 kg",
    },
    {
      value: 19,
      label: "50 kg",
    },
    {
      value: 20,
      label: "100 kg",
    },
  ],
});

export const ECOMMERCE_CUSTOMERS_CONSTANTS = Object.freeze({
  PATH: "ecommerce/customers",
  HEADER: "Manage Customers",
  COLS: ["#", "Name", "Email", "Phone", "Status", "Actions"],
  ADD_HEADER: "Customer",
});
